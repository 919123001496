// Generates the CSSTransition classNames dynamically to match the generated class names from withStyles
export function dynamicCssTransitionClassNames (classes, className) {
  return {
    appear: classes[`${className}-appear`],
    appearActive: classes[`${className}-appear-active`],
    enter: classes[`${className}-enter`],
    enterActive: classes[`${className}-enter-active`],
    enterDone: classes[`${className}-enter-done`],
    exit: classes[`${className}-exit`],
    exitActive: classes[`${className}-exit-active`],
    exitDone: classes[`${className}-exit-done`]
  }
}
