import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import RedoIcon from '@material-ui/icons/Redo'

import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useSnackbar } from 'notistack'

import { FormattedException } from 'common/graphqlclient/ErrorHandler'
import CommonGraphqlFragments from 'common/graphqlclient/Fragments'
import { instantToDateTimeString } from 'common/utilities/dates'
import useConfirmationDialog from 'hooks/Common/useConfirmationDialog'

const styles = theme => ({
  propertyValue: {
    wordBreak: 'break-word',
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap'
  },
  propertyLabel: {
    fontWeight: 'bold',
    verticalAlign: 'top'
  }
})

const PropertyRow = ({ prop, val, classes, children }) => (
  <TableRow>
    <TableCell className={classes.propertyLabel}>{prop}</TableCell>
    <TableCell className={classes.propertyValue}>{val === undefined || val === null ? children : val}</TableCell>
  </TableRow>
)

const DlqEventDetail = ({ dlqEventKey, classes, onClose, refetch }) => {
  const { showConfirmationDialog } = useConfirmationDialog()
  const { enqueueSnackbar } = useSnackbar()

  const { loading, error, data } = useQuery(DlqEventQuery, {
    skip: !dlqEventKey,
    variables: {
      key: dlqEventKey
    }
  })

  const [reprocessEventMutation] = useMutation(ReprocessDlqEvent, {
    onCompleted: data => {
      const result = data.v2Api.reprocessDlqEvent
      if (result.success) {
        enqueueSnackbar('Causal event added back to processing queue. Dlq Event marked as REPROCESSED.')
      } else {
        enqueueSnackbar(result.message, { variant: 'error', persist: true })
      }
      if (refetch) { refetch() }
      onClose()
    },
    onError: error => {
      enqueueSnackbar(error.message, { variant: 'error', persist: true })
    }
  })

  const [deleteEventMutation] = useMutation(DeleteDlqEvent, {
    onCompleted: data => {
      const result = data.v2Api.deleteDlqEvent
      if (result.success) {
        enqueueSnackbar('Dlq Event marked for deletion.')
      } else {
        enqueueSnackbar(result.message, { variant: 'error', persist: true })
      }
      if (refetch) { refetch() }
      onClose()
    },
    onError: error => {
      enqueueSnackbar(error.message, { variant: 'error', persist: true })
    }
  })

  const reprocessEvent = key => {
    showConfirmationDialog({
      title: 'Reprocess Dlq Event',
      message: 'This will reprocess the causal event of this Dlq Event. Are you sure?',
      confirmLabel: 'Reprocess',
      onConfirm: () => reprocessEventMutation({ variables: { key: key } })
    })
  }

  const deleteEvent = key => {
    showConfirmationDialog({
      title: 'Delete Dlq Event',
      message: 'This will mark this Dlq Event for deletion. To confirm, please type \'DELETE\' below:',
      target: 'DELETE',
      targetLabel: 'Confirm Action',
      confirmLabel: 'Delete',
      onConfirm: () => {
        deleteEventMutation({ variables: { key: key } })
      }
    })
  }

  if (!dlqEventKey) { return null }

  let content = <CircularProgress />
  if (error) {
    content = <FormattedException err={error} />
  } else if (!loading && data) {
    const e = data.v2Api.admin.dlqEvent
    const c = e.producedEvent.context

    content = (
      <Table>
        <TableBody>
          <PropertyRow prop='Actions' classes={classes}>
            <Tooltip title='Reprocess'><IconButton onClick={() => reprocessEvent(dlqEventKey)}><RedoIcon /></IconButton></Tooltip>
            <Tooltip title='Delete'><IconButton onClick={() => deleteEvent(dlqEventKey)}><DeleteIcon /></IconButton></Tooltip>
          </PropertyRow>
          <PropertyRow prop='Uuid' val={e.key.uuid} classes={classes} />
          <PropertyRow prop='Status' val={e.status} classes={classes} />
          <PropertyRow prop='Client' val={e.client} classes={classes} />
          <PropertyRow prop='Env' val={e.env} classes={classes} />
          <PropertyRow prop='User' val={e.user} classes={classes} />
          <PropertyRow prop='File cmsUuid' val={e.cmsUuid} classes={classes} />
          <PropertyRow prop='Id (CausalEventId)' val={e.key.id} classes={classes} />
          <PropertyRow prop='Timestamp' val={instantToDateTimeString(c.timestamp, true)} classes={classes} />
          <PropertyRow prop='Topic' val={c.topic} classes={classes} />
          <PropertyRow prop='Partition' val={c.partition} classes={classes} />
          <PropertyRow prop='Offset' val={c.offset} classes={classes} />
          <PropertyRow prop='CausalEventName' val={e.causalEventName} classes={classes} />
          <PropertyRow prop='CausalEventTimestamp' val={instantToDateTimeString(e.causalEventTimestamp, true)} classes={classes} />
          <PropertyRow prop='CausalEventTopic' val={e.causalEventTopic} classes={classes} />
          <PropertyRow prop='CausalEventPartition' val={e.causalEventPartition} classes={classes} />
          <PropertyRow prop='CausalEventOffset' val={e.causalEventOffset} classes={classes} />
          <PropertyRow prop='Process' val={e.key.process} classes={classes} />
          <PropertyRow prop='Message' val={e.message} classes={classes} />
          <PropertyRow prop='Exception' val={e.exception} classes={classes} />
        </TableBody>
      </Table>
    )
  }

  return (
    <Grid item xs>
      <Paper>
        <IconButton aria-label='Close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {content}
      </Paper>
    </Grid>
  )
}

export default withStyles(styles)(DlqEventDetail)

const DlqEventQuery = gql`
  query dlqEventQuery($key: DlqEventKeyInput!) {
    v2Api {
      admin{
        dlqEvent(key: $key) {
          ...dlqEventFields
        }
      }
    }
  }

  ${CommonGraphqlFragments.dlqEvent}
  ${CommonGraphqlFragments.event}
  ${CommonGraphqlFragments.eventContext}
`

const ReprocessDlqEvent = gql`
  mutation reprocessDlqEvent($key: DlqEventKeyInput!) {
    v2Api {
      reprocessDlqEvent(key: $key) {
        key {
          uuid
          id
          process
        }
        success
        message
      }
    }
  }
`

const DeleteDlqEvent = gql`
  mutation deleteDlqEvent($key: DlqEventKeyInput!) {
    v2Api {
      deleteDlqEvent(key: $key) {
        key {
          uuid
          id
          process
        }
        success
        message
      }
    }
  }
`
