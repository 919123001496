import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  fullPageGrid: {
    width: '100vw',
    height: '100vh'
  }
})

const FullPageCircularProgress = ({ classes }) =>
  <Grid
    container
    direction='column'
    justify='center'
    alignItems='center'
    className={classes.fullPageGrid}
  >
    <Grid item xs={1}>
      <CircularProgress />
    </Grid>
  </Grid>

export default withStyles(styles)(FullPageCircularProgress)
