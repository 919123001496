import React from 'react'
import SegmentIcon from 'icons/Segment'
import { FILETYPE_PDF, FILETYPE_POWERPOINT, FILETYPE_WORD } from 'common/proto'
import LogoWord from 'icons/LogoWord'
import LogoPowerpoint from 'icons/LogoPowerpoint'
import LogoPDF from 'icons/LogoPDF'

export default ({ type, size }) => {
  switch (type) {
    case FILETYPE_WORD:
      return <LogoWord style={{ fontSize: size }} />
    case FILETYPE_POWERPOINT:
      return <LogoPowerpoint style={{ fontSize: size }} />
    case FILETYPE_PDF:
      return <LogoPDF style={{ fontSize: size }} />
    default:
      return <SegmentIcon />
  }
}
