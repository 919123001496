import React from 'react'

// function to return the preferred locale, keeping it a function allows for user config changes while the page is loaded
const preferredLocale = () => (navigator.languages && navigator.languages.length > 0) ? navigator.languages[0] : navigator.language

const instantToDateTimeString = (instant, withUtcZone = false) => {
  if (!instant) {
    return 'N/A'
  }

  const options = {
    dateStyle: 'short',
    timeStyle: 'long',
    hour12: false
  }

  if (withUtcZone) {
    // format in UTC, with timezone display for clarity e.g. "2019-12-12, 23:44:44 UTC"
    // default is local time, short, short e.g. "2019-12-12, 19:44:44"
    options.timeZone = 'UTC'
  }

  return <span>{Intl.DateTimeFormat(preferredLocale(), options).format(new Date(instant))}</span>
}

const momentsAreTheSame = (a, b) => {
  return a === b || (a !== null && b !== null && a.diff(b) === 0)
}

export { instantToDateTimeString, momentsAreTheSame }
