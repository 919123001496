import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import * as clipboard from 'clipboard-polyfill'
import Select from 'components/Common/MUIReactSelect'
import useConfirmationDialog from 'hooks/Common/useConfirmationDialog'
import { withSnackbar } from 'notistack'

import { FormattedException } from 'common/graphqlclient/ErrorHandler'
import { additionalAllowedClientCodes, allAllowedClients, codesToClients } from 'common/users/userClients'
import ClientPicker from 'components/Common/ClientPicker'
import useAuthorization from 'hooks/Common/useAuthorization'

const UserFormDialog = ({ me, open, onClose, editUser, selectedClient, enqueueSnackbar, closeSnackbar }) => {
  const { showConfirmationDialog } = useConfirmationDialog()
  const { Roles, Permissions, hasPermission, roleValueToLabel } = useAuthorization()

  const roleOption = useCallback(value => ({
    value: value,
    label: roleValueToLabel(value)
  }), [roleValueToLabel])

  // Internal states
  const [showErrors, setShowErrors] = useState(false)
  const [rolesOptions] = useState(hasPermission(Permissions.MANAGE_USERS_ADVANCED, me)
    ? [Roles.User, Roles.Uploader, Roles.Admin, Roles.SuperAdmin].map(roleOption)
    : [Roles.User, Roles.Uploader, Roles.Admin].map(roleOption)
  )

  // User properties
  const [uuid, setUuid] = useState(null)
  const [clientCode, setClientCode] = useState(me.client.code)
  const [roles, setRoles] = useState([roleOption(Roles.Uploader)])
  const [clientsAllowed, setClientsAllowed] = useState([])
  const [clientsAllowedStar, setClientsAllowedStar] = useState(false)
  // These are clients an existing user is allowed to see but the currently logged-in user isn't
  const [hasHiddenClientsAllowed, setHasHiddenClientsAllowed] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [enabled, setEnabled] = useState(true)

  const emailEl = useRef(null)

  const resetForm = useCallback((partial) => {
    if (!partial) {
      setClientCode(selectedClient)
      setClientsAllowed([])
      setClientsAllowedStar(false)
      setHasHiddenClientsAllowed(false)
      setRoles([roleOption(Roles.Uploader)])
    }
    // Keep the client, clientsAllowed and role untouched to make
    // creating multiple similar users more efficient
    setUuid(null)
    setEmail('')
    setFirstName('')
    setLastName('')
    setJobTitle('')
    setShowErrors(false)
    setEnabled(true)
    if (partial) { emailEl.current.focus() }
  }, [selectedClient, emailEl, roleOption, Roles.Uploader])

  useLayoutEffect(() => {
    if (open) {
      if (editUser) {
        setUuid(editUser.uuid)
        setClientCode(editUser.client.code)
        setRoles(editUser.roles.map(r => rolesOptions.find(o => o.value === r)))
        if (additionalAllowedClientCodes(editUser).includes('*')) {
          setClientsAllowed([])
          setClientsAllowedStar(true)
        } else {
          const visibleClientCodes = additionalAllowedClientCodes(editUser)
          const visibleClients = codesToClients(me, visibleClientCodes)
          setClientsAllowed(visibleClients)
          setClientsAllowedStar(false)
        }
        setHasHiddenClientsAllowed(editUser.clientsAllowedHidden)
        setEmail(editUser.email)
        setFirstName(editUser.firstName)
        setLastName(editUser.lastName)
        setJobTitle(editUser.jobTitle)
        setEnabled(editUser.enabled)
      } else {
        resetForm(false)
      }
    }
  }, [editUser, rolesOptions, resetForm, open, me])

  // Async data
  const { loading: clientLoading, error: clientError, data: clientData } = useQuery(ClientQuery, {
    skip: !open,
    variables: {
      code: clientCode
    }
  })

  useEffect(() => {
    if (open && !clientLoading) {
      if (clientError) {
        enqueueSnackbar(<FormattedException err={clientError} />, { variant: 'error' })
        onClose()
      }
    }
  }, [open, clientLoading, clientError, enqueueSnackbar, onClose])

  // Errors
  const emailError = !email ||
    (hasPermission(Permissions.MANAGE_USERS_ADVANCED, me) && clientData && clientData.v2Api.admin.client && !clientData.v2Api.admin.client.allowedEmailSuffixes.includes(email.substring(email.indexOf('@')))) ||
    (roles.some(r => r && r === Roles.SuperAdmin) && !email.endsWith('@redock.com'))

  // Helper text is only displayed to Admins at the moment
  let emailHelperText = null
  if (hasPermission(Permissions.MANAGE_USERS_ADVANCED, me)) {
    emailHelperText = (!clientData || !clientData.v2Api.admin.client) ? 'Client unknown' : `Allowed emails are: ${clientData.v2Api.admin.client.allowedEmailSuffixes}`
    if (roles.some(r => r === Roles.SuperAdmin) && !email.endsWith('@redock.com')) {
      emailHelperText = 'Only @redock.com emails are allowed for Super Admin users'
    }
  }
  const firstNameError = !firstName
  const lastNameError = !lastName
  const jobTitleError = !jobTitle
  const hasErrors = emailError || firstNameError || lastNameError || jobTitleError

  const [upsertUser, { loading: upsertUserLoading }] = useMutation(UpsertUserMutation, {
    onCompleted: (data) => {
      const result = data.v2Api.upsertUser
      // A zero-length password is possible, if the user already existed in AAD, but needed to
      // be created in our backend -- in this situation there is no new password provided by AAD
      if (result.password && result.password.length > 0) {
        let action
        if (result.status === 'CREATED') {
          action = 'created'
        } else if (result.status === 'UPDATED') {
          action = 'updated'
        }
        const message = <span>{`User ${result.email} ${action} with temporary password `}<b>{result.password}</b></span>
        enqueueSnackbar(message, {
          variant: 'success',
          persist: true,
          action: key => (
            <>
              <Button
                style={{ color: 'white' }} onClick={async () => {
                  const dt = new clipboard.DT()
                  dt.setData('text/plain', result.password)
                  await clipboard.write(dt)
                  window.alert('Password copied to clipboard.')
                }}
              >
                COPY PASSWORD
              </Button>
              <IconButton color='inherit' aria-label='Close' onClick={() => { closeSnackbar(key) }}>
                <CloseIcon />
              </IconButton>
            </>)
        })
        // The dialog overlay prevents selecting and copying the password in the notification so
        // it must be closed in this particular case
        onClose()
      } else if (result.status === 'CREATED') {
        enqueueSnackbar(`User ${result.email} created`, { variant: 'success' })
      } else if (result.status === 'UPDATED') {
        const message = `User ${result.email} updated`
        enqueueSnackbar(message, { variant: 'success' })
      } else if (result.status === 'INVITED') {
        enqueueSnackbar(`User ${result.email} sent an invitation`, { variant: 'success' })
      } else {
        // TODO once we have an error management solution in place, report this silently and make the user message "success"
        enqueueSnackbar(`User ${result.email} created/updated, unknown status ${result.status} from server, please report this via a support ticket`, { variant: 'warning' })
      }
      editUser ? onClose() : resetForm(true)
    },
    onError: (err) => {
      enqueueSnackbar(<FormattedException err={err} />, { variant: 'error' })
    }
  })

  return (
    <Dialog
      open={open && !clientLoading}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{editUser ? 'Edit User' : 'Create User'}</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete='off'>
          <Grid container direction='column' spacing={0}>
            {allAllowedClients(me).length > 1 ? (
              <Grid item xs>
                <FormControl fullWidth margin='normal'>
                  <ClientPicker
                    clients={allAllowedClients(me, true)}
                    useMui
                    textFieldProps={{
                      label: 'Label',
                      InputLabelProps: {
                        shrink: true
                      }
                    }}
                    onChange={v => {
                      setClientCode(v.code)
                      if (clientsAllowed.find(c => c.code === v.code)) {
                        setClientsAllowed(clientsAllowed.filter(c => c.code !== v.code))
                      }
                    }}
                    isMulti={false}
                    disabled={upsertUserLoading}
                    selectedClientCodes={clientCode}
                    hideIfUseless={false}
                    isClearable={false}
                    width='auto'
                    placeholder='Client'
                  />
                </FormControl>
              </Grid>) : null}
            <Grid item xs>
              <Select
                options={rolesOptions}
                name='roles'
                disabled={upsertUserLoading}
                placeholder='Role'
                value={roles[0]}
                onChange={option => setRoles([option])}
              />
            </Grid>
            {allAllowedClients(me).length > 1
              ? (
                <>
                  <Grid item xs>
                    <Box display='flex' alignItems='center'>
                      <Box flexGrow={1}>
                        <ClientPicker
                          clients={allAllowedClients(me, true).filter(c => c.code !== clientCode)}
                          includePrimary={false}
                          useMui
                          onChange={v => {
                            if (v.includes('*')) {
                              setClientsAllowedStar(true)
                              setClientsAllowed([])
                            } else {
                              setClientsAllowedStar(false)
                              setClientsAllowed(v)
                            }
                          }}
                          isMulti
                          disabled={upsertUserLoading}
                          placeholder='Additional Clients Allowed'
                          includeStar={me.clientCodesAllowed.includes('*')}
                          selectedClientCodes={clientsAllowedStar ? ['*'] : clientsAllowed.map(c => c.code)}
                          hideIfUseless={false}
                          isClearable
                          width='auto'
                        />
                      </Box>
                      {hasHiddenClientsAllowed ? (
                        <Box pl={0.75}>
                          <Tooltip title='Some allowed clients hidden due to permissions' placement='top-end'>
                            <WarningIcon />
                          </Tooltip>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                </>
              ) : null}
            <Grid item xs>
              <TextField
                fullWidth
                inputRef={emailEl}
                id='email'
                label='Email'
                variant='outlined'
                disabled={upsertUserLoading}
                value={email}
                error={showErrors && emailError}
                onChange={e => setEmail(e.target.value)}
                helperText={emailHelperText}
                margin='normal'
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                id='first-name'
                label='First Name'
                error={showErrors && firstNameError}
                variant='outlined'
                disabled={upsertUserLoading}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                margin='normal'
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                id='last-name'
                label='Last Name'
                error={showErrors && lastNameError}
                variant='outlined'
                disabled={upsertUserLoading}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                margin='normal'
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                id='jobTitle'
                label='Job Title'
                error={showErrors && jobTitleError}
                variant='outlined'
                disabled={upsertUserLoading}
                value={jobTitle}
                onChange={e => setJobTitle(e.target.value)}
                margin='normal'
              />
            </Grid>
            {(hasPermission(Permissions.MANAGE_USERS_ADVANCED, me) && editUser) ? (
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='enabled'
                      checked={enabled}
                      disabled={upsertUserLoading}
                      onChange={e => setEnabled(e.target.checked)}
                    />
                  }
                  label='Enabled'
                />
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>Cancel</Button>
        <Button
          disabled={(showErrors && hasErrors) || upsertUserLoading}
          type='submit'
          onClick={e => {
            const doUpsert = () => upsertUser({
              variables: {
                user: {
                  uuid: uuid,
                  client: clientCode,
                  clientCodesAllowed: clientsAllowedStar ? ['*'] : clientsAllowed.map(c => c.code),
                  env: me.env,
                  idp: 'aadv2',
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                  jobTitle: jobTitle,
                  roles: roles.map(r => r.value),
                  enabled: enabled
                }
              }
            })

            e.preventDefault()
            setShowErrors(true)
            if (!hasErrors) {
              // Extra step when creating a Super Admin or updating a user to a Super Admin
              if ((!editUser || !editUser.roles.some(r => r === Roles.SuperAdmin)) && roles.some(r => r.value === Roles.SuperAdmin)) {
                showConfirmationDialog({
                  title: editUser ? 'Promote to Super Admin' : 'Create Super Admin',
                  message: editUser
                    ? <span>You are about to promote <b>{email}</b> to a <b>Super Admin</b>. This user will have <b>full access to the reDock system</b>. Are you sure you want to proceed?</span>
                    : <span>You are about to create <b>{email}</b> as a <b>Super Admin</b>. This user will have <b>full access to the reDock system</b>. Are you sure you want to proceed?</span>,
                  target: null,
                  confirmLabel: editUser ? 'Promote to Super Admin' : 'Create Super Admin',
                  onConfirm: doUpsert
                })
                // Extra step when creating or updating any non super-admin user with * clients allowed
              } else if (!roles.some(r => r.value === Roles.SuperAdmin) && (
                (editUser && !additionalAllowedClientCodes(editUser).includes('*') && clientsAllowedStar) ||
                (!editUser && clientsAllowedStar)
              )) {
                showConfirmationDialog({
                  title: editUser ? 'Assign All Clients' : 'Create User with All Clients',
                  message: editUser
                    ? <span>You are about to assign all client access to a user. This user will be able to <b>see all clients in the reDock system</b>. Are you sure you want to proceed?</span>
                    : <span>You are about to create a user with all client access. This user will be able to <b>see all clients in the reDock system</b>. Are you sure you want to proceed?</span>,
                  target: null,
                  confirmLabel: 'Yes, Provide All Access',
                  onConfirm: doUpsert
                })
              } else {
                doUpsert()
              }
            }
          }}
        >
          {editUser ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const UpsertUserMutation = gql`
  mutation upsertUser($user: UpsertUser!) {
    v2Api {
      upsertUser(user: $user) {
        userUuid
        identityUuid
        email
        password
        status
      }
    }
  }
`

const ClientQuery = gql`
  query client($code: ID!) {
    v2Api {
      admin {
        client(code: $code) {
          name
          allowedEmailSuffixes
        }
      }
    }
  }
`

export default withSnackbar(UserFormDialog)
