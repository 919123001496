import React from 'react'
import ReactSelect from 'react-select'
import MUIReactSelect from 'components/Common/MUIReactSelect'

const starClient = { value: '*', label: '* (All)', client: '*' }

const ClientPicker = ({ clients, onChange, isMulti, selectedClientCodes, className, hideIfUseless = true, isClearable = true, includeStar, width, disabled, textFieldProps, useMui, placeholder }) => {
  const styles = {
    container: (provided, state) => ({
      ...provided,
      width: width || 300,
      zIndex: 101 // hackish: Puts it above the table header
    })
  }

  clients = clients.map(client => ({ value: client.code, label: client.name, client }))
  clients = includeStar ? [starClient, ...clients] : clients

  const selectedCodes = !selectedClientCodes ? [] : Array.isArray(selectedClientCodes) ? selectedClientCodes : [selectedClientCodes]
  let selected
  if (includeStar && selectedCodes.includes('*')) {
    selected = starClient
  } else {
    selected = clients.filter(c => selectedCodes.includes(c.value))
  }

  const onChangeHandler = (items, action) => {
    if (isMulti) {
      onChange(items ? items.map(v => v.client) : [])
    } else {
      onChange(items.client || null)
    }
  }

  if (hideIfUseless && clients.length <= 1) {
    return null
  } else {
    return (
      useMui ? (
        <MUIReactSelect
          className={className}
          isDisabled={disabled}
          textFieldProps={textFieldProps}
          value={selected}
          isClearable={isClearable}
          isSearchable
          isMulti={isMulti}
          name='clients'
          options={clients}
          onChange={onChangeHandler}
          placeholder={placeholder}
        />
      ) : (
        <ReactSelect
          className={className}
          styles={styles}
          isDisabled={disabled}
          textFieldProps={textFieldProps}
          value={selected}
          isClearable={isClearable}
          isSearchable
          isMulti={isMulti}
          name='clients'
          placeholder={includeStar ? 'None' : 'Any Client'}
          options={clients}
          onChange={onChangeHandler}
        />)
    )
  }
}

export default ClientPicker
