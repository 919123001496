/**
 * Initializes the Zoho ASAP web add-in. Note that, if `window.ZohoHCAsapSettings` needs to be set with any
 * values, it should be done here. The docs are not clear about this, but the settings need to be available
 * *before* the call to initialize the add-in.
 * @param asapId
 * @param authService
 * @param zohoDeskToken
 */
export async function initZohoDesk (asapId, authService, zohoDeskToken) {
  const user = authService.getUser()
  if (!user) return

  window.ZohoHCAsapSettings = {
    hideLauncherIcon: true,
    userInfo: {
      jwtToken: zohoDeskToken
    }
  }

  // copied directly from the ASAP configuration, *except* the asap ID is from the config
  // eslint-disable-next-line
  window.ZohoHCAsap=window.ZohoHCAsap||function(a,b){ZohoHCAsap[a]=b;};(function(){var d=document; var s=d.createElement("script");s.type="text/javascript";s.defer=true; s.src=`https://desk.zoho.com/portal/api/web/inapp/${asapId}?orgId=689267795`; d.getElementsByTagName("head")[0].appendChild(s); })();
}
