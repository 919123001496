import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

const ConfirmationDialog = ({
  // Set to true to open the dialog
  open = false,
  // Title at the top of the dialog
  title = 'Confirmation Dialog',
  // Message displayed in the dialog
  message = 'Please confirm to continue.',
  // If set to a String, the user will need to type in this exact value (case-sensitive) in order
  // to enable the 'Confirm' button of the dialog
  target = null,
  // Label of the input field where user enters the 'target'
  targetLabel = 'Type target here',
  // Invoked when the user successfully clicks the 'Confirm' button
  onConfirm = null,
  // Invoked when the dialog closes (confirm, cancel, etc.)
  onClose = null,
  // Label of the "Confirm" button
  confirmLabel = 'Confirm',
  // Label of the 'Cancel' button
  cancelLabel = 'Cancel',
  // If TRUE, the 'Confirm' button is displayed. This allows the ConfirmationDialog to act as a notification
  // dialog that the user can only close
  showConfirm = true,
  // If TRUE, the 'Cancel' button is displayed
  showCancel = true
}) => {
  const [value, setValue] = useState('')

  // Reset the value each time the dialog is closed/opened
  useEffect(() => {
    setValue('')
  }, [open])

  const handleConfirm = (e) => {
    onConfirm && onConfirm(e)
    onClose && onClose(e)
  }

  const handleKeyPress = (e) => {
    if (e.which === 13 && (!target || target === value)) {
      e.preventDefault()
      handleConfirm(e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyPress={handleKeyPress}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
        {showConfirm && target && (
          <TextField
            autoFocus
            margin='dense'
            label={targetLabel || 'Target'}
            fullWidth
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <Button onClick={onClose} color='primary'>
            {cancelLabel}
          </Button>
        )}
        {showConfirm && (
          <Button disabled={target && value !== target} onClick={handleConfirm} color='primary'>
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
