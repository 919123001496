import moment from 'moment'
import { dateTypeEnum } from 'common/config/Constants'

const dateFormat = 'YYYYMMDD'

const QUERY_KEY = 'q'
const TRIGGER_KEY = 't'
const SOURCE_KEY = 'f' // [f]rom (aka Source)
const SEGMENT_ID_KEY = 's'
const DATE_TYPE_KEY = 'd'
const DATE_FROM_KEY = 'df'
const DATE_TO_KEY = 'dt'
const SEGMENT_TYPES_KEY = 'st'
const SOURCE_CONTEXT_KEY = 'c'

const extractParams = (location) => {
  if (!location) { return {} }

  const urlParams = new URLSearchParams(location.search)
  const query = urlParams.get(QUERY_KEY) || '' // Input component needs a default
  const trigger = urlParams.get(TRIGGER_KEY) || 'LOCATIONBASED'
  const source = urlParams.get(SOURCE_KEY) || 'IN_APP'
  const segmentId = urlParams.get(SEGMENT_ID_KEY)

  const dateTypeRaw = urlParams.get(DATE_TYPE_KEY)
  const dateType = dateTypeRaw ? JSON.parse(dateTypeRaw) : dateTypeEnum.ANY

  const dateFromRaw = urlParams.get(DATE_FROM_KEY)
  const dateFrom = dateFromRaw ? moment(dateFromRaw, dateFormat) : null

  const dateToRaw = urlParams.get(DATE_TO_KEY)
  const dateTo = dateToRaw ? moment(dateToRaw, dateFormat) : null

  const segmentTypesRaw = urlParams.get(SEGMENT_TYPES_KEY)
  const segmentTypes = segmentTypesRaw ? segmentTypesRaw.split(',') : []

  const sourceContext = urlParams.get(SOURCE_CONTEXT_KEY) || '' // Input component needs a default

  return {
    query,
    trigger,
    source,
    segmentId,
    dateType,
    dateFrom,
    dateTo,
    segmentTypes,
    sourceContext
  }
}

const formatParams = ({ query, trigger, source, segmentId, dateType, dateFrom, dateTo, segmentTypes, sourceContext }) => {
  let result = ''

  if (query) {
    result += result === '' ? '?' : '&'
    result += `${QUERY_KEY}=${encodeURIComponent(query)}`
  }

  if (trigger) {
    result += result === '' ? '?' : '&'
    result += `${TRIGGER_KEY}=${trigger}`
  }

  if (source) {
    result += result === '' ? '?' : '&'
    result += `${SOURCE_KEY}=${source}`
  }

  if (segmentId) {
    result += result === '' ? '?' : '&'
    result += `${SEGMENT_ID_KEY}=${encodeURIComponent(segmentId)}`
  }

  if (dateType) {
    result += result === '' ? '?' : '&'
    result += `${DATE_TYPE_KEY}=${encodeURIComponent(dateType)}`
  }

  if (dateFrom) {
    result += result === '' ? '?' : '&'
    result += `${DATE_FROM_KEY}=${encodeURIComponent(dateFrom.format(dateFormat))}`
  }

  if (dateTo) {
    result += result === '' ? '?' : '&'
    result += `${DATE_TO_KEY}=${encodeURIComponent(dateTo.format(dateFormat))}`
  }

  if (segmentTypes && segmentTypes.length) {
    result += result === '' ? '?' : '&'
    result += `${SEGMENT_TYPES_KEY}=${encodeURIComponent(segmentTypes.join(','))}`
  }

  if (sourceContext) {
    result += result === '' ? '?' : '&'
    result += `${SOURCE_CONTEXT_KEY}=${encodeURIComponent(sourceContext)}`
  }

  return result
}

export { extractParams, formatParams }
