import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useStorageState } from 'react-storage-hooks'

const TIP_CARD_WIDTH = 650

const useStyles = makeStyles(theme => ({
  card: {
    // marginTop: theme.spacing(6),
    width: TIP_CARD_WIDTH,
    // background: theme.palette.grey[200],
    '& img': {
      // This ensures images added to the card are never wider than the available area inside the
      // card content (816-(16*2) = 784)
      maxWidth: TIP_CARD_WIDTH - (16 * 2)
    }
  },
  logo: {
    marginRight: theme.spacing(1),
    width: 24
  },
  cardContent: {
    overflowY: 'auto',
    minHeight: 100,
    maxHeight: 600
  }
}))

const TipCard = ({ style, tip, loadPreviousTip, loadNextTip, onClose }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Card className={classes.card} elevation={4} style={style}>
      <CardHeader
        avatar={<img alt='reDock' src='/favicon-32x32.png' className={classes.logo} />}
        action={(
          <IconButton aria-label='Close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        title={tip.title}
        subheader={tip.category}
      />
      <CardContent dangerouslySetInnerHTML={{ __html: tip.content }} className={classes.cardContent} />
      <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant='text' color='secondary' onClick={() => {
            loadPreviousTip({ variables: { uuid: tip.uuid } })
          }}
        >Previous Tip
        </Button>
        <Button
          variant='text' color='secondary' className={classes.button} onClick={() => {
            loadNextTip({ variables: { uuid: tip.uuid } })
          }}
        >Next Tip
        </Button>
      </CardActions>
    </Card>
  )
}

const Tips = ({ show = true }) => {
  const LOCAL_STORAGE_VIEWED_TIPS_UUIDS = 'Tips-viewedTipsUuids'
  const SESSION_STORAGE_TIPS_CLOSED = 'Tips-closed'

  const [closed, setClosed, closedWriteError] = useStorageState(window.sessionStorage, SESSION_STORAGE_TIPS_CLOSED, false)
  const [viewedTipsUuids, setViewedTipsUuids, viewedTipsUuidsError] = useStorageState(window.localStorage, LOCAL_STORAGE_VIEWED_TIPS_UUIDS, [])
  const [tip, setTip] = useState(null)

  if (closedWriteError) { console.error('Cannot write `closed` state to session storage') }
  if (viewedTipsUuidsError) { console.error('Cannot write `viewedTips` state to local storage') }

  const showTip = newTip => {
    setViewedTipsUuids(prev => [...new Set([...prev, newTip.uuid])])
    setTip(newTip)
  }

  useQuery(RandomTipQuery, {
    skip: !show || !!tip,
    // This query should never be cached since it will not always return the same value
    fetchPolicy: 'no-cache',
    variables: { excludedUuids: viewedTipsUuids },
    // onCompleted can be called with data=undefined when skipped
    // See https://github.com/apollographql/react-apollo/issues/3943
    onCompleted: data => {
      if (data && data.v2Api.getRandomTip) {
        showTip(data.v2Api.getRandomTip)
      }
    }
  })

  const [loadNextTip] = useLazyQuery(NextTipQuery, {
    // This is a HACK to work around a problem where in some occasions when you go back
    // and forth between previous and next one of the two will stop working (e.g. you click the "next" button, the
    // uuid passed as a variable is correct but onCompleted will not be called and the tip remains the same)
    // Might be related to https://github.com/apollographql/react-apollo/issues/2177
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.v2Api.getNextTip) {
        showTip(data.v2Api.getNextTip)
      }
    }
  })

  const [loadPreviousTip] = useLazyQuery(PreviousTipQuery, {
    // This is a HACK to work around a problem where in some occasions when you go back
    // and forth between previous and next one of the two will stop working (e.g. you click the "next" button, the
    // uuid passed as a variable is correct but onCompleted will not be called and the tip remains the same)
    // Might be related to https://github.com/apollographql/react-apollo/issues/2177
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.v2Api.getPreviousTip) {
        showTip(data.v2Api.getPreviousTip)
      }
    }
  })

  const onClose = () => {
    setClosed(true)
  }

  if (!tip) return null

  const visible = show && tip && !closed

  return (
    <Fade in={visible} style={{ transitionDelay: visible ? '1000ms' : '0ms' }}>
      <TipCard tip={tip} loadPreviousTip={loadPreviousTip} loadNextTip={loadNextTip} onClose={onClose} />
    </Fade>
  )
}

const RandomTipQuery = gql`
  query getRandomTip($excludedUuids: [String!]) {
    v2Api {
      getRandomTip(excludedUuids: $excludedUuids) {
        uuid
        pos
        title
        category
        content
      }
    }
  }
`

const NextTipQuery = gql`
  query getNextTip($uuid: String!) {
    v2Api {
      getNextTip(uuid: $uuid) {
        uuid
        pos
        title
        category
        content
      }
    }
  }
`

const PreviousTipQuery = gql`
  query getPreviousTip($uuid: String!) {
    v2Api {
      getPreviousTip(uuid: $uuid) {
        uuid
        pos
        title
        category
        content
      }
    }
  }
`

export default Tips
