import React, { useCallback, useContext, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

import { acceptedTypes, acceptedMimeTypes } from 'common/config/Constants'

import { theme } from 'services/theme/reDockTheme'
import FilesUploaderContext from 'contexts/Common/FilesUploaderContext'

const UploadDrop = ({ children, selectedClient }) => {
  const filesUploaderContext = useContext(FilesUploaderContext)

  const adjustFileRejections = fileRejections => {
    const accepted = []
    const rejections = []
    fileRejections.forEach(r => {
      if (r.errors.find(e => e.code === 'file-invalid-type') && r.file.type === '') {
        // Because the file.type returned by browsers for dropped files is unreliable we sometimes
        // fallback to accepting files based on extension instead
        // See https://github.com/react-dropzone/react-dropzone/issues/241
        const tuple = acceptedTypes.find(ft => r.file.path.endsWith(ft.ext))
        if (tuple) {
          accepted.push(r.file)
        } else {
          rejections.push(r)
        }
      } else {
        rejections.push(r)
      }
    })

    return { accepted, rejections }
  }

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    const { accepted, rejections } = adjustFileRejections(fileRejections)

    filesUploaderContext.addDroppedFiles({
      acceptedFiles: [...acceptedFiles, ...accepted],
      fileRejections: [...rejections],
      client: selectedClient
    })
  }, [selectedClient, filesUploaderContext])

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: acceptedMimeTypes,
    // Keep in sync with #MAXFILELENGTH
    maxSize: 50000000,
    onDrop
  })

  const baseStyle = {
    padding: theme.spacing(1),
    borderWidth: 3,
    borderColor: theme.palette.secondary.light,
    borderStyle: 'dashed',
    borderRadius: 5,
    cursor: 'pointer',
    transition: 'border-color 250ms linear, background-color 250ms linear',
    outline: 'none'
  }

  const acceptStyle = {
    cursor: 'copy',
    borderColor: theme.palette.secondary.dark,
    backgroundColor: theme.primaryHoverBackground,
    transition: 'border-color 500ms linear, background-color 500ms linear'
  }

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragAccept ? acceptStyle : {})
  }), [acceptStyle, baseStyle, isDragAccept])

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

export default UploadDrop
