import React, { useState } from 'react'
import gql from 'graphql-tag'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RedoIcon from '@material-ui/icons/Redo'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useSnackbar } from 'notistack'
import { instantToDateTimeString } from 'common/utilities/dates'
import { extractDlqEventKey, setDlqEventKey } from 'common/utilities/dlqUtilities'
import CommonGraphqlFragments from 'common/graphqlclient/Fragments'
import DataTable from 'components/Common/DataTable'
import DlqEventDetail from './DlqEventDetail'
import useConfirmationDialog from 'hooks/Common/useConfirmationDialog'
import { useMutation } from '@apollo/react-hooks'

import { removeTypename } from 'common/utilities/graphql'
import { FormattedException } from 'common/graphqlclient/ErrorHandler'

const PATHNAME = '/admin/dlq'

const styles = theme => ({
  item: {
    padding: theme.spacing(1)
  }
})

const DlqEvents = ({ me, classes, selectedClient, location, history }) => {
  const { showConfirmationDialog } = useConfirmationDialog()
  const { enqueueSnackbar } = useSnackbar()

  const [selectedRows, setSelectedRows] = useState([])
  const [refetch, setRefetch] = useState(null)
  const [rawData, setRawData] = useState(null)
  const [includeAllStatuses, setIncludeAllStatuses] = useState(false)

  const showEventDetails = key => {
    setDlqEventKey(history, PATHNAME, key)
  }

  const onRowsSelected = (newSelectedRows, selectedRows) => {
    setSelectedRows(selectedRows.map(r => r.index))
  }

  const clearSelectedRows = () => {
    setSelectedRows([])
  }

  const selectedRowsToKeys = selectedRows => {
    const events = rawData.v2Api.admin.dlqEvents.events
    return selectedRows.data.map(selectedRow => events[selectedRow.dataIndex].key)
  }

  const [reprocessEventMutation] = useMutation(ReprocessDlqEvent, {
    onCompleted: data => {
      const result = data.v2Api.reprocessDlqEvent
      if (result.success) {
        enqueueSnackbar('Causal event added back to processing queue. Dlq Event marked as REPROCESSED.')
      } else {
        enqueueSnackbar(result.message, { variant: 'error', persist: true })
      }

      if (refetch) { refetch() }
    },
    onError: error => {
      console.log(error)
      enqueueSnackbar(<FormattedException err={error} />, { variant: 'error', persist: true })
    }
  })

  const [reprocessEventsMutation] = useMutation(ReprocessDlqEvents, {
    onCompleted: data => {
      const results = data.v2Api.reprocessDlqEvents
      if (results.every(r => r.success)) {
        enqueueSnackbar(`${results.length} causal event(s) added back to processing queue and Dlq Event(s) marked as REPROCESSED.`)
      } else if (results.every(r => !r.success)) {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`ERROR: ${r.key.uuid}: ${r.message}`}</li>
          )}
          </ul>
          , { variant: 'error', persist: true }
        )
      } else {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`${r.success ? 'SUCCESS' : 'ERROR'}: ${r.key.uuid}${r.success ? '' : `: ${r.message}`}`}</li>
          )}
          </ul>
          , { variant: 'warning', persist: true }
        )
      }

      if (refetch) { refetch() }
      setSelectedRows([])
    },
    onError: error => {
      console.log(error)
      enqueueSnackbar(<FormattedException err={error} />, { variant: 'error' })
    }
  })

  const [deleteEventMutation] = useMutation(DeleteDlqEvent, {
    onCompleted: data => {
      const result = data.v2Api.deleteDlqEvent
      if (result.success) {
        enqueueSnackbar('Dlq Event marked for deletion.')
      } else {
        enqueueSnackbar(result.message, { variant: 'error', persist: true })
      }
      if (refetch) { refetch() }
    },
    onError: error => {
      console.log(error)
      enqueueSnackbar(<FormattedException err={error} />, { variant: 'error' })
    }
  })

  const [deleteEventsMutation] = useMutation(DeleteDlqEvents, {
    onCompleted: data => {
      const results = data.v2Api.deleteDlqEvents
      if (results.every(r => r.success)) {
        enqueueSnackbar(`${results.length} ${results.length > 1 ? 'Dlqs have' : 'Dlq has'} been marked for deletion.`)
      } else if (results.every(r => !r.success)) {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`ERROR: ${r.key.uuid}: ${r.message}`}</li>
          )}
          </ul>
          , { variant: 'error', persist: true }
        )
      } else {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`${r.success ? 'SUCCESS' : 'ERROR'}: ${r.key.uuid}${r.success ? '' : `: ${r.message}`}`}</li>
          )}
          </ul>
          , { variant: 'warning', persist: true }
        )
      }

      if (refetch) { refetch() }
      setSelectedRows([])
    },
    onError: error => {
      console.log(error)
      enqueueSnackbar(<FormattedException err={error} />, { variant: 'error' })
    }
  })

  const reprocessEvent = key => {
    showConfirmationDialog({
      title: 'Reprocess Dlq Event',
      message: 'This will reprocess the causal event of this Dlq Event. Are you sure?',
      confirmLabel: 'Reprocess',
      onConfirm: () => reprocessEventMutation({ variables: { key: removeTypename(key) } })
    })
  }

  const reprocessEvents = keys => {
    showConfirmationDialog({
      title: 'Reprocess Dlq Events',
      message: `This will reprocess the causal event of the ${keys.length} selected Dlq Events. Are you sure?`,
      confirmLabel: 'Reprocess',
      onConfirm: () => reprocessEventsMutation({ variables: { keys: keys.map(k => removeTypename(k)) } })
    })
  }

  const deleteEvent = key => {
    showConfirmationDialog({
      title: 'Delete Dlq Event',
      message: 'This will mark this Dlq Event for deletion. To confirm, please type \'DELETE\' below:',
      target: 'DELETE',
      targetLabel: 'Confirm Action',
      confirmLabel: 'Delete',
      onConfirm: () => {
        deleteEventMutation({ variables: { key: removeTypename(key) } })
      }
    })
  }

  const deleteEvents = keys => {
    showConfirmationDialog({
      title: 'Delete Dlq Events',
      message: `This will mark the ${keys.length} selected Dlq Event for deletion. To confirm, please re-type 'DELETE' below:`,
      target: 'DELETE',
      targetLabel: 'Confirm Action',
      confirmLabel: 'Delete',
      onConfirm: () => {
        deleteEventsMutation({ variables: { keys: keys.map(k => removeTypename(k)) } })
      }
    })
  }

  // Configure the mui-table  (https://github.com/gregnb/mui-datatables)
  const columns = [
    { name: 'Status' },
    { name: 'Uuid' },
    { name: 'Client' },
    { name: 'User' },
    { name: 'File cmsUuid' },
    { name: 'CausalEventName' },
    {
      name: 'Timestamp',
      options: {
        customBodyRender: (value) => <span>{instantToDateTimeString(value, true)}</span>
      }
    },
    { name: 'Message' },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value) => (
          <Box display='flex' flexDirection='row' flexWrap='nowrap'>
            <Tooltip title='View Details'><IconButton onClick={() => showEventDetails(value[0])}><VisibilityIcon /></IconButton></Tooltip>
            <Tooltip title='Reprocess'><IconButton onClick={() => reprocessEvent(value[0])}><RedoIcon /></IconButton></Tooltip>
            <Tooltip title='Delete'><IconButton onClick={() => deleteEvent(value[0])}><DeleteIcon /></IconButton></Tooltip>
          </Box>
        )
      }
    }
  ]

  const dlqEventKey = extractDlqEventKey(location)
  const detailsView = <DlqEventDetail dlqEventKey={dlqEventKey} onClose={() => setDlqEventKey(history, PATHNAME, null)} />

  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      style={{ height: '100%' }}
    >
      {detailsView}
      <Grid item xs style={{ display: dlqEventKey ? 'none' : 'initial', height: '100%' }} className={classes.item}>
        <DataTable
          id='DlqEvents'
          title='Dlq Events'
          me={me}
          query={DlqEventsQuery}
          queryVariables={{ includeAllStatuses }}
          customToolbar={(
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeAllStatuses} onChange={() => setIncludeAllStatuses(prev => !prev)}
                  value='includeAllStatuses'
                />
              }
              label='Include All Statuses'
            />
          )}
          defaultValues={{ clientCodes: [selectedClient] }}
          setRefetchData={f => setRefetch(() => f)}
          setRawData={setRawData}
          clearSelectedRows={clearSelectedRows}
          columns={columns}
          options={{
            selectableRows: 'multiple',
            rowsSelected: selectedRows,
            onRowsSelect: onRowsSelected,
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip title='Reprocess Selected DlqEven(s)'>
                  <IconButton onClick={() => reprocessEvents(selectedRowsToKeys(selectedRows))}>
                    <RedoIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Delete Selected DlqEvent(s)'>
                  <IconButton onClick={() => deleteEvents(selectedRowsToKeys(selectedRows))}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
          }}
          extractData={data => data.v2Api.admin.dlqEvents.events.map(e => [
            e.status,
            e.key.uuid,
            e.client,
            e.user,
            e.cmsUuid,
            e.causalEventName,
            e.producedEvent.context.timestamp,
            e.message,
            // This is the dumbest thing but mui-datatables throws a warning (and eventually will throw an error)
            // when passing an object (for reasons that do not affect us since we are doing all the sorting/filtering
            // server-side) so we are working around that by passing in the object inside an array
            [e.key]
          ])}
          extractTotal={data => data.v2Api.admin.dlqEvents.total}
        />
      </Grid>
    </Grid>
  )
}

const DlqEventsQuery = gql`
  query dlqEventsQuery($clients: [ID!], $page: Int!, $rowsPerPage: Int!, $includeAllStatuses: Boolean!, $searchText: String) {
    v2Api {
      admin{
        dlqEvents(clients: $clients, page: $page, rowsPerPage: $rowsPerPage, includeAllStatuses: $includeAllStatuses, searchText: $searchText) {
          total
          events {
            ...dlqEventFields
          }
        }
      }
    }
  }

  ${CommonGraphqlFragments.dlqEvent}
  ${CommonGraphqlFragments.event}
  ${CommonGraphqlFragments.eventContext}
`

const ReprocessDlqEvent = gql`
  mutation reprocessDlqEvent($key: DlqEventKeyInput!) {
    v2Api {
      reprocessDlqEvent(key: $key) {
        key {
          uuid
          id
          process
        }
        success
        message
      }
    }
  }
`

const ReprocessDlqEvents = gql`
  mutation reprocessDlqEvents($keys: [DlqEventKeyInput!]!) {
    v2Api {
      reprocessDlqEvents(keys: $keys) {
        key {
          uuid
          id
          process
        }
        success
        message
      }
    }
  }
`

const DeleteDlqEvent = gql`
  mutation deleteDlqEvent($key: DlqEventKeyInput!) {
    v2Api {
      deleteDlqEvent(key: $key) {
        key {
          uuid
          id
          process
        }
        success
        message
      }
    }
  }
`

const DeleteDlqEvents = gql`
  mutation deleteDlqEvents($keys: [DlqEventKeyInput!]!) {
    v2Api {
      deleteDlqEvents(keys: $keys) {
        key {
          uuid
          id
          process
        }
        success
        message
      }
    }
  }
`

export { DlqEvents }

export default withStyles(styles)(DlqEvents)
