import React, { useLayoutEffect, useState } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import ClientManagement from 'scenes/admin/ClientManagement'
import ConnectorManagement from 'scenes/admin/ConnectorManagement'
import UserManagement from 'scenes/admin/Users'
import DlqEvents from 'scenes/admin/DlqEvents'
import FileStatuses from 'scenes/admin/FileStatuses'
import useAuthorization from 'hooks/Common/useAuthorization'
import PermissionRoute from 'components/Routes/PermissionRoute'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 64px)' // 64px is the top AppBar
  },
  margin: {
    margin: theme.spacing(1)
  },
  message: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  messageContainer: {
    flexGrow: 0
  },
  tabsContainer: {
    flexGrow: 0,
    width: '100%'
  },
  tabs: {
    marginBottom: theme.spacing(4)
  },
  contentContainer: {
    overflowX: 'hidden',
    overflowY: 'auto'
  }
})

const Admin = ({ classes, me, refreshMe, selectedClient, history, location }) => {
  const { Permissions, hasPermission } = useAuthorization()

  const defaultTab = (me.features.fileStatuses.enabled && hasPermission(Permissions.MANAGE_FILES, me)) ? 'file-statuses' : 'connectors'
  const [selectedTab, setSelectedTab] = useState(defaultTab)

  useLayoutEffect(() => {
    let newTab
    switch (location.pathname) {
      case '/admin/file-statuses':
        newTab = 'file-statuses'
        break
      case '/admin/connectors':
        newTab = 'connectors'
        break
      case '/admin/client':
        newTab = 'client'
        break
      case '/admin/user':
        newTab = 'user'
        break
      case '/admin/dlq':
        newTab = 'dlq'
        break
      default:
        break
    }
    setSelectedTab(prev => newTab || prev)
  }, [location])

  const handleTabChange = (event, value) => {
    let path
    switch (value) {
      case 'file-statuses':
        path = '/admin/file-statuses'
        break
      case 'connectors':
        path = '/admin/connectors'
        break
      case 'client':
        path = '/admin/client'
        break
      case 'user':
        path = '/admin/user'
        break
      case 'dlq':
        path = '/admin/dlq'
        break
      default:
        throw new Error('Invalid tab value')
    }
    history.push(path, {
      selectedTab: value
    })
  }

  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems='stretch'
      className={classes.root}
    >
      {me.features.fileStatuses.enabled && hasPermission(Permissions.MANAGE_FILES_ADVANCED, me) && (
        <Grid item xs className={classes.messageContainer}>
          <Paper className={classnames(classes.margin, classes.message)} elevation={0}>
            <Typography color='secondary'>
              <InfoIcon fontSize='large' style={{ verticalAlign: 'middle' }}>Info</InfoIcon>
              <>
                You are in the administration area for the <strong>{me.env}</strong> environment.
              </>
            </Typography>
          </Paper>
        </Grid>
      )}
      <Grid item xs className={classes.tabsContainer}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          {me.features.fileStatuses.enabled && hasPermission(Permissions.MANAGE_FILES, me) && <Tab label='File Statuses' value='file-statuses' />}
          {hasPermission(Permissions.MANAGE_CONNECTORS, me) && <Tab label='Connectors' value='connectors' />}
          {hasPermission(Permissions.MANAGE_CLIENTS, me) && <Tab label='Client Management' value='client' />}
          {me.features.userManagement.enabled && hasPermission(Permissions.MANAGE_USERS, me) && <Tab label='User Management' value='user' />}
          {hasPermission(Permissions.MANAGE_DLQS, me) && <Tab label='Dlq Events' value='dlq' />}
        </Tabs>
      </Grid>
      <Grid item xs className={classes.contentContainer}>
        <Switch>
          {me.features.fileStatuses.enabled ? (
            <PermissionRoute
              permission={Permissions.MANAGE_FILES}
              me={me}
              path='/admin/file-statuses'
              render={({ history, location }) => <FileStatuses me={me} env={me.env} history={history} location={location} selectedClient={selectedClient} />}
            />
          ) : null}
          <PermissionRoute
            permission={Permissions.MANAGE_CONNECTORS}
            me={me}
            path='/admin/connectors'
            render={({ location }) => <ConnectorManagement me={me} selectedClient={selectedClient} location={location} />}
          />
          <PermissionRoute
            permission={Permissions.MANAGE_CLIENTS}
            me={me}
            path='/admin/client'
            render={() => <ClientManagement me={me} refreshMe={refreshMe} />}
          />
          {me.features.userManagement.enabled ? (
            <PermissionRoute
              permission={Permissions.MANAGE_USERS}
              me={me}
              path='/admin/user'
              render={() => <UserManagement me={me} env={me.env} selectedClient={selectedClient} />}
            />
          ) : null}
          <PermissionRoute
            permission={Permissions.MANAGE_DLQS}
            me={me}
            path='/admin/dlq'
            render={({ history, location }) => <DlqEvents me={me} env={me.env} history={history} location={location} selectedClient={selectedClient} />}
          />
          <Redirect to={`/admin/${defaultTab}`} />
        </Switch>
      </Grid>
    </Grid>
  )
}

export { Admin }

export default withStyles(styles)(Admin)
