function httpToWs (httpUrl) {
  const url = new URL(httpUrl)
  url.protocol = url.protocol.replace('http', 'ws')
  url.pathname = '/subscriptions'
  return url.href
}

export default async function appConfig () {
  // use compiled-in vars at dev time, when running in prod, we get the URLs from the server config
  if (process.env.NODE_ENV === 'development') {
    const graphQlUrl = process.env.REACT_APP_APPJS_GRAPHQL_URL || 'http://localhost:8080/graphql'
    return {
      urls: {
        graphQl: graphQlUrl,
        subscriptions: process.env.REACT_APP_APPJS_WS_URL || httpToWs(graphQlUrl)
      },
      zohoDesk: {
        asapId: process.env.REACT_APP_ZOHODESK_ASAP_ID || '394024000009826001'
      },
      constants: {
        maxUploadSlots: (process.env.REACT_APP_CONSTANT_MAX_UPLOAD_SLOTS && parseInt(process.env.REACT_APP_CONSTANT_MAX_UPLOAD_SLOTS)) || 100
      }
    }
  } else {
    const response = await window.fetch('/config', {
      redirect: 'manual',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        Cache: 'no-cache'
      },
      credentials: 'same-origin'
    })

    if (response.type === 'opaqueredirect' || !response.ok) {
      const error = Error('Unable to retrieve API config')
      error.response = response
      throw error
    }

    const responseBody = await response.json()
    const urls = responseBody.urls
    if (urls.graphQl && !urls.subscriptions) {
      urls.subscriptions = httpToWs(urls.graphQl)
    }
    return {
      urls,
      ...responseBody
    }
  }
}
