import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import classnames from 'classnames'
import { detect } from 'detect-browser'
import gql from 'graphql-tag'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LockIcon from '@material-ui/icons/Lock'
import MenuIcon from '@material-ui/icons/Menu'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/Help'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import SearchIcon from '@material-ui/icons/Search'
import SettingsApplicationIcon from '@material-ui/icons/SettingsApplications'
import { useSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import { IntlProvider } from 'react-intl'
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { useStorageState } from 'react-storage-hooks'
import { allAllowedClients } from 'common/users/userClients'

import AnchorButton from 'components/Common/AnchorButton'
import { initZohoDesk } from 'common/zohodesk/ZohoDesk'
import { TopLevelErrorException } from 'components/Error/topLevelError'
import FullPageCircularProgress from 'components/Progress/FullPageCircularProgress'
import { LoginAccessDenied, LoginInProgress } from 'services/auth/Errors'
import { theme } from 'services/theme/reDockTheme'
import Admin from 'scenes/admin/Admin'
import Search from 'scenes/search/Search'
import Upload from 'scenes/upload/Upload'
import ProfileMenu from 'scenes/profile/ProfileMenu'
import Logout from 'scenes/logout/Logout'
import * as generatedMessages from 'translations'
import ClientPicker from 'components/Common/ClientPicker'
import UploadMenuIcon from 'components/Upload/UploadMenuIcon'
import PermissionRoute from 'components/Routes/PermissionRoute'
import useAuthorization from 'hooks/Common/useAuthorization'

const SESSION_STORAGE_SELECTED_CLIENT = 'redock.selectedClient'
const drawerWidth = 240
const browser = detect()
const showIeWarning = browser.name === 'ie'

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    paddingRight: 0
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  drawerToggleIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  margin: {
    margin: theme.spacing(1)
  },
  ieWarning: {
    textAlign: 'center'
  },
  clientSelection: {
    color: theme.palette.primary.contrastText,
    paddingTop: 10
  },
  clientSelectionControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    backgroundColor: theme.secondarySelectionBackground,
    color: theme.palette.text.primary
  },
  title: {
    flexGrow: 1,
    color: 'white',
    textDecoration: 'none'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative',
    padding: 0,
    marginTop: 64
  },
  signinAvatar: {
    margin: `${theme.spacing(1)}px auto`,
    backgroundColor: theme.palette.primary.main
  },
  signinButton: {
    marginTop: theme.spacing(3)
  },
  signinModalPaper: {
    position: 'fixed',
    width: '40%',
    left: '30%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '25%',
    margin: 'auto'
  }
})

const ClientChooser = ({ me, classes, selectedClient, setSelectedClient }) => {
  if (!me) { return null }

  if (allAllowedClients(me).length > 1) {
    return (
      <ClientPicker
        clients={allAllowedClients(me, true)}
        className={classes.clientSelectionControl}
        isClearable={false}
        selectedClientCodes={selectedClient}
        onChange={client => setSelectedClient(client.code)}
      />
    )
  } else {
    return (
      <Typography variant='h6' color='inherit' noWrap className={classes.title}>
        {me.client.name}
      </Typography>
    )
  }
}

const PageLayout = ({ history, authService, zohoDeskAsapId, classes, intl, features, constants, client }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { Permissions, hasPermission } = useAuthorization()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [signinMessage, setSigningMessage] = useState(null)
  const [mountError, setMountError] = useState(null)
  const [me, setMe] = useState(null)
  const [zohoDeskToken, setZohoDeskToken] = useState(null)
  const [locale] = useState('en') // We don't have a setter yet but will once we support multiple locales
  const [asapLogin, setAsapLogin] = useState(false)
  const [selectedClient, setSelectedClient] = useStorageState(window.sessionStorage, SESSION_STORAGE_SELECTED_CLIENT, false)
  const [apiVersion, setApiVersion] = useState(null)

  const user = authService.getUser()

  const { data: apiVersionData, loading: apiVersionLoading, error: apiVersionError } = useQuery(ApiVersionQuery, {
    skip: !user || mountError,
    fetchPolicy: 'no-cache',
    pollInterval: 600000 // 10min in ms
  })

  // #apollo3
  // This is required because apollo does not invoke onCompleted during polling (even if the data has changed it seemed)
  // We should refactor this inside onCompleted and onError in the useQuery above once we upgrade to apollo 3.0
  useEffect(() => {
    // TODO Update once we have bug tracking in place
    if (apiVersionError) { setMountError(apiVersionError) }
    if (apiVersionLoading || !apiVersionData) { return }

    const newVersion = apiVersionData.v2Api.apiVersion
    if (apiVersion === null) {
      setApiVersion(newVersion)
    } else if (newVersion !== apiVersion) {
      const reload = () => {
        setApiVersion(newVersion)
        window.location.reload(true)
      }
      enqueueSnackbar(<p>A new version of reDock is available <AnchorButton style={{ marginLeft: 16, color: theme.palette.secondary.light }} onClick={reload}>REFRESH</AnchorButton></p>, { persist: true, action: () => null })
    }
  }, [apiVersionData, apiVersionLoading, apiVersionError, apiVersion, enqueueSnackbar, setApiVersion])

  useEffect(() => {
    if (!user) {
      // this is for the rare case when someone explicitly navigates to /logout (usually at the behest of our support) to force a logout
      // if that happens, then we don't want the user to be directed back to /logout after login
      if (history.location.pathname === '/logout') {
        history.push('/')
      }
    }
  }, [user, history])

  const updateMe = (newMe, newExternalTokens) => {
    if (newMe.clientsAllowed.map(c => c.code).indexOf(selectedClient) === -1) {
      setSelectedClient(newMe.client.code)
    }
    setMe(newMe)
    setZohoDeskToken(newExternalTokens.zohoDesk)
  }

  const onMeError = error => {
    setMe(null)
    setZohoDeskToken(null)
    setSelectedClient(null)
    setMountError(error)
  }

  useQuery(MeQuery, {
    skip: !user || mountError,
    fetchPolicy: 'network-only',
    // onCompleted can be called with data=undefined when skipped
    // See https://github.com/apollographql/react-apollo/issues/3943
    onCompleted: data => { data && updateMe(data.v2Api.me, data.v2Api.externalTokens) },
    onError: onMeError
  })

  const refreshMe = async () => {
    try {
      // #apollo3
      // It should be possible to do this using the refetch of the useQuery above but it's buggy at the
      // moment (most notably the onCompleted callback is not invoked when using refetch). We can revisit this once
      // we upgrade to apollo3.0
      if (!mountError) {
        const result = await client.query({
          query: MeQuery,
          fetchPolicy: 'network-only'
        })
        updateMe(result.data.v2Api.me, result.data.v2Api.externalTokens)
      }
    } catch (e) {
      onMeError(e)
    }
  }

  /**
   * Open Zoho Desk, see https://www.zoho.com/desk/developers/asap/.
   * @returns {Promise<void>}
   */
  const openZohoDesk = () => {
    window.ZohoHCAsapReady(() => {
      window.ZohoHCAsap.Action('open')
    })
  }

  useEffect(() => {
    async function handleZoho () {
      if (!zohoDeskAsapId) { return }

      const userLoggedIn = user && me
      if (userLoggedIn && !asapLogin && zohoDeskToken) {
        // noinspection JSIgnoredPromiseFromCall
        await initZohoDesk(zohoDeskAsapId, authService, zohoDeskToken)
        // Show Zoho Desk, see https://www.zoho.com/desk/developers/asap/.
        window.ZohoHCAsapReady(() => {
          window.ZohoHCAsap.Login({
            token: zohoDeskToken
          })
          setAsapLogin(true)
        })
      } else if (!userLoggedIn && asapLogin) {
        window.ZohoHCAsapReady(() => {
          setAsapLogin(false)
          window.ZohoHCAsap.Logout()
        })
      }
    }

    handleZoho()
  }, [authService, user, history, zohoDeskAsapId, me, asapLogin, zohoDeskToken])

  const login = async () => {
    try {
      await authService.login()

      setTimeout(async _ => {
        try {
          await client.mutate({ mutation: UserLoggedInMutation })
        } catch (err) {
          // TODO add error capture and reporting, but continue the logout process from the user's perspective by ignoring this error otherwise
          console.error('Error doing UserLoggedInMutation, ignoring', err)
        }
      }, 0)

      setSigningMessage(null)
      await refreshMe()
    } catch (err) {
      let signinMessage
      if (err instanceof LoginAccessDenied) {
        signinMessage = 'Access denied, was the login cancelled?'
      } else if (err instanceof LoginInProgress) {
        signinMessage = 'A login is already in progress, check if a login popup is open'
      } else {
        signinMessage = 'Login failed, please contact customer success'
      }
      if (user) {
        setMountError(err)
      } else {
        setSigningMessage(signinMessage)
      }
    }
  }

  const logout = async () => {
    // Do not put inside a setTimeout because it must occurs before the actual logout occurs
    // to work properly
    try {
      await client.mutate({ mutation: UserLoggedOutMutation })
    } catch (err) {
      // TODO add error capture and reporting, but continue the logout process from the user's perspective by ignoring this error otherwise
      console.error('Error doing UserLoggedOutMutation, ignoring', err)
    }

    setSelectedClient(null)
    setMe(null)
    setZohoDeskToken(null)
    authService.logout()
  }

  if (mountError) {
    return <TopLevelErrorException intl={intl} error={mountError} />
  }

  if (user && !me) {
    // we are logged in but haven't loaded our own information yet, hold off on rendering -- if we try to render
    // now, and the URL is `/admin`, it'll end up redirecting to / before the user information is loaded, since the
    // `/admin` route will not be available yet
    // do allow `/logout` though
    return (
      <>
        <Route path='/logout' render={(props) => user ? <Logout {...props} authService={authService} /> : null} />
        <FullPageCircularProgress />
      </>
    )
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale='en'
      messages={generatedMessages[locale]}
      defaultMessages={generatedMessages.en}

      // To support dynamically changing the locale of the site.
      // Not super relevant when using navigator.language but easy to forget the day we decide to implement a culture picker.
      // See https://github.com/yahoo/react-intl/issues/243
      key={locale}
    >
      <div className={classes.root}>
        <Modal
          open={!user}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div className={classes.signinModalPaper}>
            <Avatar className={classes.signinAvatar}>
              <LockIcon />
            </Avatar>
            <img src='/reDock-Indigo-logo-big.png' alt='reDock Logo' style={{ display: 'block', width: '70%', margin: '16px auto' }} />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.signinButton}
              onClick={login}
            >
              Sign in
            </Button>
            {signinMessage
              ? <Typography color='error' style={{ marginTop: 12, textAlign: 'center' }}>{signinMessage}</Typography>
              : null}
          </div>
        </Modal>
        <AppBar
          position='fixed'
          className={classnames(classes.appBar, {
            [classes.appBarShift]: drawerOpen
          })}
        >
          <Toolbar disableGutters={!drawerOpen} className={classes.toolbar}>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={() => setDrawerOpen(true)}
              className={classnames(classes.menuButton, {
                [classes.hide]: drawerOpen
              })}
            >
              <MenuIcon />
            </IconButton>
            <Link to='/' className={classes.title}>
              <Typography variant='h6' color='inherit' noWrap>
                reDock
              </Typography>
            </Link>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ClientChooser me={me} classes={classes} selectedClient={selectedClient} setSelectedClient={setSelectedClient} />
              <ProfileMenu onLogout={logout} me={me} />
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          variant='permanent'
          className={classnames(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
          })}
          classes={{
            paper: classnames({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen
            })
          }}
          open={drawerOpen}
        >
          <div className={classes.drawerToggleIcon}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              {hasPermission(Permissions.SEARCH, me) ? (
                <Link to='/'>
                  <ListItem button>
                    <ListItemIcon>
                      <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary='Search' />
                  </ListItem>
                </Link>
              ) : null}
              {hasPermission(Permissions.UPLOAD, me) ? (
                <Link to='/upload'>
                  <ListItem button>
                    <ListItemIcon>
                      <UploadMenuIcon selectedClient={selectedClient} />
                    </ListItemIcon>
                    <ListItemText primary='Upload' />
                  </ListItem>
                </Link>
              ) : null}
              {hasPermission(Permissions.VIEW_ADMIN_PAGE, me) ? (
                <Link to='/Admin'>
                  <ListItem button>
                    <ListItemIcon>
                      <SettingsApplicationIcon />
                    </ListItemIcon>
                    <ListItemText primary='Admin' />
                  </ListItem>
                </Link>
              ) : null}
              {asapLogin ? (
                <ListItem button onClick={openZohoDesk}>
                  <ListItemIcon>
                    <HelpIcon />
                  </ListItemIcon>
                  <ListItemText primary='Help' />
                </ListItem>
              ) : null}
            </div>
          </List>
        </Drawer>

        <main id='page-layout' className={classes.content}>
          {showIeWarning ? (
            <Paper className={classnames(classes.margin, classes.ieWarning)} elevation={0}>
              <Typography color='secondary'>
                <InfoIcon fontSize='large' style={{ verticalAlign: 'middle' }}>Info</InfoIcon>
                <>
                  You are using Internet Explorer. Only basic functionality is supported in IE.
                  We recommend using <a href='https://www.google.com/chrome/'>Chrome</a>, <a href='https://www.mozilla.org/en-US/firefox/new/'>Firefox</a>,
                  or <a href='https://www.microsoft.com/en-ca/windows/microsoft-edge'>Edge</a>.
                </>
              </Typography>
            </Paper>
          ) : null}
          {user && (
            <Switch>
              <PermissionRoute
                permission={Permissions.SEARCH}
                me={me}
                exact
                path='/'
                render={(props) => <Search {...props} authService={authService} me={me} selectedClient={selectedClient} />}
              />
              <PermissionRoute
                permission={Permissions.UPLOAD}
                me={me}
                path='/upload'
                render={(props) => <Upload {...props} authService={authService} me={me} selectedClient={selectedClient} constants={constants} />}
              />
              <PermissionRoute
                permission={Permissions.VIEW_ADMIN_PAGE}
                me={me}
                path='/admin'
                render={(props) => <Admin {...props} refreshMe={refreshMe} me={me} authService={authService} selectedClient={selectedClient} />}
              />
              <Route path='/logout' render={(props) => this.user() ? <Logout {...props} authService={authService} /> : null} />
              <Redirect to='/' />
            </Switch>
          )}
        </main>
      </div>
    </IntlProvider>
  )
}

const ApiVersionQuery = gql`
  query apiVersion {
    v2Api {
      apiVersion
    }
  }
`

const MeQuery = gql`
  query me {
    v2Api {
      me {
        client {
          code
          name
        }
        clientCodesAllowed
        clientsAllowed {
          code
          name
        }
        env
        email
        firstName
        lastName
        jobTitle
        roles
        uuid
        features {
          fileStatuses { enabled, value }
          manualFileDeletion { enabled, value }
          sourceContextFilter { enabled, value }
          userManagement { enabled, value }
        }
      }
      externalTokens {
        zohoDesk
      }
    }
  }
`

const UserLoggedInMutation = gql`
  mutation userLoggedIn {
    v2Api {
      userLoggedIn {
        id
      }
    }
  }
`

const UserLoggedOutMutation = gql`
  mutation userLoggedOut {
    v2Api {
      userLoggedOut {
        id
      }
    }
  }
`

export default withRouter(withStyles(styles)(withApollo(PageLayout)))
