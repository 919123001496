import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import trim from 'lodash/trim'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Chip from '@material-ui/core/Chip'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import DocumentIcon from 'icons/Document'
import DownloadIcon from '@material-ui/core/SvgIcon'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import OpenInNew from '@material-ui/icons/OpenInNew'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import { CSSTransition } from 'react-transition-group'

import { dynamicCssTransitionClassNames } from 'common/utilities/jss'
import AnchorButton from 'components/Common/AnchorButton'
import FileLink from 'components/Common/FileLink'
import FileTypeIcon from 'components/Common/FileTypeIcon'
import SourceTypeIcon from 'components/Common/SourceTypeIcon'
import { computeSegmentHtml, doCopy, getClips, joinClips } from 'services/redock/Segment'
import { reDockContent, reDockPrimary, reDockSecondary } from 'services/theme/reDockTheme'

const cardTitleIconSize = 24
const cardTitleIconSidePadding = 6
const cardTitleBaseHeight = 48
const cardTitleIconContainerWidth = cardTitleIconSize + cardTitleIconSidePadding
// Arbitrary value that is assumed to be big enough. The animation looks the smoothest if it`s equal to cardTitleIconContainerSize
// but that doesn't leave enough room for long paths as it results in only 2 lines.
const maxCardTitleContentHeight = cardTitleBaseHeight * 2.5

// IE and Edge do not support negative lookbehind regex so we cannot use (?<!<) which forces us
// to use a workaround like this one to ensure our itemsHighlighted contains proper html strings
const htmlPathReducer = (a, c) => {
  if (a.length && a[a.length - 1].endsWith('<')) { a[a.length - 1] += `/${c}` } else { a.push(c) }
  return a
}

const useStyles = makeStyles(theme => ({
  resultBody: {
    marginTop: theme.spacing(1)
  },
  card: {
    paddingBottom: theme.spacing(1)
  },
  cardNoScore: {
    width: reDockContent.pageWidth
  },
  cardHeader: {
    position: 'relative',
    paddingBottom: 4
  },
  cardActions: {},
  cardContent: {
    paddingTop: 8,
    paddingRight: 0,
    paddingLeft: 0
  },
  segmentContent: {
    width: reDockContent.pageWidth,
    flexGrow: 0,
    flexShrink: 0,
    paddingLeft: reDockContent.margins.default().left,
    paddingRight: reDockContent.margins.default().right,
    position: 'relative',
    '& img': {
      maxWidth: reDockContent.pageWidth - reDockContent.margins.default().left - reDockContent.margins.default().right
    },
    '& > div': {
      '&  > h1:first-child': {
        marginTop: '0 !important'
      },
      '&  > h2:first-child': {
        marginTop: '0 !important'
      },
      '&  > h3:first-child': {
        marginTop: '0 !important'
      },
      '&  > h4:first-child': {
        marginTop: '0 !important'
      },
      '&  > h5:first-child': {
        marginTop: '0 !important'
      },
      '&  > h6:first-child': {
        marginTop: '0 !important'
      }
    }
  },
  breadcrumbLink: {
    wordBreak: 'break-word',
    textAlign: 'left'
  },
  breadcrumbSplit: {
    color: 'gray',
    fontWeight: 'bold',
    padding: '0 3px'
  },
  breadcrumbTitle: {
    color: reDockPrimary.main,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    textAlign: 'left'
  },
  chip: {
    margin: theme.spacing(1)
  },
  avatar: {
    backgroundColor: reDockSecondary.dark
  },
  omittedcontent: {
    padding: 0,
    minHeight: 0
  },
  cardTitle: {
    display: 'flex;',
    width: '100%',
    minHeight: cardTitleBaseHeight,
    // 56 is the height of the CardHeader and the CardTitle is "centered" it but when we expand the CardTitle gets
    // bigger and without these margins it will "move up" a bit which is super irritating visually
    marginTop: (56 - cardTitleBaseHeight) / 2,
    marginBottom: (56 - cardTitleBaseHeight) / 2,
    '& span': {
      fontSize: theme.typography.subtitle2.fontSize,
      lineHeight: `${cardTitleBaseHeight / 2}px`
    }
  },
  cardTitleTop: {
    display: 'flex',
    alignItems: 'center',
    minHeight: cardTitleBaseHeight,
    transition: 'min-height 300ms'
  },
  cardTitleBottom: {
    display: 'flex',
    alignItems: 'center',
    minHeight: cardTitleBaseHeight,
    marginTop: -cardTitleBaseHeight,
    marginLeft: cardTitleIconContainerWidth,
    transition: 'max-height 300ms, min-height 300ms, margin-left 300ms, margin-top 300ms'
  },
  cardTitleTopIcon: {
    flexShrink: 0,
    flexGrow: 0,
    paddingRight: cardTitleIconSidePadding,
    lineHeight: '17px'
  },
  cardTitleTopContent: {
    flexGrow: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
    minHeight: cardTitleBaseHeight / 2,
    maxHeight: cardTitleBaseHeight / 2,
    alignSelf: 'flex-start',
    marginLeft: cardTitleIconContainerWidth,
    transition: 'margin-left 300ms, margin-right 300ms',
    display: 'flex',
    alignItems: 'center'
  },
  cardTitleBottomIcon: {
    flexShrink: 0,
    flexGrow: 0,
    paddingRight: cardTitleIconSidePadding,
    lineHeight: '17px'
  },
  cardTitleBottomContent: {
    flexGrow: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
    minHeight: cardTitleBaseHeight / 2,
    maxHeight: cardTitleBaseHeight / 2,
    alignSelf: 'flex-end',
    transition: 'max-height 300ms, min-height 300ms, margin-right 300ms',
    display: 'flex',
    alignItems: 'center'
  },
  cardTitleExpandButton: {
    flexShrink: 0,
    flexGrow: 0,
    height: 48,
    width: 48,
    transform: 'rotate(0deg)',
    transition: 'transform 300ms'
  },
  noPath: {
    alignSelf: 'center'
  },
  ellipsis: {
    opacity: 1,
    display: 'inline-block',
    width: 0,
    transition: 'opacity 300ms'
  },
  details: {
    opacity: 0,
    transition: 'opacity 300ms',
    wordBreak: 'break-word',
    visibility: 'hidden'
  },
  sourceTypeIconContainer: {
    float: 'left'
  },
  fileTypeIconContainer: {
    float: 'left'
  },
  pathAndBreadcrumbContainer: {
    float: 'left',
    width: `calc(100% - ${cardTitleIconContainerWidth * 2}px - 48px)` // Last 48px is for the expand button
  },
  'cardTitle-enter-active': {
    '& $ellipsis': {
      opacity: 0
    },
    '& $details': {
      opacity: 1,
      visibility: 'visible'
    },
    '& $cardTitleTop': {
      minHeight: cardTitleIconSize
    },
    '& $cardTitleBottom': {
      marginTop: 0,
      marginLeft: 0,
      minHeight: cardTitleIconSize
    },
    '& $cardTitleTopContent': {
      marginLeft: 0,
      marginRight: 0,
      minHeight: cardTitleIconSize,
      maxHeight: maxCardTitleContentHeight
    },
    '& $cardTitleBottomContent': {
      marginRight: 0,
      minHeight: cardTitleIconSize,
      maxHeight: maxCardTitleContentHeight
    },
    '& $cardTitleExpandButton': {
      transform: 'rotate(180deg)'
    }
  },
  // TODO use jss-plugin-extend to extend cardTitle-enter-active as they are the same
  'cardTitle-enter-done': {
    '& $ellipsis': {
      opacity: 0
    },
    '& $details': {
      opacity: 1,
      visibility: 'visible'
    },
    '& $cardTitleTop': {
      minHeight: cardTitleIconSize
    },
    '& $cardTitleBottom': {
      marginTop: 0,
      marginLeft: 0,
      minHeight: cardTitleIconSize
    },
    '& $cardTitleTopContent': {
      marginLeft: 0,
      marginRight: 0,
      minHeight: cardTitleIconSize,
      maxHeight: maxCardTitleContentHeight
    },
    '& $cardTitleBottomContent': {
      marginRight: 0,
      minHeight: cardTitleIconSize,
      maxHeight: maxCardTitleContentHeight
    },
    '& $cardTitleExpandButton': {
      transform: 'rotate(180deg)'
    }
  },
  score: {
    position: 'absolute',
    top: 4,
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    fontStyle: 'italic'
  },
  scoreExplanation: {
    whiteSpace: 'pre-wrap',
    paddingRight: theme.spacing(1)
  }
}))

const SearchResultCard = ({ hit, userCopiedSegments, onOpenContextView, client, onFileDownloaded, setSourceContext, showSimilarByUuid }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [clips, setClips] = useState(null)
  const [titleExpanded, setTitleExpanded] = useState(false)
  const [showScoreExplanations, setShowScoreExplanation] = useState(false)

  const handleMouseEnter = async () => {
    // For security reasons, the clip has to be available when the user clicks on the button. To avoid loading them
    // all the time for all visible results, we'll load on the mouse enter of the card. Nb: the setState with function call back didn't work
    loadClips(hit.id, hit.parent ? hit.parent.uuid : null)
  }

  const handleCopyWithHeading = async () => {
    const clip = joinClips(clips)
    const segments = hit.parent ? [{ hit, segment: hit.parent }] : []
    segments.push({ hit, segment: hit.segment })
    doCopy(clip, enqueueSnackbar, () => userCopiedSegments(segments, 'SEARCH_RESULTS'))
  }

  const handleOpenDocument = () => {
    if (onOpenContextView) { onOpenContextView(hit.id) }
  }

  const loadClips = async (uuid, parentUuid) => {
    if (clips == null) {
      const uuids = parentUuid ? [parentUuid, uuid] : [uuid]
      setClips(await getClips(client, uuids))
    }
  }

  const contentDateTimeDisplay = (classes, contentTimestamp) => {
    if (!contentTimestamp) return null

    const date = DateTime.fromMillis(contentTimestamp, { setZone: true })
    const age = date.diffNow('days').negate()
    const ageMonths = Math.round(age.as('months'))
    const displayDate = date.toLocaleString(DateTime.DATE_MED)

    const dateChip = (chipLabel) => (
      <Chip
        label={chipLabel}
        className={classes.chip}
        variant='outlined'
      />
    )

    const dateTooltip = (chip) => <Tooltip title={displayDate} placement='top'>{chip}</Tooltip>

    if (ageMonths > 12) {
      return dateChip(displayDate)
    } else if (ageMonths > 1) {
      return dateTooltip(dateChip(`${ageMonths} months`))
    } else {
      const days = Math.round(age.as('days'))
      return dateTooltip(dateChip(days === 0 ? 'Today' : `${days} day${days === 1 ? '' : 's'}`))
    }
  }

  const handleFileDownloaded = async (fileName, fileType, sourceDocUri, action) => {
    onFileDownloaded(
      fileName,
      fileType,
      'SEARCH_RESULTS',
      action,
      hit
    )
  }

  // #TOTEST
  // 1) Remove FileName at the end of the path
  // 2) When doing exact term matching for SourceContext filtering (i.e. quoted query), the highlighting is applied to
  //    all the components matching the query using a single <em> tag (e.g. <em>/this/is/a</em>/path). We need to wrap
  //    individual path components inside <em> instead in order for highlighting to be properly rendered on the cards
  //    <em>/this/is/a</em>/path ===> /<em>this</em>/<em>is</em>/<em>a</em>/path
  const processHighlightedPath = highlightedPath => {
    // First value intentionally skipped as it contains the full match which we don't need
    const [, emOpeningTag, remainingPath] = highlightedPath.match(/^(<em class=".*?">)?(\/.*?)$/)

    let wrapNext = !!emOpeningTag
    return trim(remainingPath, '/').split('/')
      .reduce(htmlPathReducer, [])
      .slice(0, -1) // Last item is the fileName, ignore it
      .map(item => {
        if (!wrapNext) { return item }

        if (item.match('</em>$')) {
          wrapNext = false
          return `${emOpeningTag}${item}`
        } else {
          return `${emOpeningTag}${item}</em>`
        }
      })
  }

  const buildPath = (hit, classes) => {
    // Last item is the fileName, ignore it
    const items = trim(hit.segment.path, '/').split('/').slice(0, -1)
    if (items.length === 0 || (items.length === 1 && items[0] === '')) {
      return (
        <span className={classnames(classes.breadcrumbSplit, classes.details)}> / </span>
      )
    }

    const itemsHighlighted = processHighlightedPath(hit.segment.pathHighlighted)

    // This represent the value to be copied inside the SourceContext Filter when a component
    // of the path is clicked by the user. It's built up with each path component
    // rendered
    let sourceContext = ''
    return (
      <>
        {itemsHighlighted.map((itemHighlighted, idx) => {
          sourceContext += `/${items[idx]}`
          const currentContext = `"${sourceContext}"`

          if (idx === 0) {
            return (
              <Fragment key={idx}>
                <AnchorButton onClick={() => setSourceContext(currentContext)}><span dangerouslySetInnerHTML={{ __html: itemHighlighted }} /></AnchorButton>
              </Fragment>
            )
          } else if (idx === 1) {
            return (
              <Fragment key={idx}>
                <span className={classes.breadcrumbSplit}>/</span>
                {itemsHighlighted.length > 1 ? <span className={classes.ellipsis}>...</span> : null}
                <AnchorButton className={classes.details} onClick={() => setSourceContext(currentContext)}><span dangerouslySetInnerHTML={{ __html: itemHighlighted }} /></AnchorButton>
              </Fragment>
            )
          } else {
            return (
              <Fragment key={idx}>
                <span className={classnames(classes.breadcrumbSplit, classes.details)}>/</span>
                <AnchorButton className={classes.details} onClick={() => setSourceContext(currentContext)}><span dangerouslySetInnerHTML={{ __html: itemHighlighted }} /></AnchorButton>
              </Fragment>
            )
          }
        })}
      </>
    )
  }

  const buildBreadcrumb = (hit, classes) => {
    const breadcrumb = []
    const titlesHighlighted = hit.breadcrumb.titlesHighlighted
    const fileName = hit.breadcrumb.fileName
    // Fall back to the highlights from the SourceContext if the Breadcrumb did not match anything
    const fileNameHighlighted = hit.breadcrumb.fileNameHighlighted.match(/<\/em>/)
      ? hit.breadcrumb.fileNameHighlighted
      : trim(hit.segment.pathHighlighted, '/').split(/\//).reduce(htmlPathReducer, []).pop()

    // Build breadcrumb
    if (hit.breadcrumb.sourceDocUri && fileName) {
      // TODO deal with https://github.com/facebook/create-react-app/issues/4141
      // https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
      // https://github.com/mui-org/material-ui/issues/13861
      breadcrumb.push(
        <FileLink
          key='doclink'
          className={classes.breadcrumbLink}
          sourceDocUri={hit.breadcrumb.sourceDocUri}
          fileName={fileName}
          fileNameHighlighted={fileNameHighlighted}
          fileType={hit.segment.fileType}
          onFileDownloaded={handleFileDownloaded}
        />
      )
      breadcrumb.push(<span key='split0' className={classes.breadcrumbSplit}> / </span>)
    }

    if (titlesHighlighted.length > 1 || hit.segment.title) {
      breadcrumb.push(<span key='ellipsis' className={classes.ellipsis}>...</span>)
    }

    let idx = 1
    if (titlesHighlighted.length > 1) {
      titlesHighlighted.slice(0, titlesHighlighted.length - 1).forEach(title => {
        breadcrumb.push(<span key={`breadcrumb${idx}`} className={classes.details} dangerouslySetInnerHTML={{ __html: title }} />)
        breadcrumb.push(<span key={`split${idx++}`} className={classnames(classes.breadcrumbSplit, classes.details)}> / </span>)
      })
    }
    if (hit.segment.title) breadcrumb.push(<span key='title' className={classnames(classes.breadcrumbTitle, classes.details)}>{hit.segment.title}</span>)
    else breadcrumb.pop() // Pop the trailing "/"

    return breadcrumb
  }

  const buildContent = hit => {
    let parentContent = null
    let content

    if (hit.type === 'DOC') {
      content = <Typography>Document not shown, download it</Typography>
    } else {
      const html = computeSegmentHtml(hit.segment)
      const parentHtml = hit.parent ? computeSegmentHtml(hit.parent) : null
      if (html === '') {
        console.log('Content without html or plain:', hit)
      }
      parentContent = parentHtml ? <div dangerouslySetInnerHTML={{ __html: parentHtml }} /> : null
      content = <div dangerouslySetInnerHTML={{ __html: html }} />
    }

    return { content, parentContent }
  }

  const buildSubheader = hit => {
    const subheaderSimilar = []
    if (hit.similarCount) {
      const resultText = hit.similarCount === 1 ? 'result' : 'results'
      const referralText = hit.similarCount === 1 ? 'it' : 'them'
      subheaderSimilar.push(<Fragment key='similarIntro'>{` (${hit.similarCount} similar ${resultText} ${hit.similarShown ? 'shown' : 'hidden'}, `}</Fragment>)
      subheaderSimilar.push(<AnchorButton key='similarButton' onClick={() => showSimilarByUuid(hit.segment.uuid, !hit.similarShown)}>{hit.similarShown ? 'hide' : 'show'} {referralText}</AnchorButton>)
      subheaderSimilar.push(<Fragment key='similarEnd'>)</Fragment>)
    } else if (hit.similarShown && hit.similarToUuid) {
      subheaderSimilar.push(<Fragment key='similarSecondaryIntro'> (similar to better result above)</Fragment>)
    }

    return (
      <>
        {subheaderSimilar}
      </>
    )
  }

  const buildTitle = (hit, classes) => {
    const breadcrumb = buildBreadcrumb(hit, classes)
    const path = buildPath(hit, classes)
    const pathHasValue = hit.segment.path && hit.segment.path.split('/').length > 2 // ignore null, empty, '/' or '/filename'

    return (
      <>
        {hit.scoreExplanation ? <AnchorButton onClick={() => setShowScoreExplanation(prev => !prev)} className={classes.score}>{hit.score}</AnchorButton> : null}
        <CSSTransition in={titleExpanded} timeout={300} classNames={{ ...dynamicCssTransitionClassNames(classes, 'cardTitle') }}>
          <div className={classes.cardTitle}>
            <div style={{ flexGrow: 1 }}>
              <div className={classes.cardTitleTop}>
                <div className={classes.cardTitleTopIcon}>
                  <SourceTypeIcon type={hit.segment.sourceType} size={cardTitleIconSize} />
                </div>
                <div className={classes.cardTitleTopContent}>
                  {path}
                </div>
              </div>
              <div className={classes.cardTitleBottom}>
                <div className={classes.cardTitleBottomIcon}>
                  {hit.type === 'DOC' ? <DocumentIcon /> : <FileTypeIcon type={hit.segment.fileType} size={cardTitleIconSize} />}
                </div>
                <div className={pathHasValue ? classes.cardTitleBottomContent : classnames(classes.cardTitleBottomContent, classes.noPath)}>
                  {breadcrumb}
                </div>
              </div>
            </div>
            <IconButton className={classes.cardTitleExpandButton} onClick={() => setTitleExpanded(prev => !prev)}><ExpandMoreIcon /></IconButton>
          </div>
        </CSSTransition>
      </>
    )
  }

  const { content, parentContent } = buildContent(hit)
  const title = buildTitle(hit, classes)
  const subheader = buildSubheader(hit)

  return (
    <Grid item xs={12}>
      <Card className={classnames(classes.card, { [`${classes.cardNoScore}`]: !showScoreExplanations })} onMouseEnter={handleMouseEnter}>
        <CardHeader
          className={classes.cardHeader}
          action={
            <CardActions className={classes.cardActions} disableSpacing>
              {contentDateTimeDisplay(classes, hit.segment.contentTimestamp)}
              {hit.type === 'DOC' ? (
                <Tooltip title='Download Document' placement='top'>
                  <IconButton aria-label='Download' onClick={() => enqueueSnackbar('TODO', { variant: 'warning', persist: true })}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title='Copy' placement='top'>
                  <div>
                    <IconButton aria-label='Copy' onClick={handleCopyWithHeading} disabled={clips === null}>
                      <CopyIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
              <Tooltip title='Context View' placement='top'>
                <IconButton aria-label='Context View' onClick={handleOpenDocument}>
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            </CardActions>
          }
          title={title}
          subheader={<>{subheader}</>}
        />
        <CardContent className={classes.cardContent}>
          <Box display='flex'>
            <div className={classes.segmentContent}>
              {parentContent}
              {parentContent && hit.segmentToParentEdge && hit.segmentToParentEdge.index > 0 ? (
                <Tooltip title='The original document has more content between the Heading and your current search result'>
                  <span style={{ display: 'inline-block', marginTop: 10, marginBottom: 10 }}> {/* This is to enable tooltip on a disabled button */}
                    <Button disabled variant='outlined' size='small' color='primary' className={classes.omittedcontent}>
                      <MoreHorizIcon />
                    </Button>
                  </span>
                </Tooltip>
              ) : null}
              {content}
            </div>
            {showScoreExplanations ? (
              <code className={classes.scoreExplanation}>
                {hit.scoreExplanation.replace('\r', '<br/>')}
              </code>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withApollo(withRouter(SearchResultCard))
