import Typography from '@material-ui/core/Typography'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TRANSFORMACTION_APPLY, TRANSFORMACTION_SUGGEST } from 'common/proto'
import AnchorButton from 'components/Common/AnchorButton'

const styles = theme => ({
  searchSummary: {
    color: '#808080',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  searchRunQuery: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
})

const SearchSummary = ({ result, location, classes, hits, showSimilar, setShowSimilar, executeSearch }) => {
  const inputQuery = new URLSearchParams(location.search).get('q') // Sample the last one, don't use the state one as it changes as input changes

  const searchSummary = []
  const hitCount = result && result.total
  if (hitCount > 0) {
    searchSummary.push(
      <Typography key='hitCount' className={classes.searchSummary}>{result.hits.length} of {hitCount} results
        ({(result.took / 1000).toFixed(2)} seconds)
      </Typography>
    )
  }
  if (result && result.hits.length > hits.length) {
    const hiddenCount = result.hits.length - hits.length
    const resultText = hiddenCount === 1 ? 'result' : 'results'
    const referralText = hiddenCount === 1 ? 'it' : 'them'
    if (showSimilar) {
      searchSummary.push(
        <Typography key='similarHitsShow' className={classes.searchSummary}>
          {hiddenCount} similar {resultText} shown, <AnchorButton onClick={() => { setShowSimilar(false) }}>hide {referralText}</AnchorButton>?
        </Typography>)
    } else {
      searchSummary.push(
        <Typography key='similarHitsHide' className={classes.searchSummary}>
          {hiddenCount} similar {resultText} hidden, <AnchorButton onClick={() => { setShowSimilar(true) }}>show {referralText}</AnchorButton>?
        </Typography>
      )
    }
  }

  if (result && result.runQuery && result.runQuery.queryText && inputQuery && result.runQuery.queryText.replace(/\s/g, '').toLowerCase() !== inputQuery.replace(/\s/g, '').toLowerCase()) {
    if (result.runQuery.transformAction === TRANSFORMACTION_APPLY) {
      searchSummary.push(
        <Typography key='searchInstead' className={classes.searchRunQuery}>
          Showing results for <strong>{result.runQuery.queryText}</strong>.<br />Search original <AnchorButton onClick={(event) => { executeSearch(inputQuery, 'SEARCHINSTEAD_ORIGINAL_CLICKED', 'IN_APP', document.referrer) }}>{inputQuery}</AnchorButton> instead?
        </Typography>
      )
    } else if (result.runQuery.transformAction === TRANSFORMACTION_SUGGEST) {
      searchSummary.push(
        <Typography key='searchInstead' className={classes.searchRunQuery}>
          Do you want to search <AnchorButton onClick={(event) => { executeSearch(result.runQuery.queryText, 'SEARCHINSTEAD_SUGGESTION_CLICKED', 'IN_APP', document.referrer) }}>{result.runQuery.queryText}</AnchorButton> instead?
        </Typography>
      )
    }
  } else if (result && result.total === 0 && inputQuery !== '') {
    const q = result.runQuery && result.runQuery.transformAction === TRANSFORMACTION_APPLY ? result.runQuery.queryText : inputQuery
    searchSummary.push(<Typography key='noResults' className={classes.searchRunQuery}>No results found for <strong>{q}</strong>.</Typography>)
  }

  return searchSummary
}

export default withStyles(styles)(SearchSummary)
