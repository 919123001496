import React from 'react'

export default React.createContext({
  files: [], // List of files uploaded during this session
  uploadedFilesPercent: 0, // Percentage of dropped files that are uploaded
  uploadingErrorsPercent: 0, // Percentage of dropped files that were rejected at upload time (duplicate, too large, unsupported types)
  completedFilesPercent: 0, // Percentage of dropped files that are completed (i.e. all steps done)
  processingErrorsPercent: 0, // Percentage of dropped files that failed to be processed (i.e. DLQs)
  addDroppedFiles: () => console.error('Your component must be a child of the FilesUploaderProvider Component in order to use this function()'),
  toggleShowDetails: () => console.error('Your component must be a child of the FilesUploaderProvider Component in order to use this function()')
})
