import { createMuiTheme } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'

// See https://redock.atlassian.net/wiki/spaces/RD/pages/55476250/Aspose.Words+HTML+to+Word+CSS+to+Word+Styles for conversions constants
const PIXELS_PER_INCH = 96

// Receives margins in inches and convert them to pixels
class Margins {
  constructor (top, right, bottom, left) {
    this.top = top * PIXELS_PER_INCH
    this.right = right * PIXELS_PER_INCH
    this.bottom = bottom * PIXELS_PER_INCH
    this.left = left * PIXELS_PER_INCH
  }
}

// Theme colors based on reDock branding
// https://material.io/color/#!/?view.left=0&view.right=1&secondary.color=2bb2dc&primary.color=0c264b

export const reDockPrimary = {
  light: '#3c4e77',
  main: '#0c264b',
  dark: '#000024',
  contrastText: '#fff'
}

export const reDockSecondary = {
  light: '#6fe4ff',
  main: '#2bb2dc',
  dark: '#0082aa',
  contrastText: '#fff'
}

// For some reason the 'contrastText' of our theme does not match the default one defined at
// https://material-ui.com/customization/default-theme/ and it doesn't look as good.
// This will "override" using the values from the default-theme as seen on the site
export const reDockError = {
  light: '#e57373',
  main: '#f44336',
  dark: '#d32f2f',
  contrastText: '#fff'
}

export const reDockContent = {
  // this is the width of a Word document with letter format, the most standard.  Better results when previewing any segments.
  pageWidth: 8.5 * PIXELS_PER_INCH,
  // Standard margins options offered by Word
  margins: {
    normal: new Margins(1, 1, 1, 1),
    narrow: new Margins(0.5, 0.5, 0.5, 0.5),
    moderate: new Margins(1, 0.75, 1, 0.75),
    wide: new Margins(1, 2, 1, 2),
    default () { return this.moderate }
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: reDockPrimary,
    secondary: reDockSecondary,
    error: reDockError,
    contrastThreshold: 10,
    type: 'light'
  },
  primaryHoverBackground: lighten(reDockSecondary.light, 0.75),
  primarySelectionBackground: lighten(reDockSecondary.light, 0.75),
  secondarySelectionBackground: lighten(reDockPrimary.light, 0.25),
  typography: {
    useNextVariants: true
  }
})
