import React, { useEffect, useRef, useState, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

const ClientFormDialog = ({ client, clientCodes, onSubmit, open, handleClose }) => {
  const editing = client !== null

  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [allowedEmailSuffixes, setAllowedEmailSuffixes] = useState(['@redock.com'])
  const [newSuffix, setNewSuffix] = useState('')

  useEffect(() => {
    setCode(client ? client.code : '')
    setName(client ? client.name : '')
    setAllowedEmailSuffixes(client ? client.allowedEmailSuffixes : ['@redock.com'])
  }, [client])

  const newSuffixRef = useRef()

  const emailSuffixRegex = /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const emailSuffixError = useMemo(() => !emailSuffixRegex.test(newSuffix), [newSuffix, emailSuffixRegex])

  const submit = (event) => {
    event.preventDefault()

    onSubmit({
      code,
      name,
      allowedEmailSuffixes
    })
  }

  const addAllowedSuffix = (event, value) => {
    event.preventDefault()
    setAllowedEmailSuffixes(prev => [...prev, value])
    setNewSuffix('')
    newSuffixRef.current.focus()
  }

  const removeAllowedSuffix = (event, idx) => {
    event.preventDefault()
    setAllowedEmailSuffixes(prev => {
      const newValue = [...prev]
      newValue.splice(idx, 1)
      return newValue
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{editing ? 'Edit Client' : 'Create Client'}</DialogTitle>
      <DialogContent>
        <form>
          <Grid container direction='column' spacing={0}>
            <Grid item xs>
              <TextField
                fullWidth
                required
                label='Name'
                variant='outlined'
                value={name}
                onChange={e => setName(e.target.value)}
                margin='normal'
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                fullWidth
                // This (wrongly) assumes only users with `*` clientsAllowed will manage clients and thus
                // the clientCodes (which is populated from `me.clientsAllowed`) will contain all possible
                // client codes. Note that the server-side code will check client codes correctly regardless
                // of the user so this code is only a potential minor inconvenience for future users that can
                // manage clients but are not allowed to see every clients
                error={!editing && clientCodes.indexOf(code) !== -1}
                disabled={editing}
                label='Code (lowercase, no space)'
                variant='outlined'
                value={code}
                onChange={e => setCode(e.target.value.toLowerCase().trim())}
                margin='normal'
              />
            </Grid>
            <Grid item xs>
              <Grid container direction='column' spacing={0}>
                <Grid item xs>
                  <label>
                    Allowed Email Suffixes:
                  </label>
                </Grid>
                {
                  allowedEmailSuffixes.map((suffix, idx) =>
                    <Grid key={idx} item xs>
                      <p>
                        {suffix}
                        <IconButton onClick={e => removeAllowedSuffix(e, idx)}><RemoveCircleIcon /></IconButton>
                      </p>
                    </Grid>
                  )
                }
                <Grid item xs>
                  <TextField
                    fullWidth
                    inputRef={newSuffixRef}
                    error={emailSuffixError && newSuffix !== ''}
                    label='Add Email Suffix'
                    value={newSuffix}
                    onChange={e => setNewSuffix(e.target.value)}
                    margin='normal'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' onClick={e => !emailSuffixError && addAllowedSuffix(e, newSuffix)} style={{ cursor: 'pointer' }}>
                          <AddCircleIcon />
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter' && !emailSuffixError) {
                        addAllowedSuffix(e, newSuffix)
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>Cancel</Button>
        <Button disabled={!name || !code || allowedEmailSuffixes.length === 0} type='submit' onClick={e => submit(e)}>{editing ? 'Update' : 'Create'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientFormDialog
