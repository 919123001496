/**
 * A UUIDv4 implementation. From https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript.
 */
const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line
    (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export { uuidv4 }
