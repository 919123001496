import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createGraphQlClient } from 'common/graphqlclient/GraphQlClient'
import { theme } from 'services/theme/reDockTheme'
import PageLayout from 'scenes/main/PageLayout'
import ConfirmationDialogProvider from 'components/Common/ConfirmationDialogProvider'
import FilesUploaderProvider from 'components/Common/FilesUploaderProvider'
import AuthorizationProvider from 'components/Common/AuthorizationProvider'

const styles = _ => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  snackbarCountdown: {
    position: 'absolute',
    top: '0',
    right: '0',
    margin: '10px 20px'
  },
  success: { backgroundColor: theme.palette.primary.main },
  info: { backgroundColor: theme.palette.secondary.main }
})

class App extends React.Component {
  constructor (props) {
    super(props)
    this.createClient()

    // add action to all snackbars
    this.notistackRef = React.createRef()
  }

  onClickDismiss = key => () => {
    this.notistackRef.current.closeSnackbar(key)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.authService !== prevProps.authService || this.props.urls !== prevProps.urls) {
      this.createClient()
    }
  }

  createClient = () => {
    const { authService, urls } = this.props
    this.client = createGraphQlClient(authService, urls.graphQl, urls.subscriptions)
  }

  render () {
    const { classes, authService, zohoDeskAsapId, constants } = this.props

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <ApolloProvider client={this.client}>
              <ApolloProviderHooks client={this.client}>
                <SnackbarProvider
                  ref={this.notistackRef}
                  maxSnack={3}
                  classes={{
                    variantSuccess: classes.success,
                    variantInfo: classes.info
                  }}
                  action={key => (
                    <IconButton color='inherit' aria-label='Close' onClick={this.onClickDismiss(key)}>
                      <CloseIcon />
                    </IconButton>
                  )}
                >
                  <ConfirmationDialogProvider>
                    <FilesUploaderProvider>
                      <AuthorizationProvider>
                        <PageLayout authService={authService} zohoDeskAsapId={zohoDeskAsapId} constants={constants} />
                      </AuthorizationProvider>
                    </FilesUploaderProvider>
                  </ConfirmationDialogProvider>
                </SnackbarProvider>
              </ApolloProviderHooks>
            </ApolloProvider>
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

export default withRouter(withStyles(styles)(App))
