import React from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedErrorCode, FormattedException } from 'common/graphqlclient/ErrorHandler'

export const TopLevelErrorCode = ({ code }) => (
  <div style={{ height: '100%', width: '100%', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
    <Typography variant='h5' align='center'><FormattedErrorCode code={code} /></Typography>
  </div>
)

export const TopLevelErrorException = ({ error }) => (
  <div style={{ height: '100%', width: '100%', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
    <Typography variant='h5' align='center'><FormattedException err={error} /></Typography>
  </div>
)

export class TopLevelErrorHandler extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true, error })
    // TODO log to Sentry
  }

  render () {
    return this.state.hasError
      ? <TopLevelErrorException error={this.state.error} />
      : this.props.children
  }
}
