import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useSnackbar } from 'notistack'
import { fileStatusesEnum } from 'common/config/Constants'
import FilesUploaderContext from 'contexts/Common/FilesUploaderContext'
import CommonGraphqlFragments from 'common/graphqlclient/Fragments'
import { escapeRegExp } from 'common/strings/Strings'

let filePos = 0
const pollingInterval = 3000 // ms

const FilesUploaderProvider = ({ children, selectedClient }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [uploadingPos, setUploadingPos] = useState(null)
  const [uuids, setUuids] = useState([])
  const [files, setFiles] = useState([])
  const [currentBatchOffset, setCurrentBatchOffset] = useState(0)
  const [completedFilesPercent, setCompletedFilesPercent] = useState(100)
  const [uploadedFilesPercent, setUploadedFilesPercent] = useState(100)
  const [uploadingErrorsPercent, setUploadingErrorsPercent] = useState(0)
  const [processingErrorsPercent, setProcessingErrorsPercent] = useState(0)
  const [uploadNotificationShown, setUploadNotificationShown] = useState(false)
  const [processingNotificationShown, setProcessingNotificationShown] = useState(false)

  // This mutation is responsible for uploading files. It's currently uploading them one by one
  // but we might want to consider batching them up at some point?
  const [uploadFile] = useMutation(UploadFileMutation, {
    onCompleted: data => {
      setFiles(prev => {
        const newFiles = [...prev]
        const file = newFiles.find(f => f.pos === uploadingPos)
        file.uploadResult = data.v2Api.uploadFile
        file.completed = data.v2Api.uploadFile.preExisting
        file.showDetails = !file.completed

        return newFiles
      })
    },
    onError: error => {
      setFiles(prev => {
        const newFiles = [...prev]
        const file = newFiles.find(f => f.pos === uploadingPos)
        file.completed = true
        file.showDetails = false
        file.error = error

        return newFiles
      })
    }
  })

  // Upload files one by one
  useEffect(() => {
    const toUpload = files.find(f => !f.uploadResult && !f.completed)
    if (toUpload && toUpload.pos !== uploadingPos) {
      const variables = {
        client: toUpload.client,
        file: toUpload.raw
      }

      // We only get relative path info e.g. if the user's computer has these absolute paths:
      //
      //   /foo/bar/1.docx
      //   /foo/bar/baz/2.docx
      //
      // on browsers in which directory drop is supported, we should "see" the following:
      //   1) drop file 1.docx   : [{ path: "/1.docx", name: "1.docx" }]
      //   2) drop directory baz : [{ path: "/baz/2.docx", name: "2.docx" }]
      //   3) drop directory bar : [{ path: "/bar/1.docx", name: "1.docx" }, { fullPath: "/bar/baz/2.docx", name: "2.docx" }]
      //   4) drop directory foo : [{ path: "/foo/bar/1.docx", name: "1.docx" }, { fullPath: "/foo/bar/baz/2.docx", name: "2.docx" }]
      //
      // see https://wicg.github.io/entries-api/#api-entry

      const relativePath = toUpload.raw.path
        ? toUpload.raw.path.replace(new RegExp(`/?${escapeRegExp(toUpload.raw.name)}$`, 'g'), '')
        : ''

      if (relativePath) {
        variables.relativePath = relativePath
      }

      // noinspection JSIgnoredPromiseFromCall
      uploadFile({
        variables
      })

      setUploadingPos(toUpload.pos)
    }
  }, [files, uploadingPos, uploadFile, selectedClient])

  // This query will fetch file statuses
  const { data: queryData, loading: queryLoading, error: queryError } = useQuery(FileStatusesQuery, {
    skip: uuids.length === 0,
    pollInterval: pollingInterval,
    fetchPolicy: 'no-cache',
    variables: { cmsUuids: uuids }
  })

  // Update the list of files (uuids) that we need to fetch the status of
  useEffect(() => {
    // We want to fetch any file that has been uploaded that is either not completed yet
    // OR it completed with a failure but the DLQ event has not been returned yet (this occurs because the
    // fileStatus and the DLQs are in different tables and updated in different processes so it's possible for
    // the failed fileStatus to exist before the DLQ event has been properly added to the DB)
    setUuids(files.filter(f => f.uploadResult && f.uploadResult.cmsId &&
      (!f.completed ||
        (
          (
            f.isReceived === 'FAILED' ||
            f.isAnalyzed === 'FAILED' ||
            f.isSegmented === 'FAILED'
          ) &&
          f.dlqEvents.length === 0
        )
      )).map(f => f.uploadResult.cmsId))
  }, [files])

  // Update the statuses of files based on the fileStatuses received from the query
  // Workaround the fact that onCompleted is only called for the initial query but not
  // in subsequent poll queries (see https://github.com/apollographql/react-apollo/issues/2177)
  useEffect(() => {
    if (queryLoading || !queryData) { return }
    if (queryError) {
      enqueueSnackbar('There was a problem retrieving the statuses of your files.', { variant: 'error', persist: true })
      return
    }

    setFiles(prev => {
      const newFiles = [...prev]
      queryData.v2Api.fileStatuses.forEach(s => {
        const file = newFiles.filter(f => f.uploadResult && f.uploadResult.cmsId === s.cmsUuid).pop()
        file.fileStatus = s
        file.completed = (s.isReceived !== 'NO' && s.isAnalyzed !== 'NO' && s.isSegmented !== 'NO') ||
          s.isReceived === 'FAILED' ||
          (s.isReceived === 'YES' && s.isAnalyzed === 'FAILED')
        if (file.completed) { file.showDetails = false }
      })
      return newFiles
    })
  }, [queryLoading, queryData, queryError, enqueueSnackbar])

  useEffect(() => {
    const filesBatch = files.slice(currentBatchOffset, files.length)
    const uploadPercent = filesBatch.length > 0 ? (filesBatch.filter(f => f.completed || f.uploadResult).length / filesBatch.length * 100) : 100
    const completedPercent = filesBatch.length > 0 ? (filesBatch.filter(f => f.completed).length / filesBatch.length * 100) : 100
    const duplicate = filesBatch.filter(f => f.uploadResult && f.uploadResult.preExisting).length
    const unsupportedType = filesBatch.filter(f => f.completed && !f.uploadResult && f.errors && f.errors.some(e => e.code === 'file-invalid-type')).length
    const tooLarge = filesBatch.filter(f => f.completed && !f.uploadResult && f.errors && f.errors.some(e => e.code === 'file-too-large') && !f.errors.some(e => e.code === 'file-invalid-type')).length
    const unknownErrors = filesBatch.filter(f => f.completed && !f.uploadResult && f.error).length
    const dlqs = filesBatch.filter(f => (f.fileStatus &&
      (f.fileStatus.isReceived === fileStatusesEnum.FAILED ||
        f.fileStatus.isAnalyzed === fileStatusesEnum.FAILED ||
        f.fileStatus.isSegmented === fileStatusesEnum.FAILED))).length

    setUploadedFilesPercent(uploadPercent)
    setUploadingErrorsPercent((duplicate + tooLarge + unknownErrors + unsupportedType) / filesBatch.length * 100)
    setCompletedFilesPercent(completedPercent)
    setProcessingErrorsPercent(dlqs / filesBatch.length * 100)

    if (!uploadNotificationShown && uploadPercent === 100 && filesBatch.length > 0) {
      if (duplicate + tooLarge + unknownErrors + unsupportedType) {
        enqueueSnackbar(
          <div>
            <span>Some of the files could not be uploaded:</span>
            <ul>
              {duplicate ? <li>{`Duplicate files: ${duplicate}`}</li> : null}
              {tooLarge ? <li>{`Files too large: ${tooLarge}`}</li> : null}
              {unknownErrors ? <li>{`Other errors: ${unknownErrors}`}</li> : null}
              {unsupportedType ? <li>{`Unsupported file types: ${unsupportedType}`}</li> : null}
            </ul>
          </div>, { persist: true, variant: 'error' })
      } else {
        enqueueSnackbar('Files uploaded successfully.', { variant: 'info' })
      }
      setUploadNotificationShown(true)
    }

    if (!processingNotificationShown && completedPercent === 100 && filesBatch.length > 0) {
      if (dlqs) {
        enqueueSnackbar(
          <div>
            <span>Some of the files failed to be processed:</span>
            <ul>
              {dlqs ? <li>{`Affected files: ${dlqs}`}</li> : null}
            </ul>
          </div>, { persist: true, variant: 'error' })
      } else if (dlqs + duplicate + tooLarge + unknownErrors + unsupportedType < filesBatch.length) {
        enqueueSnackbar('Uploaded files processed successfully.', { variant: 'success' })
      }
      setProcessingNotificationShown(true)
    }
  }, [files, currentBatchOffset, enqueueSnackbar, uploadNotificationShown, processingNotificationShown])

  const contextValue = {
    files: files,
    uploadedFilesPercent: uploadedFilesPercent,
    completedFilesPercent: completedFilesPercent,
    uploadingErrorsPercent: uploadingErrorsPercent,
    processingErrorsPercent: processingErrorsPercent,
    // Process the received droppedFiles and add them to the existing list of files
    // in the appropriate state. Accepted files will then be uploaded one by one.
    addDroppedFiles: droppedFiles => {
      if (droppedFiles === null) { return }

      if (files.every(f => f.completed)) {
        setCurrentBatchOffset(files.length)
        setUploadNotificationShown(false)
        setProcessingNotificationShown(false)
      }

      const { acceptedFiles, fileRejections, client } = droppedFiles

      if (fileRejections.length > 0) {
        // some odd drag-and-drop scenarios may result in an empty DataTransferObject with no file name, ignore these
        fileRejections.map(r => r.file).filter(f => !f.name).forEach(f => {
          if (f.kind === 'string' && f.type !== 'text/uri-list') {
            console.warn('Dropped text or HTML, this is not supported...', f)
          } else if (f.kind === 'string' && f.type === 'text/uri-list') {
            f.getAsString(c => {
              console.warn('Dropped URIs of unknown type, this is not supported...', c)
            })
          } else {
            f.getAsString(c => {
              console.warn('Dropped something, no name available, perhaps dropped from odd network drive or zip?', f.kind, f.type, c)
            })
          }
        })
      }

      setFiles(prev => {
        return [
          ...prev,
          ...(fileRejections.filter(r => r.file.name).map(r => ({
            raw: r.file,
            client: client,
            pos: filePos++,
            showDetails: false,
            errors: r.errors,
            completed: true
          }))),
          ...(acceptedFiles.map(f => ({
            raw: f,
            client: client,
            pos: filePos++,
            showDetails: true
          })))
        ]
      })
    },
    toggleShowDetails: pos => {
      setFiles(prev => {
        const newFiles = [...prev]
        const file = newFiles.find(f => f.pos === pos)
        file.showDetails = !file.showDetails
        return newFiles
      })
    }
  }

  return (
    <FilesUploaderContext.Provider value={contextValue}>
      {children}
    </FilesUploaderContext.Provider>
  )
}

const UploadFileMutation = gql`
  mutation uploadFileMutation($client: ID!, $relativePath: String, $file: Upload!) {
    v2Api {
      uploadFile(client: $client, relativePath: $relativePath, file: $file) {
        cmsId
        sha256
        name
        preExisting
      }
    }
  }`

const FileStatusesQuery = gql`
  query fileStatusesQuery($cmsUuids: [String!]!) {
    v2Api {
      fileStatuses(cmsUuids: $cmsUuids) {
        ...fileStatusFields
      }
    }
  }

  ${CommonGraphqlFragments.dlqEvent}
  ${CommonGraphqlFragments.fileStatus}
  ${CommonGraphqlFragments.event}
  ${CommonGraphqlFragments.eventContext}
`

export default FilesUploaderProvider
