import { useRef, useEffect } from 'react'

/**
 * Allows access to previous props and state in useEffect (similar to componentDidUpdate), see
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state and
 * https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/.
 * @param value
 * @returns The previous value associated with a given value.
 */
export function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
