import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withApollo } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Modal from '@material-ui/core/Modal'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PlayIcon from '@material-ui/icons/PlayCircleFilled'
import PauseIcon from '@material-ui/icons/PauseCircleFilled'

import AnchorButton from 'components/Common/AnchorButton'
import CommonGraphqlFragments from 'common/graphqlclient/Fragments'

import LogoGoogleDrive from 'icons/LogoGoogleDrive'
import LogoSharePointOnline from 'icons/LogoSharePointOnline'
import gql from 'graphql-tag'

const CONNECTOR_POLLING_INTERVAL = 5000

const styles = theme => ({
  card: {
    maxWidth: 620,
    margin: 'auto',
    marginBottom: theme.spacing(4)
  },
  cardHeader: {
    borderBottom: '1px solid lightgray'
  },
  cardContent: {
    paddingTop: 0,
    marginTop: 0
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  buttonDelete: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  headingCell: {
    width: 200
  },
  actions: {
    display: 'flex'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  playStopIcon: {
    width: 48,
    height: 48,
    margin: 'auto'
  },
  siteCountButton: {
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  siteModalPaper: {
    width: 1080,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    marginTop: '10%',
    margin: 'auto',
    maxHeight: 600,
    overflow: 'auto'
  },
  formHelperStandalone: {
    marginLeft: theme.spacing(2)
  }
})

class ConnectorManagement extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      connectors: null,
      busy: false,
      internalError: false,
      connectorForClient: this.props.selectedClient
    }
  }

  async componentDidMount () {
    await this.loadConnectors()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.selectedClient !== this.state.connectorForClient) {
      this.loadConnectors()
    }
  }

  loadConnectors = async () => {
    const { data } = await this.props.client.query({
      query: ConnectorsQuery,
      variables: {
        selectedClient: this.props.selectedClient
      },
      fetchPolicy: 'no-cache'
    })

    const connectors = data.v2Api.admin.connectors

    // If no existing connector has been found for a type, load the NOT_CONFIGURED instance for creation
    if (connectors.googleDriveConnectors.length === 0) {
      connectors.googleDriveConnectors.push({
        connector: {
          uuid: null,
          type: 'GOOGLE_DRIVE',
          status: 'NOT_CONFIGURED'
        }
      })
    }

    if (connectors.sharePointOnlineConnectors.length === 0) {
      connectors.sharePointOnlineConnectors.push({
        connector: {
          uuid: null,
          type: 'SHAREPOINT_ONLINE',
          status: 'NOT_CONFIGURED'
        },
        registeredEndpoints: [],
        sitePatternField: 'DISPLAY_NAME'
      })
    }

    this.setState({ connectors: connectors, connectorForClient: this.props.selectedClient })
  }

  render () {
    if (this.state.connectors === null) return null

    return (
      <div>
        {this.state.connectors.googleDriveConnectors.map(c => {
          return (<ConnectorGoogleDriveCard key={this.props.selectedClient + (c.connector.uuid || c.connector.type)} {...this.props} googleDriveConnector={c} />)
        })}
        {this.state.connectors.sharePointOnlineConnectors.map(c => {
          return (<ConnectorSharePointOnlineCard key={this.props.selectedClient + (c.connector.uuid || c.connector.type)} {...this.props} sharePointOnlineConnector={c} />)
        })}
      </div>
    )
  }
}

// TODO: extract common components with ConnectorSharePointOnlineCard
class ConnectorGoogleDriveCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      googleDriveConnector: null,
      internalError: null,
      busy: false,
      endpointModal: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.receiveGoogleDriveConnector(this.props.googleDriveConnector, true)
    // noinspection JSIgnoredPromiseFromCall
    this.pollConnector(0)
  }

  componentWillUnmount () {
    this._isMounted = false
    clearTimeout(this.pollHandle)
  }

  pollConnector = async (timeout) => {
    const connector = this.state.googleDriveConnector?.connector
    if (connector && !this.state.busy && this.state.internalError === null && connector.uuid &&
      (connector.status === 'UNREACHABLE' || connector.status === 'BUSY' || connector.status === 'RUNNING' || connector.status === 'DELETING')
    ) {
      await this.getGoogleDriveConnector()
    }

    // if getGoogleDriveConnector returns after the component unmounts, we don't want to schedule the poll again
    if (this._isMounted) {
      this.pollHandle = setTimeout(() => {
        this.pollConnector(CONNECTOR_POLLING_INTERVAL)
      }, timeout)
    }
  }

  createUpdateGoogleDriveConnector = async () => {
    this.setState({ busy: true })
    try {
      const googleDriveConnector = this.state.googleDriveConnector
      const connector = googleDriveConnector.connector
      const mutationResult = await this.props.client.mutate({
        mutation: CreateUpdateGoogleDriveConnectorMutation,
        variables: {
          input: {
            uuid: connector.uuid,
            selectedClient: this.props.selectedClient,
            credentialsString: googleDriveConnector.credentialsString,
            impersonateUser: googleDriveConnector.impersonateUser,
            initQuery: googleDriveConnector.initQuery
          }
        }
      })
      this.receiveGoogleDriveConnector(mutationResult.data.v2Api.createUpdateGoogleDriveConnector, true)
    } catch (e) {
      console.error(e)
      this.setState({ internalError: 'An error occurred creating connector. Please contact customer success.', busy: false })
    }
  }

  getGoogleDriveConnector = async () => {
    const googleDriveConnector = this.state.googleDriveConnector
    const connector = googleDriveConnector.connector
    try {
      const queryResult = await this.props.client.query({
        query: GoogleDriveConnectorQuery,
        variables: {
          uuid: connector.uuid
        },
        fetchPolicy: 'network-only'
      })
      this.receiveGoogleDriveConnector(queryResult.data.v2Api.admin.googleDriveConnector)
    } catch (e) {
      console.error(e)
      this.setState({ internalError: 'An error occurred getting connector information. Please contact customer success.' })
    }
  }

  startConnector = async () => {
    await this.mutateConnector(StartConnectorMutation, (result) => { return result.data.v2Api.startConnector })
  }

  pauseConnector = async () => {
    await this.mutateConnector(PauseConnectorMutation, (result) => { return result.data.v2Api.pauseConnector })
  }

  resumeConnector = async () => {
    await this.mutateConnector(ResumeConnectorMutation, (result) => { return result.data.v2Api.resumeConnector })
  }

  deleteConnector = async () => {
    if (window.confirm('This will remove the synchronization job and remove from reDock\'s index all documents what were uploaded via this connector. Are you sure you want to continue?')) {
      await this.mutateConnector(DeleteConnectorMutation, (result) => { return result.data.v2Api.deleteConnector })
      this.setState({ expanded: false })
    }
  }

  mutateConnector = async (mutation, resultHandler) => {
    this.setState({ busy: true })
    const googleDriveConnector = this.state.googleDriveConnector
    const connector = googleDriveConnector.connector

    try {
      const mutationResult = await this.props.client.mutate({
        mutation: mutation,
        variables: {
          uuid: connector.uuid
        }
      })
      this.receiveConnectorFragment(resultHandler(mutationResult))
    } catch (e) {
      this.setState({ internalError: 'An error occurred modifying the connector. Please contact customer success.', busy: false })
    }
  }

  receiveConnectorFragment = (connectorFragment) => {
    const googleDriveConnector = this.state.googleDriveConnector
    googleDriveConnector.connector = connectorFragment
    this.setState({ googleDriveConnector: googleDriveConnector, busy: false, internalError: null })
  }

  receiveGoogleDriveConnector = (googleDriveConnector, triggerExpansion = false) => {
    const connector = googleDriveConnector.connector

    this.setState({
      googleDriveConnector: googleDriveConnector,
      busy: false,
      internalError: null
    })

    if (triggerExpansion) this.setState({ expanded: connector.status === 'FORBIDDEN' || connector.status === 'NOT_CONFIGURED' || connector.status === 'ERROR' })
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  }

  handleInitQueryChange = (e) => {
    const googleDriveConnector = this.state.googleDriveConnector
    googleDriveConnector.initQuery = e.target.value
    this.setState({ googleDriveConnector: googleDriveConnector })
  }

  handleCredentialsStringChange = (e) => {
    const googleDriveConnector = this.state.googleDriveConnector
    googleDriveConnector.credentialsString = e.target.value
    this.setState({ googleDriveConnector: googleDriveConnector })
  }

  handleImpersonateUserChange = (e) => {
    const googleDriveConnector = this.state.googleDriveConnector
    googleDriveConnector.impersonateUser = e.target.value
    this.setState({ googleDriveConnector: googleDriveConnector })
  }

  render () {
    if (!this.state.googleDriveConnector) return null

    const { classes } = this.props
    const googleDriveConnector = this.state.googleDriveConnector
    const connector = googleDriveConnector.connector
    const connectorUiState = this.state.busy ? 'BUSY' : connector.status
    const initQuery = googleDriveConnector.initQuery || ''
    const credentialsString = googleDriveConnector.credentialsString || ''
    const impersonateUser = googleDriveConnector.impersonateUser || ''

    let credentialsMessage
    if (connector.status === 'NOT_CONFIGURED') {
      credentialsMessage = <>{initQuery || 'Set your service account JSON credentials below.'}<br /></>
    } else {
      credentialsMessage = initQuery
    }

    const progressMessage = connector.docsTotal ? ': ' + connector.docsSynced + ' / ' + connector.docsTotal + ' sites + folders + files' : ''

    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<LogoGoogleDrive style={{ fontSize: '48px' }} />}
          title='Google Drive Connector'
          subheader={
            <>
              {credentialsMessage}
              <br />
              {this.state.internalError ? <Typography color='error'>{this.state.internalError}</Typography> : {
                NEW: <>Click the <PlayIcon className={classes.playStopIcon} color='secondary' style={{ width: 14, height: 14 }} /> button to start syncing</>,
                RUNNING: connector.docsTotal ? (connector.docsSynced === connector.docsTotal ? 'Active' : 'Syncing now') + progressMessage : 'Starting process...',
                UNREACHABLE: <Typography color='error'>Connector service is unreachable.</Typography>,
                BUSY: 'Working' + progressMessage,
                ERROR: <Typography color='error'>An error occurred, please check your configuration and {connector.status === 'NOT_CONFIGURED' ? 'Create' : 'Update'} again. If this doesn't work, contact customer success.</Typography>,
                IDLE: 'Idle' + progressMessage,
                PAUSED: 'Paused' + progressMessage,
                DELETING: 'Deleting...'
              }[connectorUiState]}
            </>
          }
          action={{
            NEW: <IconButton onClick={() => this.startConnector()}><PlayIcon className={classes.playStopIcon} color='secondary' /></IconButton>,
            RUNNING: <IconButton onClick={() => this.pauseConnector()}><PauseIcon className={classes.playStopIcon} color='secondary' /></IconButton>,
            BUSY: <CircularProgress className={classes.playStopIcon} color='secondary' />,
            DELETING: <CircularProgress className={classes.playStopIcon} color='secondary' />,
            IDLE: <IconButton onClick={() => this.startConnector()}><PlayIcon className={classes.playStopIcon} color='secondary' /></IconButton>,
            PAUSED: <IconButton onClick={() => this.resumeConnector()}><PlayIcon className={classes.playStopIcon} color='secondary' /></IconButton>
          }[connectorUiState]}
        />
        <CardActions className={classes.actions} onClick={this.handleExpandClick}>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: this.state.expanded
            })}
            aria-expanded={this.state.expanded}
            aria-label='Show more'
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
          <CardContent className={classes.cardContent}>
            <TextField
              id='gdrive-credentialsString'
              label='Credentials String (JSON)'
              className={classNames(classes.margin, classes.textField)}
              error={this.state.clientError}
              variant='outlined'
              value={credentialsString || ''}
              onChange={this.handleCredentialsStringChange}
              helperText='The credentials String (JSON) are the service account credentials used to access Google Drive, in JSON format, provided by the Google API Console.'
              margin='normal'
            />
            <TextField
              id='gdrive-initQuery'
              label='Initial Query (Optional)'
              className={classNames(classes.margin, classes.textField)}
              error={this.state.clientError}
              variant='outlined'
              value={initQuery || ''}
              onChange={this.handleInitQueryChange}
              helperText='Enter your the Google Drive API query for initial folders, or leave blank to index all accessible data'
              margin='normal'
            />
            <TextField
              id='gdrive-impersonateUser'
              label='As User (Optional)'
              className={classNames(classes.margin, classes.textField)}
              error={this.state.clientError}
              variant='outlined'
              value={impersonateUser || ''}
              onChange={this.handleImpersonateUserChange}
              helperText='To have the connector inherit the security permissions of a particular a user, or leave blank to use the service account user directly.'
              margin='normal'
            />
            <Button variant='contained' color='secondary' disabled={connector.status === 'BUSY'} className={classes.button} onClick={() => this.createUpdateGoogleDriveConnector()}>{connector.status === 'NOT_CONFIGURED' ? 'Create' : 'Update'}</Button>
            {connector.status !== 'NOT_CONFIGURED' && connector.status !== 'BUSY' &&
              <Button variant='contained' className={classNames(classes.button, classes.buttonDelete)} onClick={() => this.deleteConnector()}>Delete</Button>}
          </CardContent>
        </Collapse>
      </Card>
    )
  }
}

ConnectorGoogleDriveCard.propTypes = {
  classes: PropTypes.object.isRequired
}

class ConnectorSharePointOnlineCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      sharePointOnlineConnector: null,
      internalError: null,
      busy: false,
      endpointModal: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.receiveSharePointOnlineConnector(this.props.sharePointOnlineConnector, true)

    const params = new URLSearchParams(this.props.location.search)

    if (params.get('error') === 'access_denied') {
      this.setState({ internalError: 'Could not grant reDock access to ' + this.state.sharePointOnlineConnector.tenantName + '. Check your configuration and try again.' })
    } else if (params.get('admin_consent') === 'True') {
      // We just received the consent. Wait 5s before apply the connector again
      this.setState({ busy: true })
      setTimeout(() => {
        this.createUpdateSharePointOnlineConnector()
      }, 5000)
    }

    // noinspection JSIgnoredPromiseFromCall
    this.pollConnector(0)
  }

  componentWillUnmount () {
    this._isMounted = false
    clearTimeout(this.pollHandle)
  }

  pollConnector = async (timeout) => {
    const connector = this.state.sharePointOnlineConnector?.connector
    if (connector && !this.state.busy && this.state.internalError === null && connector.uuid &&
      (connector.status === 'UNREACHABLE' || connector.status === 'BUSY' || connector.status === 'RUNNING' || connector.status === 'DELETING')
    ) {
      await this.getSharePointOnlineConnector()
    }
    // if getSharePointOnlineConnector returns after the component unmounts, we don't want to schedule the poll again
    if (this._isMounted) {
      this.pollHandle = setTimeout(() => {
        this.pollConnector(CONNECTOR_POLLING_INTERVAL)
      }, timeout)
    }
  }

  createUpdateSharePointOnlineConnector = async () => {
    this.setState({ busy: true })
    try {
      const sharePointOnlineConnector = this.state.sharePointOnlineConnector
      const connector = sharePointOnlineConnector.connector
      const mutationResult = await this.props.client.mutate({
        mutation: CreateUpdateSharePointOnlineConnectorMutation,
        variables: {
          input: {
            uuid: connector.uuid,
            selectedClient: this.props.selectedClient,
            tenantName: sharePointOnlineConnector.tenantName,
            sitePattern: sharePointOnlineConnector.sitePattern,
            sitePatternField: sharePointOnlineConnector.sitePatternField
          }
        }
      })
      this.receiveSharePointOnlineConnector(mutationResult.data.v2Api.createUpdateSharePointOnlineConnector, true)
    } catch (e) {
      console.error(e)
      this.setState({ internalError: 'An error occurred creating connector. Please contact customer success.', busy: false })
    }
  }

  getSharePointOnlineConnector = async () => {
    const sharePointOnlineConnector = this.state.sharePointOnlineConnector
    const connector = sharePointOnlineConnector.connector
    try {
      const queryResult = await this.props.client.query({
        query: SharePointOnlineConnectorQuery,
        variables: {
          uuid: connector.uuid
        },
        fetchPolicy: 'network-only'
      })
      this.receiveSharePointOnlineConnector(queryResult.data.v2Api.admin.sharePointOnlineConnector)
    } catch (e) {
      console.error(e)
      this.setState({ internalError: 'An error occurred getting connector information. Please contact customer success.' })
    }
  }

  startConnector = async () => {
    await this.mutateConnector(StartConnectorMutation, (result) => { return result.data.v2Api.startConnector })
  }

  pauseConnector = async () => {
    await this.mutateConnector(PauseConnectorMutation, (result) => { return result.data.v2Api.pauseConnector })
  }

  resumeConnector = async () => {
    await this.mutateConnector(ResumeConnectorMutation, (result) => { return result.data.v2Api.resumeConnector })
  }

  deleteConnector = async () => {
    if (window.confirm('This will remove the synchronization job and remove from reDock\'s index all documents what were uploaded via this connector. Are you sure you want to continue?')) {
      await this.mutateConnector(DeleteConnectorMutation, (result) => { return result.data.v2Api.deleteConnector })
      this.setState({ expanded: false })
    }
  }

  mutateConnector = async (mutation, resultHandler) => {
    this.setState({ busy: true })
    const sharePointOnlineConnector = this.state.sharePointOnlineConnector
    const connector = sharePointOnlineConnector.connector

    try {
      const mutationResult = await this.props.client.mutate({
        mutation: mutation,
        variables: {
          uuid: connector.uuid
        }
      })
      this.receiveConnectorFragment(resultHandler(mutationResult))
    } catch (e) {
      this.setState({ internalError: 'An error occurred modifying the connector. Please contact customer success.', busy: false })
    }
  }

  receiveConnectorFragment = (connectorFragment) => {
    const sharePointOnlineConnector = this.state.sharePointOnlineConnector
    sharePointOnlineConnector.connector = connectorFragment
    this.setState({ sharePointOnlineConnector: sharePointOnlineConnector, busy: false, internalError: null })
  }

  receiveSharePointOnlineConnector = (sharePointOnlineConnector, triggerExpansion = false) => {
    const connector = sharePointOnlineConnector.connector

    // Prompt for consent
    if (connector.status === 'FORBIDDEN' && new URLSearchParams(this.props.location.search).get('admin_consent') !== 'True') {
      const clientId = sharePointOnlineConnector.clientId
      const tenantName = sharePointOnlineConnector.tenantName
      const url = `https://login.microsoftonline.com/${tenantName}/adminconsent?client_id=${clientId}&redirect_uri=${window.location.href}`
      window.open(url, '_self')
      return
    }

    this.setState({
      sharePointOnlineConnector: sharePointOnlineConnector,
      busy: false,
      internalError: null
    })

    if (triggerExpansion) this.setState({ expanded: connector.status === 'FORBIDDEN' || connector.status === 'NOT_CONFIGURED' || connector.status === 'ERROR' })
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }))
  }

  handleTenantChange = (e) => {
    const sharePointOnlineConnector = this.state.sharePointOnlineConnector
    sharePointOnlineConnector.tenantName = e.target.value
    this.setState({ sharePointOnlineConnector: sharePointOnlineConnector })
  }

  handleSitePatternChange = (e) => {
    const sharePointOnlineConnector = this.state.sharePointOnlineConnector
    sharePointOnlineConnector.sitePattern = e.target.value
    this.setState({ sharePointOnlineConnector: sharePointOnlineConnector })
  }

  handleSitePatternFieldChange = (e) => {
    const sharePointOnlineConnector = this.state.sharePointOnlineConnector
    sharePointOnlineConnector.sitePatternField = e.target.value
    this.setState({ sharePointOnlineConnector: sharePointOnlineConnector })
  }

  render () {
    if (!this.state.sharePointOnlineConnector) return <CircularProgress />

    const { classes } = this.props
    const sharePointOnlineConnector = this.state.sharePointOnlineConnector
    const connector = sharePointOnlineConnector.connector
    const connectorUiState = this.state.busy ? 'BUSY' : connector.status
    const tenant = sharePointOnlineConnector.tenantName || ''
    const sitePattern = sharePointOnlineConnector.sitePattern || ''
    const sitePatternField = sharePointOnlineConnector.sitePatternField || ''
    const registeredEndpoints = sharePointOnlineConnector.registeredEndpoints || []

    let tenantMessage
    if (connector.status === 'NOT_CONFIGURED') {
      tenantMessage = <>{tenant || 'Set your organization tenant name below.'}<br />Your consent to grant reDock Files. Read access may be requested.</>
    } else {
      tenantMessage = tenant
    }

    const progressMessage = connector.docsTotal ? ': ' + connector.docsSynced + ' / ' + connector.docsTotal + ' sites + folders + files' : ''

    let siteHelper = null
    if (tenant && registeredEndpoints.length > 0) {
      siteHelper = (
        <AnchorButton className={classes.siteCountButton} onClick={() => { this.setState({ endpointModal: true }) }}>
          {registeredEndpoints.length} sites
        </AnchorButton>
      )
    }

    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<LogoSharePointOnline style={{ fontSize: '48px' }} />}
          title='Sharepoint Online Connector'
          subheader={
            <>
              {tenantMessage} {siteHelper}
              <br />
              {this.state.internalError ? <Typography color='error'>{this.state.internalError}</Typography> : {
                NEW: <>Click the <PlayIcon className={classes.playStopIcon} color='secondary' style={{ width: 14, height: 14 }} /> button to start syncing</>,
                RUNNING: connector.docsTotal ? (connector.docsSynced === connector.docsTotal ? 'Active' : 'Syncing now') + progressMessage : 'Starting process...',
                UNREACHABLE: <Typography color='error'>Connector service is unreachable.</Typography>,
                BUSY: 'Working' + progressMessage,
                ERROR: <Typography color='error'>An error occurred, please check your configuration and {connector.status === 'NOT_CONFIGURED' ? 'Create' : 'Update'} again. If this doesn't work, contact customer success.</Typography>,
                IDLE: 'Idle' + progressMessage,
                PAUSED: 'Paused' + progressMessage,
                DELETING: 'Deleting...'
              }[connectorUiState]}
            </>
          }
          action={
            {
              NEW: <IconButton onClick={() => this.startConnector()}><PlayIcon className={classes.playStopIcon} color='secondary' /></IconButton>,
              RUNNING: <IconButton onClick={() => this.pauseConnector()}><PauseIcon className={classes.playStopIcon} color='secondary' /></IconButton>,
              BUSY: <CircularProgress className={classes.playStopIcon} color='secondary' />,
              DELETING: <CircularProgress className={classes.playStopIcon} color='secondary' />,
              IDLE: <IconButton onClick={() => this.startConnector()}><PlayIcon className={classes.playStopIcon} color='secondary' /></IconButton>,
              PAUSED: <IconButton onClick={() => this.resumeConnector()}><PlayIcon className={classes.playStopIcon} color='secondary' /></IconButton>
            }[connectorUiState]
          }
        />
        <CardActions className={classes.actions} onClick={this.handleExpandClick}>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: this.state.expanded
            })}
            aria-expanded={this.state.expanded}
            aria-label='Show more'
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
          <CardContent className={classes.cardContent}>
            <TextField
              id='office365-tenant'
              label='Tenant Name'
              className={classNames(classes.margin, classes.textField)}
              error={this.state.clientError}
              variant='outlined'
              value={tenant || ''}
              onChange={this.handleTenantChange}
              helperText={'Enter your Office 365 tenant name\n(eg: contoso.onmicrosoft.com)'}
              margin='normal'
            />
            <TextField
              id='office365-sites'
              label='Site Expression'
              className={classNames(classes.margin, classes.textField)}
              error={this.state.clientError}
              variant='outlined'
              value={sitePattern || ''}
              onChange={this.handleSitePatternChange}
              helperText={'Use "All" to list all sites, "Your Site Name" to select a single site, or use a regular expression to match sites by pattern, such as "(Site 1|Site 2)" to match both "Site 1" and "Site 2", or "Client.*" to list all sites starting by "Client".'}
              margin='normal'
            />
            <RadioGroup
              id='office365-site-pattern-field'
              label='Field'
              className={classNames(classes.margin, classes.radioGroup)}
              error={this.state.clientError}
              value={sitePatternField}
              onChange={this.handleSitePatternFieldChange}
              margin='normal'
              row
            >
              <FormControlLabel value='DISPLAY_NAME' control={<Radio />} label='Display Name' />
              <FormControlLabel value='WEB_URL' control={<Radio />} label='Web URL' />
            </RadioGroup>
            <FormHelperText className={classes.formHelperStandalone}>You can apply the site expression to the Display Name of the SharePoint site or to the WebURL. \
              For instance, /sites/proposal/.* with Web URL would match all sites and sub-sites under the Proposal root site.
            </FormHelperText>
            <Button
              variant='contained' color='secondary' disabled={connector.status === 'BUSY'} className={classes.button}
              onClick={() => this.createUpdateSharePointOnlineConnector()}
            >{connector.status === 'NOT_CONFIGURED' ? 'Create' : 'Update'}
            </Button>
            {connector.status !== 'NOT_CONFIGURED' && connector.status !== 'BUSY' &&
              <Button variant='contained' className={classNames(classes.button, classes.buttonDelete)} onClick={() => this.deleteConnector()}>Delete</Button>}
          </CardContent>
        </Collapse>
        {registeredEndpoints &&
          <Modal
            open={this.state.endpointModal}
            onClose={() => { this.setState({ endpointModal: false }) }}
          >
            <div className={classes.siteModalPaper}>
              <b>Registered sites:</b>
              <Table>
                <TableRow>
                  <TableCell>Display Name</TableCell>
                  <TableCell>Web URL</TableCell>
                </TableRow>
                {registeredEndpoints.map((endpoint) => {
                  const row = endpoint.split(' -- ')
                  return (
                    <TableRow key={endpoint}>
                      <TableCell>{row[0]}</TableCell>
                      <TableCell>{row[1]}</TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
          </Modal>}
      </Card>
    )
  }
}

ConnectorSharePointOnlineCard.propTypes = {
  classes: PropTypes.object.isRequired
}

// ### QUERIES ###

const ConnectorsQuery = gql`
  query connectors($selectedClient: String!) {
    v2Api {
      admin {
        connectors(selectedClient: $selectedClient) {
          googleDriveConnectors {
            ...googleDriveConnectorFields
          }
          sharePointOnlineConnectors {
            ...sharePointOnlineConnectorFields
          }
        }
      }
    }
  }
  ${CommonGraphqlFragments.connector}
  ${CommonGraphqlFragments.googleDriveConnector}
  ${CommonGraphqlFragments.sharePointOnlineConnector}
`

const GoogleDriveConnectorQuery = gql`
  query googleDriveConnector($uuid: ID!) {
    v2Api {
      admin {
        googleDriveConnector(uuid: $uuid) {
            ...googleDriveConnectorFields
        }
      }
    }
  }
  ${CommonGraphqlFragments.connector}
  ${CommonGraphqlFragments.googleDriveConnector}
`

const SharePointOnlineConnectorQuery = gql`
  query sharePointOnlineConnector($uuid: ID!) {
    v2Api {
      admin {
        sharePointOnlineConnector(uuid: $uuid) {
          ...sharePointOnlineConnectorFields
        }
      }
    }
  }
  ${CommonGraphqlFragments.connector}
  ${CommonGraphqlFragments.sharePointOnlineConnector}
`

// ### MUTATIONS ###

const StartConnectorMutation = gql`
  mutation startConnectorMutation($uuid: ID!) {
    v2Api {
      startConnector(uuid: $uuid) {
        ...connectorFields
      }
    }
  }
  ${CommonGraphqlFragments.connector}
`

const PauseConnectorMutation = gql`
  mutation pauseConnectorMutation($uuid: ID!) {
    v2Api {
      pauseConnector(uuid: $uuid) {
        ...connectorFields
      }
    }
  }
  ${CommonGraphqlFragments.connector}
`

const ResumeConnectorMutation = gql`
  mutation resumeConnectorMutation($uuid: ID!) {
    v2Api {
      resumeConnector(uuid: $uuid) {
        ...connectorFields
      }
    }
  }
  ${CommonGraphqlFragments.connector}
`

const DeleteConnectorMutation = gql`
  mutation deleteConnectorMutation($uuid: ID!) {
    v2Api {
      deleteConnector(uuid: $uuid) {
        ...connectorFields
      }
    }
  }
  ${CommonGraphqlFragments.connector}
`

const CreateUpdateGoogleDriveConnectorMutation = gql`
  mutation createUpdateGoogleDriveConnector($input: GoogleDriveInput!) {
    v2Api {
      createUpdateGoogleDriveConnector(input: $input) {
        ...googleDriveConnectorFields
      }
    }
  }
  ${CommonGraphqlFragments.connector}
  ${CommonGraphqlFragments.googleDriveConnector}
`

const CreateUpdateSharePointOnlineConnectorMutation = gql`
  mutation createUpdateSharePointOnlineConnector($input: SharePointOnlineInput!) {
    v2Api {
      createUpdateSharePointOnlineConnector(input: $input) {
        ...sharePointOnlineConnectorFields
      }
    }
  }
  ${CommonGraphqlFragments.connector}
  ${CommonGraphqlFragments.sharePointOnlineConnector}
`

export default withStyles(styles)(withApollo(withSnackbar(ConnectorManagement)))
