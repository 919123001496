import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import useAuthorization from 'hooks/Common/useAuthorization'

const PermissionRoute = ({ render, permission, me, ...routeProps }) => {
  const { hasPermission } = useAuthorization()
  return (
    <Route
      {...routeProps}
      render={(props) => (
        hasPermission(permission, me) ? render(props) : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  )
}

export default PermissionRoute
