import React from 'react'
import UploadIcon from '@material-ui/icons/CloudUpload'
import { SOURCETYPE_OFFICE365, SOURCETYPE_GOOGLEDRIVE } from 'common/proto'
import LogoSharePointOnline from 'icons/LogoSharePointOnline'
import LogoGoogleDrive from 'icons/LogoGoogleDrive'
import { theme } from 'services/theme/reDockTheme'

export default ({ type, size }) => {
  switch (type) {
    case SOURCETYPE_OFFICE365:
      return <LogoSharePointOnline style={{ fontSize: size }} />
    case SOURCETYPE_GOOGLEDRIVE:
      return <LogoGoogleDrive style={{ fontSize: size }} />
    default:
      return <UploadIcon style={{ fontSize: size, color: theme.palette.text.secondary }} />
  }
}
