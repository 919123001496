import React from 'react'
import { detect } from 'detect-browser'
import gql from 'graphql-tag'
import trim from 'lodash/trim'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ContextSegment from './ContextSegment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import CommonGraphqlFragments from 'common/graphqlclient/Fragments'
import { buildSegmentsTree, doCopy, getClips, joinClips } from 'services/redock/Segment'
import FileLink from 'components/Common/FileLink'

const browser = detect()
const browserModalHeightBroken = browser.name === 'ie'

const styles = theme => ({
  closeButton: {
    color: 'inherit'
  },
  copyButton: {
    width: '168px'
  }
})

class ContextView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: this.props.segmentId && true,
      clip: null,
      segments: [],
      doc: null,
      currentDocument: null,
      activeSegmentRef: React.createRef(),
      segmentPositionInTheSearch: 0
    }
  }

  componentDidMount () {
    if (this.props.segmentId) {
      this.loadSegments(this.props.segmentId)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.segmentId && this.props.segmentId !== prevProps.segmentId) {
      this.scrolled = false
      this.loadSegments(this.props.segmentId)
    }

    const node = this.state.activeSegmentRef.current
    if (!this.scrolled && node) {
      node.scrollIntoView({ block: 'center', inline: 'nearest' })
      this.scrolled = true
    }
  }

  loadSegments = async (segmentUuid) => {
    this.setState({ loading: true })

    try {
      const startTime = Date.now()
      const documentResult = (await this.props.client.query({
        query: DocumentQuery,
        variables: { uuid: segmentUuid }
      })).data.v2Api.document

      // Should reconstruct tree?
      const segments = buildSegmentsTree(documentResult.uuid, documentResult.segments, documentResult.edges)

      const selectSegmentAndParent = (segs, uuid) => {
        segs.forEach(s => {
          // This will select the closest heading when the document loads. We ignore segments
          // with no html because they are not displayed in the ContextView making them impossible
          // to "select/unselect"
          if (s.html && s.uuid === uuid) {
            s.selected = true
            segs.forEach(s2 => {
              if (s2.html && s2.uuid === s.parentUuid) {
                s2.selected = true
              }
            })
          }
        })
      }
      selectSegmentAndParent(segments, segmentUuid)

      this.setState({
        currentDocument: documentResult.uuid,
        doc: documentResult,
        segments: segments,
        loading: false
      })

      this.updateClip()

      if (this.props.onSegmentsLoaded) { this.props.onSegmentsLoaded(this.props.segmentId, Date.now() - startTime) }
    } catch (err) {
      console.log(err)
      this.props.enqueueSnackbar('Oops, there was an error loading the segments', { variant: 'error', persist: true })
    }
  }

  toggleSegmentSelection = (segments, segment, selected) => {
    segments.forEach(s => {
      if (s.uuid === segment.uuid) {
        s.selected = selected
      } else if (s.parentUuid === segment.uuid) {
        this.toggleSegmentSelection(segments, s, selected)
      }
    })
  }

  updateSelection = (segment, selected) => {
    const newSegments = [...this.state.segments]
    this.toggleSegmentSelection(newSegments, segment, selected)

    this.setState({
      segments: newSegments
    })

    this.updateClip()
  }

  selectedSegments = (result, s) => {
    if (s.selected) {
      result.push(s)
    }
    return result
  }

  getSelectedSegments = _ => {
    return this.state.segments.reduce(this.selectedSegments, [])
  }

  handleCloseDocument = () => {
    if (this.props.onClose) { this.props.onClose() }
  }

  updateClip = async () => {
    this.setState({ clip: null })

    const clips = await getClips(this.props.client, this.getSelectedSegments().map(s => s.uuid))
    const clip = joinClips(clips)

    this.setState({ clip })
  }

  handleCopy = async () => {
    doCopy(
      this.state.clip,
      this.props.enqueueSnackbar,
      () => { this.props.userCopiedSegments(this.getSelectedSegments().map(s => { return { hitSegmentId: this.props.segmentId, segment: s } }), 'CONTEXT_VIEW') }
    )
  }

  handleFileDownloaded = async (fileName, fileType, sourceDocUri, action) => {
    const { segmentId, onFileDownloaded } = this.props

    onFileDownloaded(
      fileName,
      fileType,
      'CONTEXT_VIEW',
      action,
      null,
      segmentId
    )
  }

  render () {
    const { path, classes, segmentId } = this.props
    const { doc, segments, clip, loading, activeSegmentRef } = this.state

    if (!segmentId) { return null }

    // Last item is the fileName, ignore it
    let pathSegments = path ? trim(path, '/').split('/').slice(0, -1) : []
    if (pathSegments.length === 1 && pathSegments[0] === '') { pathSegments = [] } // split on empty string will return array with an empty string...

    return (
      <Dialog open={segmentId !== null} maxWidth='lg' fullWidth fullScreen={browserModalHeightBroken} scroll='paper' onBackdropClick={this.handleCloseDocument}>
        {loading || !doc ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            <DialogTitle>
              <Grid container direction='row' justify='flex-start' wrap='nowrap' align-item='center'>
                <Grid item>
                  <IconButton aria-label='Close' className={classes.closeButton} onClick={this.handleCloseDocument}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                {/* left margin is to ensure the title will be centered between the left button (48px + 120px margin) and the right selected text (168px width) */}
                <Grid item style={{ flexGrow: '1', marginLeft: '120px' }}>
                  <Typography variant='subtitle2' color='inherit' style={{ textAlign: 'center' }}>
                    {pathSegments.map(segment =>
                      <>
                        <span>{segment}</span>
                        <span>/</span>
                      </>)}
                    <FileLink fileName={doc.title} fileType={doc.fileType} sourceDocUri={doc.uri} onFileDownloaded={this.handleFileDownloaded} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant='contained' color='secondary' disabled={!clip || this.getSelectedSegments().length === 0} className={classes.copyButton} onClick={this.handleCopy}>
                    {this.getSelectedSegments().length
                      ? `Copy ${this.getSelectedSegments().length} Selected`
                      : 'None Selected'}
                  </Button>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container direction='column' justify='flex-start' alignItems='center'>
                <Grid item xs>
                  {segments.map((s) =>
                    <ContextSegment
                      key={s.uuid}
                      activeSegmentRef={activeSegmentRef}
                      segment={s}
                      selected={s.selected}
                      activeSegmentId={segmentId}
                      updateSelection={this.updateSelection}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    )
  }
}

const DocumentQuery = gql`
  query document($uuid: ID!) {
    v2Api {
      document(uuid: $uuid) {
        uuid
        title
        fileType
        uri
        cmsId
        segments {
          uuid
          type
          fileType
          content {
            __typename
            ... on RContentRepr {
              repr
            }
            ... on PlainRepr {
              value
            }
            ... on HtmlRepr {
              value
            }
            ... on ImageRepr {
              uri
              mimeType
              dimensions {
                widthPixels
                heightPixels
              }
            }
          }
        }
        edges {
          ...edgeFields
        }
      }
    }
  }
  
  ${CommonGraphqlFragments.edge}
`

export default withStyles(styles)(withApollo(withSnackbar(withRouter(ContextView))))
