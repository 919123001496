import Grid from '@material-ui/core/Grid'
import React, { memo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import SearchResultCard from 'components/Search/SearchResultCard'

const styles = theme => ({
  resultBody: {
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    alignItems: 'center'
  }
})

const SearchResults = ({ classes, hits, onFileDownloaded, userCopiedSegments, showSimilarByUuid, onOpenContextView, setSourceContext }) => {
  if (!hits || hits.length === 0) return null

  return (
    <Grid className={classes.resultBody} container spacing={3}>
      {hits.map(h =>
        <SearchResultCard
          key={h.id}
          hit={h}
          onFileDownloaded={onFileDownloaded}
          userCopiedSegments={userCopiedSegments}
          showSimilarByUuid={showSimilarByUuid}
          onOpenContextView={onOpenContextView}
          setSourceContext={setSourceContext}
        />)}
    </Grid>)
}

export default memo(withStyles(styles)(SearchResults))
