import React from 'react'
import gql from 'graphql-tag'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import WarningIcon from '@material-ui/icons/Warning'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'

import { FormattedException } from 'common/graphqlclient/ErrorHandler'
import { allAllowedClients, codesToClients } from 'common/users/userClients'
import ConfirmationDialog from 'components/Common/ConfirmationDialog'
import DataTable from 'components/Common/DataTable'
import UserFormDialog from 'scenes/admin/UserFormDialog'
import AuthorizationContext from 'contexts/Common/AuthorizationContext'

const styles = theme => ({
  item: {
    padding: theme.spacing(1)
  },
  clientList: {
    '& div': {
      'margin-right': theme.spacing(0.25)
    }
  }
})

const RowDataIndexes = {
  FIRST_NAME: 0,
  LAST_NAME: 1,
  EMAIL: 2,
  JOB_TITLE: 3,
  CLIENT: 4,
  CLIENTS_ALLOWED: 5,
  ENV: 6,
  ROLES: 7,
  UUID: 8,
  ENABLED: 9,
  ACTIONS: 10
}

const columnToAttribute = {
  Uuid: 'uuid',
  Client: 'client',
  'Clients Allowed': 'clientsAllowed',
  Email: 'email',
  Env: 'env',
  Roles: 'roles',
  'First Name': 'firstName',
  'Last Name': 'lastName',
  'Job Title': 'jobTitle',
  Enabled: 'enabled'
}

const resultToNotification = (result, idx) => (<p>{`${result.deleted ? 'SUCCESS' : `ERROR : ${result.errorMessage}`}`}</p>)

const stringColumn = (field, name, sortAttribute, sortDirection, options) => ({
  name: name,
  options: {
    sortDirection: sortAttribute === field ? sortDirection : 'none',
    ...options
  }
})

const booleanColumn = (field, name, sortAttribute, sortDirection, options) =>
  stringColumn(field, name, sortAttribute, sortDirection, {
    customBodyRender: (value, tableMeta, updateValue) => (<span>{value ? 'yes' : 'no'}</span>),
    ...options
  })

const arrayColumn = (field, name, sortAttribute, sortDirection, options) =>
  stringColumn(field, name, sortAttribute, sortDirection, {
    customBodyRender: (value, tableMeta, updateValue) => (<p>{value.join(', ')}</p>),
    ...options
  })

class UserManagement extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showConfirmationDialog: false,
      showForm: false,
      editUser: null,
      selectedRows: [],
      sortAttribute: null,
      sortDirection: null,
      confirmationDialog: {
        title: null,
        text: null,
        target: null,
        handleConfirm: null,
        showConfirm: true
      }
    }
  }

  setRawData = (rawData) => {
    this.rawData = rawData
  }

  // Configure the mui-table  (https://github.com/gregnb/mui-datatables)
  getColumns = (Roles, Permissions, hasPermission, roleValueToLabel, me, classes, deletionWording) => [
    stringColumn('firstName', 'First Name', this.state.sortAttribute, this.state.sortDirection),
    stringColumn('lastName', 'Last Name', this.state.sortAttribute, this.state.sortDirection),
    stringColumn('email', 'Email', this.state.sortAttribute, this.state.sortDirection),
    stringColumn('jobTitle', 'Job Title', this.state.sortAttribute, this.state.sortDirection),
    stringColumn('client', 'Primary Client', this.state.sortAttribute, this.state.sortDirection, {
      display: allAllowedClients(me) > 1,
      customBodyRender: (value, tableMeta, updateValue) => {
        const client = codesToClients(me, value)[0]
        return !client.missing ? (
          <Chip label={client.name} variant='outlined' size='small' />
        ) : (
          <Tooltip title='Client does not exist' placement='top'>
            <Chip label={value} variant='outlined' size='small' style={{ color: 'red', borderColor: 'red' }} icon={<WarningIcon style={{ color: 'red' }} />} />
          </Tooltip>
        )
      }
    }),
    stringColumn('clientsAllowed', 'Additional Clients', this.state.sortAttribute, this.state.sortDirection, {
      display: allAllowedClients(me).length > 1,
      customBodyRender: ([clientCodesAllowed, clientsAllowedHidden], tableMeta, updateValue) => {
        function clientRender (client) {
          return !client.missing ? (
            <Chip key={client.code} label={client.name} variant='outlined' size='small' />
          ) : (
            <Tooltip key={client.code} title='Client does not exist' placement='top'>
              <Chip label={client.code} variant='outlined' size='small' style={{ color: 'red', borderColor: 'red' }} icon={<WarningIcon style={{ color: 'red' }} />} />
            </Tooltip>
          )
        }

        const additionalClients = clientCodesAllowed.includes('*')
          ? <Chip label='* (All)' variant='outlined' size='small' />
          : codesToClients(me, clientCodesAllowed)
            .map(clientRender)

        return (
          <Box display='flex' alignItems='center' className={classes.clientList}>
            {additionalClients}
            {clientsAllowedHidden ? (
              <Box pl={additionalClients.length > 0 ? 0.75 : 0}>
                <Tooltip title='Some clients hidden due to permissions' placement='top'>
                  <WarningIcon />
                </Tooltip>
              </Box>
            ) : null}
          </Box>
        )
      }
    }),
    stringColumn('env', 'Env', this.state.sortAttribute, this.state.sortDirection, { display: hasPermission(Permissions.MANAGE_USERS_ADVANCED, me) }),
    arrayColumn('roles', 'Roles', this.state.sortAttribute, this.state.sortDirection, {
      customBodyRender: (value, tableMeta, updateValue) => (<>{value.map((v, i) => <p key={i}>{roleValueToLabel(v)}</p>)}</>)
    }),
    stringColumn('uuid', 'Uuid', this.state.sortAttribute, this.state.sortDirection, { display: hasPermission(Permissions.MANAGE_USERS_ADVANCED, me) }),
    booleanColumn('enabled', 'Enabled', this.state.sortAttribute, this.state.sortDirection, { display: hasPermission(Permissions.MANAGE_USERS_ADVANCED, me) }), {
      name: 'Actions',
      options: {
        customBodyRender: value => ((hasPermission(Permissions.MANAGE_USERS_ADVANCED, me) || value[1].every(r => r !== Roles.SuperAdmin)) ? (
          <Box display='flex' flexDirection='row' flexWrap='nowrap'>
            <Tooltip title='Edit'>
              <IconButton onClick={() => {
                const users = this.rawData.v2Api.admin.users.users
                this.setState({ showForm: true, editUser: users.find(u => u.uuid === value[0]) })
              }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete'><IconButton onClick={() => this.deleteUsers(deletionWording, [value[0]])}><DeleteIcon /></IconButton></Tooltip>
          </Box>
        ) : null)
      }
    }
  ]

  onRowsSelected = (newSelectedRows, selectedRows) => {
    this.setState({
      selectedRows: selectedRows.map(r => r.index)
    })
  }

  clearSelectedRows = () => {
    this.setState({
      selectedRows: []
    })
  }

  onColumnSortChange = (changedColumn, direction) => {
    if (columnToAttribute[changedColumn] === undefined) { return }

    // mui-datatable doesn't cycle back to 'none' direction which is unintuitive so we'll implement it ourselves
    // here instead
    if (columnToAttribute[changedColumn] === this.state.sortAttribute && this.state.sortDirection === 'asc') {
      this.setState({
        sortAttribute: null,
        sortDirection: null
      })
    } else {
      this.setState({
        sortAttribute: columnToAttribute[changedColumn],
        sortDirection: direction === 'descending' ? 'desc' : 'asc'
      })
    }
  }

  deleteUsers = (deletionWording, uuids) => {
    const confirmMessage = <span>{`${uuids.length} selected user(s) will be ${deletionWording}`}</span>

    this.confirm(
      'Delete User(s)',
      confirmMessage,
      null,
      null,
      async () => {
        try {
          const result = await this.props.client.mutate({
            mutation: DeleteUsersMutation,
            variables: {
              uuids: uuids
            }
          })
          const results = result.data.v2Api.deleteUsers.results
          if (results.every(r => r.deleted)) {
            this.props.enqueueSnackbar(`${results.length} ${results.length > 1 ? 'users have' : 'user has'} been ${deletionWording}. You will need to manually refresh the table.`)
          } else {
            this.props.enqueueSnackbar(<div>{results.map((r, i) => resultToNotification(r, i))}</div>, { variant: results.every(r => !r.deleted) ? 'error' : 'warning' })
          }
        } catch (err) {
          this.props.enqueueSnackbar(<FormattedException err={err} />, { variant: 'error' })
        } finally {
          this.setState({
            selectedRows: []
          })
        }
      },
      uuids.length > 0
    )
  }

  handleDeleteUsers = (deletionWording, selectedRows, displayData) => {
    const uuids = selectedRows.data.map(r => displayData[r.dataIndex].data[RowDataIndexes.UUID])

    this.setState({
      selectedRows: selectedRows.data.map(r => r.index)
    })

    this.deleteUsers(deletionWording, uuids)
  }

  confirm = (title, text, target, targetLabel, handleConfirm, showConfirm) => {
    this.setState({
      showConfirmationDialog: true,
      confirmationDialog: {
        title: title,
        text: text,
        target: target,
        targetLabel: targetLabel,
        handleConfirm: handleConfirm,
        showConfirm: showConfirm
      }
    })
  }

  render () {
    const { me, classes, selectedClient } = this.props
    const { showConfirmationDialog, confirmationDialog, selectedRows, sortAttribute, sortDirection, showForm, editUser } = this.state

    const { Roles, Permissions, hasPermission, roleValueToLabel } = this.context

    const deletionWording = hasPermission(Permissions.MANAGE_USERS_ADVANCED, me) ? 'disabled' : 'deleted'

    const columns = this.getColumns(Roles, Permissions, hasPermission, roleValueToLabel, me, classes, deletionWording)

    return (
      <Grid
        container
        direction='row'
        justify='flex-start'
        style={{ height: '100%' }}
      >
        <Grid item xs style={{ height: '100%' }} className={classes.item}>
          <DataTable
            id='Users'
            title='Users'
            me={me}
            query={UsersQuery}
            queryVariables={{
              sortAttribute,
              sortDirection
            }}
            defaultValues={{ clientCodes: [selectedClient] }}
            columns={columns}
            setRawData={this.setRawData}
            clearSelectedRows={this.clearSelectedRows}
            options={{
              selectableRows: 'multiple',
              rowsSelected: selectedRows,
              onRowsSelect: this.onRowsSelected,
              sort: true,
              onColumnSortChange: this.onColumnSortChange,
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
                <Tooltip title='Delete Selected User(s)'>
                  <IconButton onClick={() => this.handleDeleteUsers(deletionWording, selectedRows, displayData)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>,
              setRowProps: (row, rowIndex) => {
                if (row[RowDataIndexes.ENABLED].props.children === 'no') {
                  return {
                    style: {
                      opacity: '0.3'
                    }
                  }
                }

                return {}
              }
            }}
            onCreate={() => { this.setState({ showForm: true, editUser: null }) }}
            extractData={data => data.v2Api.admin.users.users.map(u => [
              u.firstName,
              u.lastName,
              u.email,
              u.jobTitle,
              // datatable does not support objects
              u.client.code,
              [u.clientCodesAllowed, u.clientsAllowedHidden],
              u.env,
              u.roles,
              u.uuid,
              u.enabled,
              [u.uuid, u.roles]
            ])}
            extractTotal={data => data.v2Api.admin.users.total}
          />
        </Grid>
        <UserFormDialog
          me={me}
          open={showForm}
          editUser={editUser}
          selectedClient={selectedClient}
          onClose={() => this.setState({ showForm: false })}
        />
        <ConfirmationDialog
          open={showConfirmationDialog}
          title={confirmationDialog.title}
          text={confirmationDialog.text}
          target={confirmationDialog.target}
          showConfirm={confirmationDialog.showConfirm}
          targetLabel='Confirm Action'
          onClose={() => this.setState({ showConfirmationDialog: false })}
          onConfirm={confirmationDialog.handleConfirm}
        />
      </Grid>
    )
  }
}
// Tell React the type of context to load inside this.context
UserManagement.contextType = AuthorizationContext

const UsersQuery = gql`
  query usersQuery($clients: [ID!], $page: Int!, $rowsPerPage: Int!, $sortAttribute: String, $sortDirection: String, $searchText: String) {
    v2Api {
      admin{
        users(clients: $clients, page: $page, rowsPerPage: $rowsPerPage, sortAttribute: $sortAttribute, sortDirection: $sortDirection, searchText: $searchText) {
          total
          users {
            uuid,
            client {
              code
            }
            clientCodesAllowed,
            clientsAllowedHidden,
            env,
            enabled,
            firstName,
            lastName,
            jobTitle,
            roles,
            email
          }
        }
      }
    }
  }
`

const DeleteUsersMutation = gql`
  mutation deleteUsers($uuids: [ID!]!) {
    v2Api {
      deleteUsers(uuids: $uuids) {
        results {
          uuid
          deleted
          errorMessage
        }
      }
    }
  }
`

export default withSnackbar(withApollo(withStyles(styles)(UserManagement)))
