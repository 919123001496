import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Button from '@material-ui/core/Button'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import RedoIcon from '@material-ui/icons/Redo'
import { useSnackbar } from 'notistack'

import { theme } from 'services/theme/reDockTheme'
import { instantToDateTimeString } from 'common/utilities/dates'
import { arrayIsNullOrEmpty } from 'common/utilities/arrays'
import { setDlqEventKey } from 'common/utilities/dlqUtilities'
import useConfirmationDialog from 'hooks/Common/useConfirmationDialog'
import AnchorButton from 'components/Common/AnchorButton'
import { removeTypename } from 'common/utilities/graphql'
import { FormattedException } from 'common/graphqlclient/ErrorHandler'
import useAuthorization from 'hooks/Common/useAuthorization'

const FAILED_EVENTS = ['FileAnalysisFailedEvent', 'FileSegmentationFailedEvent', 'FileIndexingFailedEvent']

const RelatedEventRow = ({ me, event, history, pathname, onReprocessed }) => {
  const { producedEvent, dlqEvents } = event
  const { showConfirmationDialog } = useConfirmationDialog()
  const { enqueueSnackbar } = useSnackbar()
  const { Permissions, hasPermission } = useAuthorization()

  const [reprocessEventMutation] = useMutation(ReprocessEventMutation, {
    onCompleted: data => {
      const result = data.v2Api.reprocessEvent
      if (result.success) {
        enqueueSnackbar('Event sent for Reprocessing.')
      } else {
        enqueueSnackbar(result.message, { variant: 'error', persist: true })
      }

      if (onReprocessed) { onReprocessed() }
    },
    onError: error => {
      console.log(error)
      enqueueSnackbar(<FormattedException err={error} />, { variant: 'error' })
    }
  })

  const doReprocess = (producedEvent) => {
    showConfirmationDialog({
      title: 'Reprocess Event',
      message: 'Are you sure you want to reprocess this event?',
      onConfirm: () => {
        reprocessEventMutation({
          variables: {
            params: {
              topic: producedEvent.context.topic,
              partition: producedEvent.context.partition,
              offset: producedEvent.context.offset
            }
          }
        })
      }
    })
  }

  let style = {}
  if (FAILED_EVENTS.indexOf(event.name) >= 0) { style = { backgroundColor: theme.palette.error.light } }
  return (
    <TableRow style={style}>
      <TableCell>{producedEvent.event.id}</TableCell>
      <TableCell>{producedEvent.event.name}</TableCell>
      <TableCell>{instantToDateTimeString(producedEvent.event.timestamp)}</TableCell>
      <TableCell>{producedEvent.event.parentIds.join('/n')}</TableCell>
      <TableCell>{producedEvent.context.partition}</TableCell>
      <TableCell>{producedEvent.context.offset}</TableCell>
      <TableCell>{producedEvent.context.topic}</TableCell>
      <TableCell>{instantToDateTimeString(producedEvent.context.timestamp)}</TableCell>
      {hasPermission(Permissions.VIEW_DLQS, me) ? (
        <TableCell>{dlqEvents ? dlqEvents.map((d, i) => (
          <p key={i}>
            <AnchorButton onClick={() => setDlqEventKey(history, pathname, { ...d.key })}>
              {d.key.uuid}
            </AnchorButton>
          </p>)) : '-'}
        </TableCell>
      ) : null}
      <TableCell>
        <Tooltip title='Reprocess'>
          <IconButton onClick={() => doReprocess(producedEvent)}>
            <RedoIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

const ExpandableEventRows = ({ me, events, history, pathname, onReprocessed }) => {
  const { Permissions, hasPermission } = useAuthorization()
  const [expanded, setExpanded] = useState(false)

  if (arrayIsNullOrEmpty(events)) { return null }

  return (
    <>
      {expanded ? events.map((e, i) => <RelatedEventRow key={i} me={me} event={e} pathname={pathname} history={history} onReprocessed={onReprocessed} />) : null}
      <TableRow key='expand'>
        <TableCell colSpan={hasPermission(Permissions.VIEW_DLQS, me) ? 10 : 9} onClick={() => setExpanded(!expanded)} style={{ textAlign: 'center', cursor: 'pointer' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </TableCell>
      </TableRow>
    </>
  )
}

const RelatedEventsTable = ({ me, events, history, pathname, onReprocessed, setSelectedRows, refetch, title }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { showConfirmationDialog } = useConfirmationDialog()
  const { Permissions, hasPermission } = useAuthorization()

  const sorted = events.sort((a, b) => b.producedEvent.context.offset - a.producedEvent.context.offset)
  const topEvents = [...sorted].slice(0, 5)
  const bottomEvents = [...sorted].slice(5)

  const dlqEventKeys = events.flatMap(e => e.dlqEvents.map(k => k.key))

  const [deleteDlqEventsMutation] = useMutation(DeleteDlqEvents, {
    onCompleted: data => {
      const results = data.v2Api.deleteDlqEvents
      if (results.every(r => r.success)) {
        enqueueSnackbar(`${results.length} dlqs have been marked for deletion.`)
      } else if (results.every(r => !r.success)) {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`ERROR: ${r.key.uuid}: ${r.message}`}</li>
          )}
          </ul>
          , { variant: 'error', persist: true }
        )
      } else {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`${r.success ? 'SUCCESS' : 'ERROR'}: ${r.key.uuid}${r.success ? '' : `: ${r.message}`}`}</li>
          )}
          </ul>
          , { variant: 'warning', persist: true }
        )
      }

      if (refetch) { refetch() }
      setSelectedRows([])
    },
    onError: error => {
      console.log(error)
      enqueueSnackbar(<FormattedException err={error} />, { variant: 'error' })
    }
  })

  const deleteFileDlqs = (dlqEventKeys) => {
    showConfirmationDialog({
      title: 'Delete All Associated Dlqs',
      message: `This will remove ${dlqEventKeys.length} Dlq(s) associated to this file. Are you sure you want to continue?`,
      confirmLabel: 'Delete Dlqs',
      onConfirm: async () => {
        deleteDlqEventsMutation({ variables: { keys: dlqEventKeys.map(k => removeTypename(k)) } })
      }
    })
  }

  if (!events || events.length === 0) return null

  return (
    <>
      <Typography color='textPrimary' variant='subtitle1'>{title}</Typography>
      <Paper elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Timestamp (reDock)</TableCell>
              <TableCell>ParentIds</TableCell>
              <TableCell>Partition</TableCell>
              <TableCell>Offset</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Timestamp (kafka)</TableCell>
              {hasPermission(Permissions.VIEW_DLQS, me) ? (
                <TableCell>
                  Dlqs <Button variant='outlined' color='secondary' size='small' onClick={() => deleteFileDlqs(dlqEventKeys)}>Delete All</Button>
                </TableCell>
              ) : null}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topEvents.map((e, i) => <RelatedEventRow key={i} me={me} event={e} pathname={pathname} history={history} onReprocessed={onReprocessed} />)}
            <ExpandableEventRows me={me} events={bottomEvents} pathname={pathname} history={history} onReprocessed={onReprocessed} />
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

const ReprocessEventMutation = gql`
  mutation reprocessEvent($params: ReprocessEventParams!) {
    v2Api {
      reprocessEvent(params: $params) {
        topic
        partition
        offset
        success
        message
      }
    }
  }
`

const DeleteDlqEvents = gql`
  mutation deleteDlqEvents($keys: [DlqEventKeyInput!]!) {
    v2Api {
      deleteDlqEvents(keys: $keys) {
        key {
          uuid
          id
          process
        }
        success
        message
      }
    }
  }
`

export default RelatedEventsTable
