import React, { Component } from 'react'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'

class SimpleSearchControl extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value
    }
  }

  handleChange = event => {
    event.preventDefault()
    this.setState({ value: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.onSubmit(this.state.value)
  }

  render () {
    return (
      <form onSubmit={this.handleSubmit}>
        <Input
          id='simple-search'
          value={this.state.value}
          onChange={this.handleChange}
          endAdornment={<InputAdornment position='end'><IconButton onClick={this.handleSubmit}><SearchIcon /></IconButton></InputAdornment>}
        />
      </form>
    )
  }
}

export default SimpleSearchControl
