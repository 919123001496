import { ApolloClient } from 'apollo-client'
import { InMemoryCache, IntrospectionFragmentMatcher, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { RetryLink } from 'apollo-link-retry'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import introspectionQueryResultData from './fragmentTypes.json'

const graphQlClients = {}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

export function createGraphQlClient (tokenProvider, graphQlServer) {
  if (!tokenProvider || !graphQlServer) {
    throw new Error('Please provide a token provider, and GraphQL and Websocket subscription endpoints')
  }
  const serverKey = `${graphQlServer}`

  if (!(serverKey in graphQlClients)) {
    const uploadLink = createUploadLink({ uri: graphQlServer })

    // https://www.apollographql.com/docs/link/links/retry.html#options-default
    const retryHandler = new RetryLink({
      attempts: {
        max: 3,
        retryIf: (_, _operation) => {
          const response = _operation.getContext().response
          return response ? response.status !== 400 : true
        }
      }
    })

    const errorHandler = onError(({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) =>
          console.error(`[GraphQL error]: Message: ${message}, Internal Message: ${extensions.internalMessage}, Code: ${extensions.code}, Error Id: ${extensions.errorId}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
        )
      }
      if (networkError) {
        if (networkError.statusCode === 401) {
          // api token expired or other issue? this will cause `setAuthorization` to try to create a new one using our
          // Orchard credentials when `retryHandler` attempts the operation again
          // don't think we need to do anything special here with msal, but leave it as a separate branch JIC
          console.error('[Network error 401 Unauthorized]')
        } else {
          console.error('[Network error]', networkError)
        }
      }
    })

    const setAuthorization = setContext(async () => {
      const token = await tokenProvider.getToken()
      if (token) {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
            ...tokenProvider.additionalAuthHeaders()
          }
        }
      } else return null
    })

    const link = ApolloLink.from([
      retryHandler,
      errorHandler,
      setAuthorization,
      uploadLink
    ])

    graphQlClients[serverKey] = new ApolloClient({
      link: link,
      cache: new InMemoryCache({
        fragmentMatcher,
        dataIdFromObject: object => {
          switch (object.__typename) {
            case 'DlqEventKey': return `${object.uuid}-${object.id}-${object.process}`
            case 'DlqEventKeyInput': return `${object.uuid}-${object.id}-${object.process}`
            default: {
              if (object.uuid) {
                // use uuid when available
                return object.uuid
              } else {
                return defaultDataIdFromObject(object)
              } // fall back to default handling
            }
          }
        }
      }),
      connectToDevTools: process.env.NODE_ENV !== 'production',
      queryDeduplication: true
    })
  }
  return graphQlClients[serverKey]
}
