import React, { useLayoutEffect, useState } from 'react'
import classnames from 'classnames'
import filesize from 'filesize'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpandIcon from '@material-ui/icons/KeyboardArrowDown'
import { FormattedErrorCode, FormattedException } from 'common/graphqlclient/ErrorHandler'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { fileStatusesEnum } from 'common/config/Constants'
import AnchorButton from 'components/Common/AnchorButton'
import useAuthorization from 'hooks/Common/useAuthorization'

const customTheme = theme => createMuiTheme({
  ...theme,
  overrides: {
    MuiStepper: {
      root: {
        padding: 4,
        backgroundColor: 'transparent'
      }
    },
    MuiStepLabel: {
      labelContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  }
})

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 400,
    marginTop: 2,
    marginBottom: 2,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2)
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  expandButton: {
    flexGrow: 0
  },
  collapseButton: {
    transform: 'rotate(180deg)'
  },
  title: {
    flexGrow: 1,
    fontSize: 14
  },
  titleSummary: {
    flexGrow: 0,
    fontSize: 14,
    color: theme.palette.error.contrastText,
    fontStyle: 'italic',
    marginRight: theme.spacing(1)
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  completed: {
    backgroundColor: theme.palette.primary.main
  },
  failed: {
    backgroundColor: theme.palette.error.light
  },
  completedTitle: {
    color: theme.palette.secondary.contrastText
  },
  failedTitle: {
    color: theme.palette.error.contrastText
  }
}))

const useStepStyles = makeStyles(theme => ({
  progress: {
    position: 'relative',
    top: '-48px',
    display: 'block',
    height: '0px'
  },
  iconRootPrimary: {
    '&$active': {
      color: theme.palette.primary.main
    },
    '&$completed': {
      color: theme.palette.primary.main
    }
  },
  iconRootSecondary: {
    '&$active': {
      color: theme.palette.secondary.main
    },
    '&$completed': {
      color: theme.palette.secondary.main
    }
  },
  active: {},
  completed: {}
}))

const StatusSummary = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
}

const placeholder = (i) => <Typography key={i} variant='caption' display='block'>&nbsp;</Typography>
const placeholders = (count) => Array(count).fill('').map((_, i) => <Typography key={i} variant='caption' display='block'>&nbsp;</Typography>)
const defaultOutput = (<>{placeholders(2)}</>)

const FileUploadStep = ({ label, stepProps, labelProps, ...other }) => {
  const classes = useStepStyles()
  const progress = stepProps.active ? <span className={classes.progress}><CircularProgress color={label === 'Uploading' ? 'secondary' : 'primary'} /></span> : null

  return (
    <Step {...{ ...other, ...stepProps }}>
      <StepLabel
        {...labelProps} StepIconProps={{
          classes: {
            root: label === 'Uploading' ? classes.iconRootSecondary : classes.iconRootPrimary,
            active: classes.active,
            completed: classes.completed
          }
        }}
      >{progress}{label}
      </StepLabel>
    </Step>
  )
}

const FileUploadStatusCard = ({ me, file, setDlqEventKey, hideCompleted, style, toggleShowDetails }) => {
  const { Permissions, hasPermission } = useAuthorization()

  const { raw, fileStatus, uploadResult, error, errors, completed, showDetails, pos } = file
  const { name, size } = raw
  const classes = useStyles()
  // stepProps and labelProps refer to MUI Step props and StepLabel props
  const [isReceivedStep, setIsReceivedStep] = useState({ label: 'Uploading', stepProps: { active: true }, labelProps: { optional: defaultOutput } })
  const [isAnalyzedStep, setIsAnalyzedStep] = useState({ label: 'Initial Analysis', stepProps: { disabled: false }, labelProps: { optional: defaultOutput } })
  const [isSegmentedStep, setIsSegmentedStep] = useState({ label: 'Deeper Analysis', stepProps: { disabled: false }, labelProps: { optional: defaultOutput } })

  let statusSummary = StatusSummary.PROCESSING
  if (isReceivedStep.labelProps.error || isAnalyzedStep.labelProps.error || isSegmentedStep.labelProps.error) {
    statusSummary = StatusSummary.FAILED
  } else if (isReceivedStep.stepProps.completed && isAnalyzedStep.stepProps.completed && isSegmentedStep.stepProps.completed) {
    statusSummary = StatusSummary.COMPLETED
  }

  // Check for files that got rejected at the client-side
  useLayoutEffect(() => {
    if (completed && !uploadResult) {
      let formattedError
      if (errors && errors.find(e => e.code === 'file-invalid-type')) {
        formattedError = <FormattedErrorCode code='unsupported-mime' />
      } else if (errors && errors.find(e => e.code === 'file-too-large')) {
        formattedError = <FormattedErrorCode code='data-too-large' />
      } else if (error) {
        formattedError = <FormattedException err={error} variant='short' />
      } else {
        formattedError = <FormattedErrorCode code='unhandled-exception' variant='short' />
      }

      setIsReceivedStep(prev => ({
        ...prev,
        stepProps: {
          active: false
        },
        labelProps: {
          error: true,
          optional: (
            <>
              <Typography variant='caption' display='block' color='error'>{formattedError}</Typography>
              {placeholders(1)}
            </>
          ),
          summary: <Typography variant='caption' display='block' className={classes.titleSummary}>{formattedError}</Typography>
        }
      }))
    }
  }, [error, errors, uploadResult, classes.titleSummary, completed])

  // Check for files that got rejected at upload time (duplicate file)
  useLayoutEffect(() => {
    if (uploadResult && uploadResult.preExisting) {
      setIsReceivedStep(prev => ({
        ...prev,
        stepProps: {
          active: false
        },
        labelProps: {
          error: true,
          optional: (
            <>
              <Typography variant='caption' display='block' color='error'>Duplicate file</Typography>
              {(name !== uploadResult.name) ? <Typography variant='caption' display='block' color='error'>{uploadResult.name}</Typography> : placeholder()}
            </>
          ),
          summary: <Typography variant='caption' display='block' className={classes.titleSummary}>Duplicate file</Typography>
        }
      }))
    }
  }, [name, classes.titleSummary, uploadResult])

  // Check for files that got uploaded and are going through the normal process
  useLayoutEffect(() => {
    if (!fileStatus) { return }

    const apiErrorOf = (fileStatus, process, className) => {
      if (!fileStatus.apiErrorCode) { return placeholder(1) }
    }

    const dlqOf = (fileStatus, process, className) => {
      if (fileStatus.dlqEvents.length === 0 && !fileStatus.apiErrorCode) { return placeholder(2) }
      const matching = fileStatus.dlqEvents.filter(d => d.key.process.endsWith(process))

      // Top
      let topMessage = placeholder(1)
      if (fileStatus.apiErrorCode) {
        topMessage = <Typography variant='caption' display='block' color='error' className={className}><FormattedErrorCode code={fileStatus.apiErrorCode} /></Typography>
      } else if (matching.length) {
        topMessage = <Typography variant='caption' display='block' color='error' className={className}>Oops... something went wrong</Typography>
      }

      // Bottom
      let bottomMessage = placeholder(1)
      if (matching.length) {
        if (hasPermission(Permissions.VIEW_DLQS, me)) {
          bottomMessage = <Typography variant='caption' display='block' color='error' className={className}><AnchorButton onClick={() => setDlqEventKey(matching[0].key)}>View DlqEvent</AnchorButton></Typography>
        } else {
          bottomMessage = <Typography variant='caption' display='block' color='error' className={className}>{`Log ticket with ID: ${matching[0].key.uuid}`}</Typography>
        }
      } // Assume only one match

      return (
        <>
          {topMessage}
          {bottomMessage}
        </>
      )
    }

    setIsReceivedStep(prev => ({
      ...prev,
      stepProps: {
        completed: fileStatus.isReceived === fileStatusesEnum.YES,
        active: fileStatus.isReceived === fileStatusesEnum.NO
      },
      labelProps: {
        error: fileStatus.isReceived === fileStatusesEnum.FAILED,
        optional: fileStatus.isReceived === fileStatusesEnum.YES ? (
          <>
            <Typography variant='caption' display='block'>Received {filesize(size)}</Typography>
            {placeholders(1)}
          </>
        ) : defaultOutput
      }
    }))
    setIsAnalyzedStep(prev => ({
      ...prev,
      stepProps: {
        completed: fileStatus.isAnalyzed === fileStatusesEnum.YES,
        active: fileStatus.isAnalyzed === fileStatusesEnum.NO && fileStatus.isReceived === fileStatusesEnum.YES,
        disabled: fileStatus.isReceived !== fileStatusesEnum.YES
      },
      labelProps: {
        error: fileStatus.isAnalyzed === fileStatusesEnum.FAILED,
        // Missing: word count, document creation time (outside of reDock)
        optional: fileStatus.isAnalyzed === fileStatusesEnum.YES ? (
          <>
            {fileStatus.pageCount ? <Typography variant='caption' display='block'>{fileStatus.pageCount} pages</Typography> : placeholder()}
            {placeholders(1)}
          </>
        ) : fileStatus.isAnalyzed === fileStatusesEnum.FAILED ? (
          <>
            {apiErrorOf(fileStatus)}
            {dlqOf(fileStatus, 'fileAnalyzer-initialAnalysis-stream')}
          </>
        ) : defaultOutput,
        summary: fileStatus.isAnalyzed === fileStatusesEnum.FAILED ? <>{dlqOf(fileStatus, 'fileAnalyzer-initialAnalysis-stream', classes.titleSummary)}</> : null
      }
    }))
    setIsSegmentedStep(prev => ({
      ...prev,
      stepProps: {
        completed: fileStatus.isSegmented === fileStatusesEnum.YES,
        active: fileStatus.isSegmented === fileStatusesEnum.NO && fileStatus.isAnalyzed === fileStatusesEnum.YES,
        disabled: fileStatus.isAnalyzed !== fileStatusesEnum.YES
      },
      labelProps: {
        error: fileStatus.isSegmented === fileStatusesEnum.FAILED,
        // Missing: word count
        optional: fileStatus.isSegmented === fileStatusesEnum.YES ? (
          <>
            <Typography variant='caption' display='block'>{fileStatus.segmentsCount} segments extracted</Typography>
            {placeholders(1)}
          </>
        ) : fileStatus.isSegmented === fileStatusesEnum.FAILED ? (
          <>
            {dlqOf(fileStatus, 'fileAnalyzer-segmentation-wq')}
          </>
        ) : defaultOutput,
        summary: fileStatus.isSegmented === fileStatusesEnum.FAILED ? <>{dlqOf(fileStatus, 'fileAnalyzer-segmentation-wq', classes.titleSummary)}</> : null
      }
    }))
  }, [me, setDlqEventKey, size, classes.titleSummary, completed, fileStatus, Permissions, hasPermission])

  if (hideCompleted && statusSummary !== StatusSummary.PROCESSING) { return null }

  return (
    <div style={{ ...style }}>
      <Card
        className={classnames(
          classes.card,
          { [`${classes.completed}`]: !showDetails && statusSummary === StatusSummary.COMPLETED },
          { [`${classes.failed}`]: !showDetails && statusSummary === StatusSummary.FAILED }
        )}
      >
        <div className={classes.summary}>
          <Typography
            className={classnames(
              classes.title,
              { [`${classes.completedTitle}`]: !showDetails && statusSummary === StatusSummary.COMPLETED },
              { [`${classes.failedTitle}`]: !showDetails && statusSummary === StatusSummary.FAILED }
            )} color='textSecondary'
          >{name}
          </Typography>
          {!showDetails && (
            (isReceivedStep.labelProps.error && isReceivedStep.labelProps.summary) ||
            (isAnalyzedStep.labelProps.error && isAnalyzedStep.labelProps.summary) ||
            (isSegmentedStep.labelProps.error && isSegmentedStep.labelProps.summary) ||
              <Typography variant='caption' display='block' className={classes.titleSummary}>Completed</Typography>
          )}
          <IconButton
            className={classnames(
              classes.expandButton,
              { [`${classes.collapseButton}`]: showDetails },
              { [`${classes.completedTitle}`]: !showDetails && statusSummary === StatusSummary.COMPLETED },
              { [`${classes.failedTitle}`]: !showDetails && statusSummary === StatusSummary.FAILED }
            )} onClick={() => toggleShowDetails(pos)}
          ><ExpandIcon />
          </IconButton>
        </div>
        {showDetails && (
          <Grid item xs={12}>
            <ThemeProvider theme={customTheme}>
              <Stepper nonLinear alternativeLabel>
                <FileUploadStep {...isReceivedStep} />
                <FileUploadStep {...isAnalyzedStep} />
                <FileUploadStep {...isSegmentedStep} />
              </Stepper>
            </ThemeProvider>
          </Grid>
        )}
      </Card>
    </div>
  )
}

export default FileUploadStatusCard
