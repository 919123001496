import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
    color: theme.palette.secondary.dark,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    textTransform: 'none',
    verticalAlign: 'baseline',
    userSelect: 'text'
  }
})

const AnchorButton = (props) => (
  <Button className={props.classes.root} {...props}>{props.children}</Button>
)

export default withStyles(styles)(AnchorButton)
