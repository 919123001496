import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

// The content inside the <Fragment> has been generated using https://svg2jsx.herokuapp.com/
// Note that in some instances you might need to add quotes around styles values manually
const BaseLogo = createSvgIcon(
  <>
    <g transform='translate(-4 -6)'>
      <path
        fill='#2d92d4'
        d='M42.256 6H15.744C14.781 6 14 6.781 14 7.744v7.259h30V7.744C44 6.781 43.219 6 42.256 6z'
      />
      <path
        fill='#2150a9'
        d='M14 33.054v7.202c0 .963.781 1.744 1.743 1.744h26.513c.963 0 1.744-.781 1.744-1.744v-7.202z'
      />
      <path fill='#2d83d4' d='M14 15.003H44V24.005000000000003H14z' />
      <path fill='#2e70c9' d='M14 24.005H44V33.055H14z' />
    </g>
    <path
      fill='#00488d'
      d='M18.319 28H1.681A1.682 1.682 0 010 26.319V9.681C0 8.753.753 8 1.681 8h16.638C19.247 8 20 8.753 20 9.681v16.638c0 .928-.753 1.681-1.681 1.681z'
    />
    <path
      fill='#fff'
      d='M7.641 19L9.985 29 12.337 29 14.05 21.311 15.764 29 18.015 29 20.359 19 18.403 19 16.857 26.264 15.144 19 12.957 19 11.19 26.489 9.597 19z'
      transform='translate(-4 -6)'
    />
  </>
  , 'LogoWord'
)

export default props => (<BaseLogo viewBox='0 0 40 36' {...props} />)
