import React, { useState } from 'react'
import AnchorButton from 'components/Common/AnchorButton'
import { withApollo } from 'react-apollo'
import { withSnackbar } from 'notistack'
import { downloadFile } from 'services/redock/File'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LogoPDF from 'icons/LogoPDF'
import LogoWord from 'icons/LogoWord'
import LogoPowerpoint from 'icons/LogoPowerpoint'
import { FILETYPE_WORD, FILETYPE_POWERPOINT } from 'common/proto'

const FileLink = React.memo(({ className, client, enqueueSnackbar, fileName, fileNameHighlighted, fileType, sourceDocUri, onFileDownloaded }) => {
  const onError = () => { enqueueSnackbar('Oops, there was an error downloading this document', { variant: 'error', persist: true }) }
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = async (event) => {
    if (fileType === 'PDF') {
      // Present conversion menu
      setAnchorEl(event.currentTarget)
    } else {
      await downloadFile(client, fileName, fileType, sourceDocUri, false, false, onError)
      if (onFileDownloaded) { onFileDownloaded(fileName, fileType, sourceDocUri, 'DOWNLOAD_ORIGINAL_FILE') }
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleViewPdf = async () => {
    await downloadFile(client, fileName, fileType, sourceDocUri, true, false, onError)
    if (onFileDownloaded) { onFileDownloaded(fileName, fileType, sourceDocUri, 'VIEW_ORIGINAL_FILE') }
    handleClose()
  }

  const handleDownloadPdf = async () => {
    await downloadFile(client, fileName, fileType, sourceDocUri, false, false, onError)
    if (onFileDownloaded) { onFileDownloaded(fileName, fileType, sourceDocUri, 'DOWNLOAD_ORIGINAL_FILE') }
    handleClose()
  }

  const handleDownloadAsWord = async () => {
    await downloadFile(client, fileName, FILETYPE_WORD, sourceDocUri, false, true, onError)
    if (onFileDownloaded) { onFileDownloaded(fileName, fileType, sourceDocUri, 'DOWNLOAD_AS_WORD') }
    handleClose()
  }

  const handleDownloadAsPowerPoint = async () => {
    await downloadFile(client, fileName, FILETYPE_POWERPOINT, sourceDocUri, false, true, onError)
    if (onFileDownloaded) { onFileDownloaded(fileName, fileType, sourceDocUri, 'DOWNLOAD_AS_POWER_POINT') }
    handleClose()
  }

  return (
    <>
      <AnchorButton color='secondary' onClick={handleClick}>
        <span className={className} dangerouslySetInnerHTML={{ __html: fileNameHighlighted || fileName }} />
      </AnchorButton>
      <Menu
        id='pdf-handling-menu'
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem onClick={handleViewPdf}>
          <ListItemIcon><LogoPDF /></ListItemIcon>
          <ListItemText primary='View PDF' />
        </MenuItem>
        <MenuItem onClick={handleDownloadPdf}>
          <ListItemIcon><LogoPDF /></ListItemIcon>
          <ListItemText primary='Download as PDF' />
        </MenuItem>
        <MenuItem onClick={handleDownloadAsWord}>
          <ListItemIcon><LogoWord /></ListItemIcon>
          <ListItemText primary='Download as Word' />
        </MenuItem>
        <MenuItem onClick={handleDownloadAsPowerPoint}>
          <ListItemIcon><LogoPowerpoint /></ListItemIcon>
          <ListItemText primary='Download as PowerPoint' />
        </MenuItem>
      </Menu>
    </>
  )
})

export default withApollo(withSnackbar(FileLink))
