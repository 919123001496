import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { reDockContent } from 'services/theme/reDockTheme'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    paddingTop: 6,
    display: 'flex',
    flex: '1 0 auto',
    height: 44
  },
  form: {
    width: reDockContent.pageWidth
  },
  paper: {
    '&:hover': {
      boxShadow: theme.shadows[4]
    }
  },
  adornment: {
    cursor: 'pointer'
  }
}))

export default ({ query, handleSearch, setQuery }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <form onSubmit={(event) => handleSearch(event, 'FORM_SUBMITTED', 'IN_APP')} className={classes.form}>
      <Paper className={classes.paper}>
        <FormControl className={classes.formControl}>
          <Input
            id='search'
            placeholder='Search your content with reDock'
            type='search'
            autoComplete='off'
            autoFocus
            disableUnderline
            fullWidth
            onDoubleClick={evt => { evt.target.select && evt.target.select() }}
            endAdornment={
              <InputAdornment position='end' className={classes.adornment}>
                <SearchIcon onClick={(event) => handleSearch(event, 'SEARCHICON_CLICKED', 'IN_APP')} />
              </InputAdornment>
            }
            value={query}
            onChange={event => setQuery(event.target.value)}
          />
        </FormControl>
      </Paper>
    </form>
  )
}
