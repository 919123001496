const getItemParsed = (storage, key) => {
  const raw = storage.getItem(key)
  if (raw === null) {
    return false
  } else {
    return JSON.parse(raw)
  }
}

export { getItemParsed }
