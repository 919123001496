import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

// The content inside the <Fragment> has been generated using https://svg2jsx.herokuapp.com/
// Note that in some instances you might need to add quotes around styles values manually
const BaseLogo = createSvgIcon(
  <>
    <path
      fill='#dc4c2c'
      d='M8 24c0 9.941 8.059 18 18 18s18-8.059 18-18H26z'
      transform='translate(-4 -6)'
    />
    <path
      fill='#f7a278'
      d='M26 6v18h18c0-9.941-8.059-18-18-18z'
      transform='translate(-4 -6)'
    />
    <path
      fill='#c06346'
      d='M26 6C16.059 6 8 14.059 8 24h18z'
      transform='translate(-4 -6)'
    />
    <path
      fill='#9b341f'
      d='M18.319 28H1.681A1.682 1.682 0 010 26.319V9.681C0 8.753.753 8 1.681 8h16.638C19.247 8 20 8.753 20 9.681v16.638c0 .928-.753 1.681-1.681 1.681z'
    />
    <path
      fill='#fff'
      d='M10.673 13.012H6v10h2.024v-3.521H10.3a3.397 3.397 0 003.397-3.397v-.058a3.024 3.024 0 00-3.024-3.024zm.897 3.346c0 .859-.697 1.556-1.556 1.556h-1.99v-3.325h1.99c.859 0 1.556.697 1.556 1.556z'
    />
  </>
  , 'LogoPowerpoint'
)

export default props => (<BaseLogo viewBox='0 0 40 36' {...props} />)
