import { useContext } from 'react'
import ConfirmationDialogContext from 'contexts/Common/ConfirmationDialogContext'

export default () => {
  const { showConfirmationDialog } = useContext(ConfirmationDialogContext)

  return {
    showConfirmationDialog
  }
}
