import React from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import UndoIcon from '@material-ui/icons/Undo'
import EditIcon from '@material-ui/icons/Edit'
import { withSnackbar } from 'notistack'
import ConfirmationDialog from 'components/Common/ConfirmationDialog'
import DataTable from 'components/Common/DataTable'
import { FormattedErrorCode, FormattedException } from 'common/graphqlclient/ErrorHandler'
import { instantToDateTimeString } from 'common/utilities/dates'
import ClientFormDialog from 'scenes/admin/ClientFormDialog'

class ClientIsBusyError extends Error {
  constructor (...args) {
    super(...args)

    this.name = 'ClientIsBusyError'
    this.userMessage = this.message
  }
}

const styles = theme => ({
  item: {
    padding: theme.spacing(1)
  }
})

class ClientManagement extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showForm: false,
      editClient: null,
      showConfirmationDialog: false,
      confirmationDialog: {
        title: null,
        message: null,
        target: null,
        handleConfirm: null
      }
    }

    this.confirmationDialog = null
    this.refetchData = null
  }

  // Configure the mui-table  (https://github.com/gregnb/mui-datatables)
  getColumns = () => [
    {
      name: 'Name',
      options: {
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          return {
            style: {
              wordBreak: 'break-word'
            }
          }
        }
      }
    },
    {
      name: 'Code',
      options: {}
    },
    {
      name: 'Allowed Email Suffixes',
      options: {
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          return {
            style: {
              wordBreak: 'normal'
            }
          }
        }
      }
    },
    {
      name: 'CreatedAt',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => instantToDateTimeString(value)
      }
    },
    {
      name: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Box display='flex' flexDirection='row' flexWrap='nowrap'>
            <Tooltip title='Edit' placement='top'><IconButton onClick={() => this.setState({ editClient: value[0], showForm: true })}><EditIcon /></IconButton></Tooltip>
            <Tooltip title='Clear Content (force clear with Ctrl, force delete with Shift)' placement='top'>
              <IconButton onClick={e => {
                const isForce = e.ctrlKey
                const isDelete = e.shiftKey
                this.confirm(
                  `${isForce ? ' FORCE ' : ' '}${isDelete ? 'Delete' : 'Clear Content for'} '${value[0].name}'?`,
                  `This will${isForce ? ' FORCE ' : ' '}${isDelete ? 'delete' : 'clear all content from'} client '${value[0].name}'. To confirm, please re-type the client's code [${value[0].code}] below:`,
                  value[0].code,
                  'Client Code',
                  () => this.resetOrDeleteClient(value[0].code, isForce, isDelete))
              }}
              >
                <UndoIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ]

  setRefetchData = (refreshData) => {
    this.refetchData = refreshData
  }

  handleCreateClient = () => {
    this.setState({ showForm: true, editClient: null })
  }

  hideCreateForm = () => {
    this.setState({ showForm: false })
  }

  createClient = async (client) => {
    try {
      const result = await this.props.client.mutate({
        mutation: CreateClient,
        variables: {
          client
        }
      })
      const response = result.data.v2Api.createClient
      if (response) {
        this.props.enqueueSnackbar('Client \'' + response.code + (this.state.editClient ? '\' updated!' : '\' created!'), { variant: 'success' })
      } else {
        this.props.enqueueSnackbar(<FormattedErrorCode code={null} />, { variant: 'error', persist: true })
      }
    } catch (err) {
      this.props.enqueueSnackbar(<FormattedException err={err} />, { variant: 'error', persist: true })
    }

    this.props.refreshMe()
    if (this.refetchData) { this.refetchData() }
    this.hideCreateForm()
  }

  updateClient = async (client) => {
    try {
      const result = await this.props.client.mutate({
        mutation: UpdateClient,
        variables: {
          client
        }
      })
      const response = result.data.v2Api.updateClient
      if (response) {
        this.props.enqueueSnackbar('Client \'' + response.code + (this.state.editClient ? '\' updated!' : '\' created!'), { variant: 'success' })
      } else {
        this.props.enqueueSnackbar(<FormattedErrorCode code={null} />, { variant: 'error', persist: true })
      }
    } catch (err) {
      this.props.enqueueSnackbar(<FormattedException err={err} />, { variant: 'error', persist: true })
    }

    this.props.refreshMe()
    if (this.refetchData) { this.refetchData() }
    this.hideCreateForm()
  }

  confirm = (title, message, target, targetLabel, handleConfirm) => {
    this.setState({
      showConfirmationDialog: true,
      confirmationDialog: {
        title: title,
        message: message,
        target: target,
        targetLabel: targetLabel,
        handleConfirm: handleConfirm
      }
    })
  }

  validatePendingFiles = async client => {
    const result = await this.props.client.query({
      query: FileStatusesQuery,
      variables: {
        clients: [client],
        page: 0,
        rowsPerPage: 0, // We only care about the total found,
        params: {
          pendingOnly: true
        }
      },
      fetchPolicy: 'no-cache'
    })

    const count = result.data.v2Api.admin.fileStatuses.total
    if (count > 0) {
      throw new ClientIsBusyError(`Request denied: Client ${client} still has ${count} file(s) being processed.`)
    }
  }

  resetOrDeleteClient = async (code, isForce, isDelete) => {
    try {
      // allow skipping this check if holding the ctrl key down when clicking or pressing enter in the confirmation dialog
      if (!isForce) {
        await this.validatePendingFiles(code)
      }
      const result = await this.props.client.mutate({
        mutation: isDelete ? DeleteClientByCode : ResetClientByCode,
        variables: {
          code
        }
      })
      const response = isDelete ? result.data.v2Api.deleteClientByCode : result.data.v2Api.resetClientByCode
      if (response) {
        this.props.enqueueSnackbar(`All data for client [${code}] is being ${isDelete ? 'deleted' : 'cleared'}.`, { variant: 'success' })
      } else {
        this.props.enqueueSnackbar(<FormattedErrorCode code={null} />, { variant: 'error', persist: true })
      }
    } catch (err) {
      this.props.enqueueSnackbar(<FormattedException err={err} />, { variant: 'error', persist: true })
    }

    if (isDelete) {
      this.props.refreshMe()
      if (this.refetchData) { this.refetchData() }
    }
  }

  render () {
    const { classes, me } = this.props
    const { showForm, editClient, showConfirmationDialog, confirmationDialog } = this.state

    return (
      <Grid
        container
        direction='row'
        justify='flex-start'
        style={{ height: '100%' }}
      >
        <Grid item xs style={{ height: '100%' }} className={classes.item}>
          <DataTable
            id='ClientManagement'
            useClientPicker={false}
            me={me}
            title='Clients'
            query={SearchClientsQuery}
            queryVariables={{ /* searchText, rowsPerPage and page handled by DataTable */ }}
            columns={this.getColumns()}
            extractData={data => data.v2Api.admin.searchClients.clients.map(c => [
              c.name,
              c.code,
              c.allowedEmailSuffixes.join(', '),
              c.createdAt,
              // This is the dumbest thing but mui-datatables throws a warning (and eventually will throw an error)
              // when passing an object (for reasons that do not affect us since we are doing all the sorting/filtering
              // server-side) so we are working around that by passing in the object inside an array
              [c]
            ])}
            extractTotal={data => data.v2Api.admin.searchClients.total}
            setRefetchData={this.setRefetchData}
            onCreate={this.handleCreateClient}
          />
        </Grid>
        <ClientFormDialog open={showForm} clientCodes={me.clientsAllowed.map(c => c.code)} client={editClient} onSubmit={client => editClient ? this.updateClient(client) : this.createClient(client)} handleClose={this.hideCreateForm} />
        <ConfirmationDialog
          open={showConfirmationDialog}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          target={confirmationDialog.target}
          targetLabel='Client Code'
          onClose={() => this.setState({ showConfirmationDialog: false })}
          onConfirm={confirmationDialog.handleConfirm}
        />
      </Grid>
    )
  }
}

const SearchClientsQuery = gql`
  query searchClientsQuery($page: Int!, $rowsPerPage: Int!, $searchText: String) {
    v2Api {
      admin{
        searchClients(page: $page, rowsPerPage: $rowsPerPage, searchText: $searchText) {
          total
          clients {
            code
            name
            allowedEmailSuffixes
            createdAt
          }
        }
      }
    }
  }
`

const FileStatusesQuery = gql`
  query fileStatusesQuery($clients: [ID!], $page: Int!, $rowsPerPage: Int!, $searchText: String, $params: FindFileStatusesParams!) {
    v2Api {
      admin{
        fileStatuses(clients: $clients, page: $page, rowsPerPage: $rowsPerPage, searchText: $searchText, params: $params) {
          total
        }
      }
    }
  }
`

const CreateClient = gql`
  mutation createClient($client: NewOrUpdatedClient!) {
    v2Api {
      createClient(client: $client) {
        code
        name
        allowedEmailSuffixes
        createdAt
      }
    }
  }
`

const UpdateClient = gql`
  mutation updateClient($client: NewOrUpdatedClient!) {
    v2Api {
      updateClient(client: $client) {
        code
        name
        allowedEmailSuffixes
        createdAt
      }
    }
  }
`

const ResetClientByCode = gql`
  mutation resetClientByCode($code: ID!) {
    v2Api {
      resetClientByCode(code: $code)
    }
  }
`

const DeleteClientByCode = gql`
  mutation deleteClientByCode($code: ID!) {
    v2Api {
      deleteClientByCode(code: $code)
    }
  }
`

export default withStyles(styles)(withApollo(withSnackbar(ClientManagement)))
