import gql from 'graphql-tag'

const segment = gql` fragment segmentFields on Segment {
  uuid
  type
  fileType
  sourceType
  path
  pathRoot
  pathFolders
  pathHighlighted
  title
  content {
    __typename
    ... on RContentRepr {
      repr
    }
    ... on PlainRepr {
      value
    }
    ... on HtmlRepr {
      value
    }
    ... on ThumbnailRepr {
      uri128w
      uri768w
    }
    ... on ImageRepr {
      uri
      mimeType
      dimensions {
        widthPixels
        heightPixels
      }
    }
  }
  contentTimestamp
}
`

const edge = gql` fragment edgeFields on Edge {
  toUuid
  fromUuid
  index
}`

const event = gql`fragment eventFields on Event {
  id
  name
  timestamp
  parentIds
}`

const eventContext = gql`fragment eventContextFields on EventContext {
  topic
  partition
  offset
  timestamp
}`

const fileStatus = gql`fragment fileStatusFields on FileStatus {
  fileName
  isReceived
  isAnalyzed
  isIndexed
  isSegmented
  isDeleted
  cmsUuid
  sourceType
  sourceIdentifier
  path
  sha256
  clientEnv {
    client
    env
  }
  mimeType
  pageCount
  segmentationMethods
  segmentsCount
  dlqEvents {
    ...dlqEventFields
  }
  relatedFileEvents {
    producedEvent {
      event {
        ...eventFields
      }
      context {
        ...eventContextFields
      } 
    }
    dlqEvents {
      ...dlqEventFields
    }
  }
  relatedSegmentEvents {
    producedEvent {
      event {
        ...eventFields
      }
      context {
        ...eventContextFields
      }
    }
    dlqEvents {
      ...dlqEventFields
    }
  }
  createdAt
  modifiedAt
  apiErrorCode
}`

const dlqEvent = gql`fragment dlqEventFields on DlqEvent {
  key {
    uuid
    id
    process
  }
  cmsUuid
  producedEvent {
    event {
      ...eventFields
    }
    context {
      ...eventContextFields
    }
  }
  client
  env
  user
  causalEventUuid
  causalEventTimestamp
  causalEventTopic
  causalEventName
  causalEventOffset
  causalEventPartition
  message
  exception
  status
}`

const connector = gql`fragment connectorFields on Connector {
  uuid
  type
  status
  docsSynced
  docsTotal
}`

const googleDriveConnector = gql`fragment googleDriveConnectorFields on GoogleDriveConnector {
  connector {
    __typename
    ...connectorFields
  }
  credentialsString
  impersonateUser
  initQuery
}`

const sharePointOnlineConnector = gql`fragment sharePointOnlineConnectorFields on SharePointOnlineConnector {
  connector {
    __typename
    ...connectorFields
  }
  registeredEndpoints
  domain
  sitePattern
  sitePatternField
  clientId
  tenantName
}`

export default {
  segment,
  edge,
  event,
  eventContext,
  fileStatus,
  dlqEvent,
  connector,
  googleDriveConnector,
  sharePointOnlineConnector
}
