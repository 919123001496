import React from 'react'
import PropTypes from 'prop-types'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex'
  }
})

class ProfileMenu extends React.Component {
  state = {
    open: false
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  handleLogout = event => {
    this.props.onLogout()
    this.handleClose(event)
  }

  render () {
    const { classes, me } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <IconButton
          buttonRef={node => {
            this.anchorEl = node
          }}
          aria-owns={open ? 'menu-profile-grow' : null}
          aria-haspopup='true'
          color='inherit'
          onClick={this.handleToggle}
        >
          <AccountCircle />
        </IconButton>
        <Popper
          open={open} anchorEl={this.anchorEl} transition disablePortal modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='menu-profile-grow'
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    <MenuItem onClick={this.handleLogout}>Logout {me ? me.email : ''}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

      </div>
    )
  }
}

ProfileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired
}

export { ProfileMenu }

export default withStyles(styles)(ProfileMenu)
