// Proto enum values
export const FILETYPE_WORD = 'WORD'
export const FILETYPE_POWERPOINT = 'POWERPOINT'
export const FILETYPE_PDF = 'PDF'

export const TRANSFORMACTION_APPLY = 1
export const TRANSFORMACTION_SUGGEST = 2

export const SOURCETYPE_OFFICE365 = 'OFFICE365'
export const SOURCETYPE_GOOGLEDRIVE = 'GOOGLE_DRIVE'
