import { arrayUniqueBy } from 'common/utilities/arrays'

/**
 * Our graphql api has a primary "client" field, as well as a "clientsAllowed" field for additional clients.
 * The logic for this can be tricky as we need to ensure we return unique values (for example, if a particular
 * value is in both the client and clientsAllowed fields, logic based on counts will be incorrect unless the
 * dup is taken into account).
 *
 * @param user
 * @param sort Whether to sort on name or not, defaults to not sorted
 * @returns {Client[]}
 */
export function allAllowedClients (user, sort = false) {
  // Set's don't guarantee uniqueness for objects, they compare by reference equality, go by codes instead
  const clients = Array.from(arrayUniqueBy([user.client, ...user.clientsAllowed], c => c.code))
  return sort ? clients.sort((a, b) => a.name.localeCompare(b.name)) : clients
}

/**
 * Our graphql api has a primary "client" field, as well as a "clientsAllowed" field for additional clients.
 * The logic for this can be tricky as we need to ensure we return unique values (for example, if a particular
 * value is in both the client and clientsAllowed fields, logic based on counts will be incorrect unless the
 * dup is taken into account).
 *
 * @param user
 * @param sort Whether to sort on code or not, defaults to not sorted
 * @returns {String[]}
 */
export function allAllowedClientCodes (user, sort = false) {
  const clientCodes = Array.from(new Set([user.client.code, ...user.clientCodesAllowed]))
  return sort ? clientCodes.sort((a, b) => a.localeCompare(b)) : clientCodes
}

/**
 * Only returns the additional allowed clients, excluding the primary client. If the primary client is
 * also present in the additional allowedClients, then it is removed.
 *
 * @param user
 * @param sort Whether to sort on name or not, defaults to not sorted
 * @returns {Client[]}
 */
export function additionalAllowedClients (user, sort = false) {
  // Set's don't guarantee uniqueness for objects, they compare by reference equality, go by codes instead
  const clients = arrayUniqueBy(user.clientsAllowed, c => c.code).filter(client => client.code !== user.client.code)
  return sort ? clients.sort((a, b) => a.name.localeCompare(b.name)) : clients
}

/**
 * Only returns the additional allowed client codes, excluding the primary client code. If the primary client is
 * also present in the additional allowedClients, then it is removed.
 *
 * @param user
 * @param sort Whether to sort on code or not, defaults to not sorted
 * @returns {String[]}
 */
export function additionalAllowedClientCodes (user, sort = false) {
  const additional = new Set(user.clientCodesAllowed)
  if (additional.has(user.client.code)) {
    additional.delete(user.client.code)
  }
  const clientCodes = Array.from(additional)
  return sort ? clientCodes.sort((a, b) => a.localeCompare(b)) : clientCodes
}

/**
 * Given client codes, converts to clients. This is a bit of a hack, but avoids having to use an API lookup for
 * the client from the code -- the code has to be one of the codes visible by the passed in `user`. The value
 * returned will always be an array of clients, containing the `name`, `code`, and `missing` properties. If the
 * client is not a known client e.g., `missing` will be true.
 *
 * @param user The user, with the client and clientsAllowed properties set
 * @param codes The code, or array of codes to convert to clients
 * @returns {Client[]}
 */
export function codesToClients (user, codes) {
  const allClients = allAllowedClients(user)
  // this simply handles the case where a code doesn't map to a known client -- the only situation in which this should
  // occur is if the client has been deleted
  function clientOfCode (code) {
    const client = allClients.find(c => c.code === code)
    return client || { name: code, code, missing: true }
  }

  return Array.isArray(codes)
    ? codes.includes('*') ? allClients : codes.map(code => clientOfCode(code))
    : codes === '*' ? allClients : [clientOfCode(codes)]
}
