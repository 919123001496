export function firstNEllipsis (s, n) {
  if (n < 0) throw Error('firstNEllipsis requires n > 0')
  return s && s.length > n ? `${s.substring(0, Math.min(n, s.length))}…` : s
}

export function buf2hex (buffer) { // buffer is an ArrayBuffer
  return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('')
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
export function escapeRegExp (string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
