// Inspired by https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
const arraysAreEqual = (a1, a2) => {
  // If both arrays are falsy return true
  if (!a1 && !a2) { return true }

  // If one of the array only is falsy return false
  if ((!a1 && a2) || (a1 && !a2)) { return false }

  // compare lengths - can save a lot of time
  if (a1.length !== a2.length) { return false }

  for (let i = 0, l = a1.length; i < l; i++) {
    // Check if we have nested arrays
    if (a1[i] instanceof Array && a2[i] instanceof Array) {
      // recurse into the nested arrays
      if (!a1[i].equals(a2[i])) { return false }
    } else if (a1[i] !== a2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}

const arrayIsNullOrEmpty = a => {
  return !a || a.length === 0
}

const arrayUniqueBy = (array, keyFn) => [...new Map(array.map(item => [keyFn(item), item])).values()]

export { arraysAreEqual, arrayIsNullOrEmpty, arrayUniqueBy }
