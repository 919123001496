import React from 'react'
import AuthorizationContext from 'contexts/Common/AuthorizationContext'

const AuthorizationProvider = ({ children }) => {
  // #RolesAndPermissions
  const Permissions = {
    SEARCH: 'SEARCH',
    UPLOAD: 'UPLOAD',

    VIEW_ADMIN_PAGE: 'VIEW_ADMIN_PAGE',

    // VIEW_FILE_STATUSES: 'VIEW_FILE_STATUSES',
    MANAGE_FILES: 'MANAGE_FILES',
    // We can split this up in more granular permissions as needed down the road
    // view CmsUuid column, view sha256 column, view 'Indexed' status in status column, view Page Count column,
    // view Segmentation Method column, view Related Events column
    MANAGE_FILES_ADVANCED: 'MANAGE_FILES_ADVANCED',

    // VIEW_CONNECTORS: 'VIEW_CONNECTORS',
    MANAGE_CONNECTORS: 'MANAGE_CONNECTORS',

    // VIEW_CLIENTS: 'VIEW_CLIENTS',
    MANAGE_CLIENTS: 'MANAGE_CLIENTS',

    // VIEW_USERS: 'VIEW_USERS',
    MANAGE_USERS: 'MANAGE_USERS',
    // We can split this up in more granular permissions as needed down the road
    // Assign SuperAdmin role, edit/delete SuperAdmin users, enforce allowedEmailSuffixes, enable/disable users in form,
    // view Deleted users, view Env column, view Uuid column, view Enabled column
    MANAGE_USERS_ADVANCED: 'MANAGE_USERS_ADVANCED',

    // VIEW_DLQS: 'VIEW_DLQS',
    MANAGE_DLQS: 'MANAGE_DLQS'
  }

  const Roles = {
    User: 'User',
    Uploader: 'Uploader',
    Admin: 'ClientAdmin',
    SuperAdmin: 'Admin'
  }

  // I'm using "inheritance" between roles here as a shortcut but we must keep in mind it becomes very tedious to manage
  // once the number of permissions increases past a certain amount and it might be something we want to dop at some point
  const RolesDetails = new Map()
  RolesDetails.set(Roles.User, {
    label: 'User',
    permissions: [
      Permissions.SEARCH
    ]
  })
  RolesDetails.set(Roles.Uploader, {
    label: 'Uploader',
    permissions: [
      ...RolesDetails.get(Roles.User).permissions,
      Permissions.UPLOAD
    ]
  })
  RolesDetails.set(Roles.Admin, {
    label: 'Admin',
    permissions: [
      ...RolesDetails.get(Roles.Uploader).permissions,
      Permissions.VIEW_ADMIN_PAGE,
      Permissions.MANAGE_CONNECTORS,
      Permissions.MANAGE_FILES,
      Permissions.MANAGE_USERS
    ]
  })
  RolesDetails.set(Roles.SuperAdmin, {
    label: 'SuperAdmin',
    permissions: [
      ...RolesDetails.get(Roles.Admin).permissions,
      Permissions.MANAGE_FILES,
      Permissions.MANAGE_FILES_ADVANCED,
      Permissions.MANAGE_CLIENTS,
      Permissions.MANAGE_USERS,
      Permissions.MANAGE_USERS_ADVANCED,
      Permissions.MANAGE_DLQS
    ]
  })

  const contextValue = {
    Roles,
    Permissions,
    hasPermission: (permission, me) => me && me.roles.some(r => RolesDetails.get(r).permissions.includes(permission)),
    roleValueToLabel: role => RolesDetails.get(role).label
  }

  return (
    <AuthorizationContext.Provider value={contextValue}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export default AuthorizationProvider
