const getFoldedHits = (result, similarByUuid, showSimilar) => {
  return result ? result.hits.reduce((hits, hit) => {
    if (hit.similarToUuid) {
      hits = hits.map(h => {
        if (h.segment.uuid === hit.similarToUuid) {
          const primaryHit = Object.assign({}, h)
          primaryHit.similarCount = primaryHit.similarCount !== undefined ? primaryHit.similarCount + 1 : 1
          if (!showSimilar && similarByUuid[hit.similarToUuid]) {
            primaryHit.similarShown = true
          }
          return primaryHit
        } else {
          return h
        }
      })
      if (!showSimilar && similarByUuid[hit.similarToUuid]) {
        const secondaryHit = Object.assign({}, hit)
        secondaryHit.similarShown = true

        hits.push(secondaryHit)
      }
    } else {
      hits.push(hit)
    }

    return hits
  }, []) : []
}

const getHitFromSegmentId = (result, id) => {
  return result && id && result.hits.find(h => h.segment.uuid === id)
}

const indexToPos = idx => {
  return idx === -1 ? -1 : ++idx
}

const getHitPositionVisibleHitsById = (foldedHits, id) => {
  const idx = foldedHits.findIndex(h => h.id === id)
  return indexToPos(idx)
}

const getHitPositionUnderResultById = (result, id) => {
  const hit = result.hits.find(h => h.id === id)
  if (!hit.similarToUuid) { return 1 }

  const resultPos = getHitPositionAllHitsById(result, hit.similarToUuid)
  const hitPos = getHitPositionAllHitsById(result, id)

  return indexToPos(hitPos - resultPos)
}

const getHitPositionAllHitsById = (result, id) => {
  const idx = result.hits.findIndex(h => h.id === id)
  return indexToPos(idx)
}

const getResultPositionById = (result, id) => {
  let idx = -1
  result.hits.some(hit => {
    if (!hit.similarToUuid) { idx++ }
    return hit.segment.uuid === id
  })

  return indexToPos(idx)
}

export {
  getFoldedHits,
  getHitFromSegmentId,
  getHitPositionAllHitsById,
  getHitPositionUnderResultById,
  getHitPositionVisibleHitsById,
  getResultPositionById,
  indexToPos
}
