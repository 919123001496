import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import UploadIcon from '@material-ui/icons/CloudUpload'

import FilesUploaderContext from 'contexts/Common/FilesUploaderContext'

const useStyles = makeStyles(theme => ({
  processingErrors: {
    position: 'absolute',
    top: 2,
    left: 6,
    color: theme.palette.error.dark
  },
  processingErrorsHideUploadingErrors: {
    position: 'absolute',
    top: 1,
    left: 5,
    color: '#fff'
  },
  processingProgress: {
    position: 'absolute',
    top: 2,
    left: 6
  },
  processingProgressIndeterminate: {
    position: 'absolute',
    top: 3,
    left: 7
  },
  uploadingErrors: {
    position: 'absolute',
    top: 6,
    left: 10,
    color: theme.palette.error.main
  },
  uploadProgress: {
    position: 'absolute',
    top: 6,
    left: 10
  },
  uploadProgressIndeterminate: {
    position: 'absolute',
    top: 7,
    left: 11
  }
}))

const UploadMenuIcon = ({ selectedClient }) => {
  const baseValue = 0

  const classes = useStyles()
  const filesUploaderContext = useContext(FilesUploaderContext)

  const processing = filesUploaderContext.completedFilesPercent < 100

  return (
    <>
      <Fade in={processing} {...(processing ? { timeout: 0 } : { timeout: 3000 })} unmountOnExit>
        <div>
          <CircularProgress className={classes.processingProgressIndeterminate} variant='indeterminate' size={42} thickness={2} />
          <CircularProgress className={classes.processingProgress} variant='static' size={44} thickness={4} value={baseValue + ((100 - baseValue) * filesUploaderContext.completedFilesPercent / 100)} />
          {/* Failed uploads will not be processed so showing them as "failed" processing as far as the progress circle is concerned makes it less confusing to users */}
          <CircularProgress classes={{ root: classes.processingErrors }} variant='static' size={44} thickness={4} value={filesUploaderContext.processingErrorsPercent + filesUploaderContext.uploadingErrorsPercent} />
          <CircularProgress classes={{ root: classes.processingErrorsHideUploadingErrors }} variant='static' size={46} thickness={6} value={filesUploaderContext.uploadingErrorsPercent} />
          <CircularProgress className={classes.uploadProgressIndeterminate} color='secondary' variant='indeterminate' size={34} thickness={2} />
          <CircularProgress className={classes.uploadProgress} color='secondary' variant='static' size={36} thickness={4} value={baseValue + ((100 - baseValue) * filesUploaderContext.uploadedFilesPercent / 100)} />
          <CircularProgress classes={{ root: classes.uploadingErrors }} variant='static' size={36} thickness={4} value={filesUploaderContext.uploadingErrorsPercent} />
        </div>
      </Fade>
      <UploadIcon />
    </>
  )
}

export default UploadMenuIcon
