import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import DateRangeDialog from 'components/Common/DateRangeDialog'
import { dateDisplayFormat, dateTypeEnum } from 'common/config/Constants'

export default ({ className, dateType, dateFrom, dateTo, setDateFilter }) => {
  const dateTypeEnumToLabel = {
    0: 'Any Date',
    1: 'Past Month',
    2: 'Past 6 Months',
    3: 'Past Year',
    4: 'Custom Range'
  }

  const [showCustomRangeDialog, setShowCustomRangeDialog] = useState(false)

  const setDateType = (event) => {
    let newDateType = event.target.value
    let newDateFrom = dateFrom
    let newDateTo = dateTo

    if (newDateType === dateType) {
      if (newDateType === dateTypeEnum.CUSTOM_RANGE) {
        setShowCustomRangeDialog(true)
      }
      return
    }

    let showCustomRangeDialog = false
    switch (newDateType) {
      case dateTypeEnum.PAST_MONTH:
        newDateFrom = moment().subtract(1, 'M')
        newDateTo = null
        break
      case dateTypeEnum.PAST_6_MONTHS:
        newDateFrom = moment().subtract(6, 'M')
        newDateTo = null
        break
      case dateTypeEnum.PAST_YEAR:
        newDateFrom = moment().subtract(1, 'y')
        newDateTo = null
        break
      case dateTypeEnum.ANY:
        newDateFrom = null
        newDateTo = null
        break
      case dateTypeEnum.CUSTOM_RANGE:
        // Maintain current dateType until custom range is submitted (see setCustomRange())
        newDateType = dateType
        showCustomRangeDialog = true
        break
      default:
        break
    }

    setShowCustomRangeDialog(showCustomRangeDialog)
    setDateFilter(newDateType, newDateFrom, newDateTo)
  }

  const setCustomRange = (dateFrom, dateTo) => {
    const dateType = (dateFrom || dateTo) ? dateTypeEnum.CUSTOM_RANGE : dateTypeEnum.ANY

    setDateFilter(dateType, dateFrom, dateTo)
  }

  const getCustomRangeString = () => {
    if (dateFrom || dateTo) {
      const from = dateFrom ? dateFrom.format(dateDisplayFormat) : '...'
      const to = dateTo ? dateTo.format(dateDisplayFormat) : '...'
      return `${from} to ${to}`
    } else {
      return 'Custom Range'
    }
  }

  return (
    <>
      <FormControl className={className}>
        <Select
          value={dateType}
          onChange={setDateType}
          renderValue={selected => {
            switch (selected) {
              case dateTypeEnum.ANY:
                return <em>{dateTypeEnumToLabel[selected]}</em>
              case dateTypeEnum.CUSTOM_RANGE:
                return getCustomRangeString()
              default:
                return dateTypeEnumToLabel[selected]
            }
          }}
        >
          <MenuItem value={dateTypeEnum.ANY}><em>{dateTypeEnumToLabel[dateTypeEnum.ANY]}</em></MenuItem>
          <MenuItem value={dateTypeEnum.PAST_MONTH}>{dateTypeEnumToLabel[dateTypeEnum.PAST_MONTH]}</MenuItem>
          <MenuItem value={dateTypeEnum.PAST_6_MONTHS}>{dateTypeEnumToLabel[dateTypeEnum.PAST_6_MONTHS]}</MenuItem>
          <MenuItem value={dateTypeEnum.PAST_YEAR}>{dateTypeEnumToLabel[dateTypeEnum.PAST_YEAR]}</MenuItem>
          <Divider />
          <MenuItem value={dateTypeEnum.CUSTOM_RANGE}>{dateTypeEnumToLabel[dateTypeEnum.CUSTOM_RANGE]}</MenuItem>
        </Select>
      </FormControl>
      <DateRangeDialog
        open={showCustomRangeDialog}
        title='Customised Date Range'
        format={dateDisplayFormat}
        fromDate={dateFrom}
        toDate={dateTo}
        onClose={() => setShowCustomRangeDialog(false)}
        onAccept={setCustomRange}
      />
    </>
  )
}
