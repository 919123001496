import { FILETYPE_WORD, FILETYPE_POWERPOINT, FILETYPE_PDF } from 'common/proto'

const dateDisplayFormat = 'YYYY-MM-DD'

const dateTypeEnum = {
  ANY: 0, // Update searchParams default for 'd' if you change this
  PAST_MONTH: 1,
  PAST_6_MONTHS: 2,
  PAST_YEAR: 3,
  CUSTOM_RANGE: 4
}

const segmentTypeEnum = {
  ANY: 'ANY',
  TEXT: 'PARAGRAPH',
  TABLE: 'TABLE',
  SLIDE: 'SLIDE',
  CONTROL: 'CONTROL_TEXT'
}

const fileStatusesEnum = {
  YES: 'YES',
  NO: 'NO',
  FAILED: 'FAILED'
}

// Keep in sync with lib-proto com.redock.proto.common.FileTypes
// Make sure all are in lowercase
const acceptedTypes = [
  { ext: '.doc', mime: 'application/msword', type: FILETYPE_WORD },
  { ext: '.dot', mime: 'application/msword', type: FILETYPE_WORD },
  { ext: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', type: FILETYPE_WORD },
  { ext: '.dotx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', type: FILETYPE_WORD },
  { ext: '.docm', mime: 'application/vnd.ms-word.document.macroenabled.12', type: FILETYPE_WORD },
  { ext: '.dotm', mime: 'application/vnd.ms-word.template.macroenabled.12', type: FILETYPE_WORD },
  { ext: '.ppt', mime: 'application/vnd.ms-powerpoint', type: FILETYPE_POWERPOINT },
  { ext: '.pot', mime: 'application/vnd.ms-powerpoint', type: FILETYPE_POWERPOINT },
  { ext: '.pps', mime: 'application/vnd.ms-powerpoint', type: FILETYPE_POWERPOINT },
  { ext: '.ppa', mime: 'application/vnd.ms-powerpoint', type: FILETYPE_POWERPOINT },
  { ext: '.pptx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', type: FILETYPE_POWERPOINT },
  { ext: '.potx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.template', type: FILETYPE_POWERPOINT },
  { ext: '.ppsx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', type: FILETYPE_POWERPOINT },
  { ext: '.ppam', mime: 'application/vnd.ms-powerpoint.addin.macroenabled.12', type: FILETYPE_POWERPOINT },
  { ext: '.pptm', mime: 'application/vnd.ms-powerpoint.presentation.macroenabled.12', type: FILETYPE_POWERPOINT },
  { ext: '.potm', mime: 'application/vnd.ms-powerpoint.template.macroenabled.12', type: FILETYPE_POWERPOINT },
  { ext: '.ppsm', mime: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12', type: FILETYPE_PDF },
  { ext: '.pdf', mime: 'application/pdf', type: 'PDF' }
]

const acceptedMimeTypes = [...new Set(acceptedTypes.map(ft => ft.mime))]

const mimeTypeToFileType = mimeType => {
  if (!mimeType) return null
  const fileType = acceptedTypes.find(ft => ft.mime === mimeType.toLowerCase())
  if (fileType) { return fileType.type } else { return null }
}

export { dateDisplayFormat, dateTypeEnum, segmentTypeEnum, acceptedMimeTypes, fileStatusesEnum, acceptedTypes, mimeTypeToFileType }
