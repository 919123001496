import { FormattedMessage } from 'react-intl'
import React from 'react'

const extractErrorInfo = (err) => {
  if (!err) {
    return { code: null, errorId: null, userMessage: null, env: null }
  }

  // default to just extracting from the object directly
  let { code, errorId, userMessage, env } = err

  if (err && err.graphQLErrors) {
    if (err.graphQLErrors.length > 0) {
      const errorWithCode = err.graphQLErrors.find(e => e.extensions && e.extensions.code)
      if (errorWithCode) {
        code = errorWithCode.extensions.code
      }
      const errorWithErrorId = err.graphQLErrors.find(e => e.extensions && e.extensions.errorId)
      if (errorWithErrorId) {
        errorId = errorWithErrorId.extensions.errorId
      }
    }
  }

  // Retrieve the code from the networkError if it exists
  if (!code && err.networkError && err.networkError.result) {
    code = err.networkError.result.code
    env = err.networkError.result.env
  }

  // fallback to userMessage for now, if available (this will go away in the future, everything from the backend should be a code
  if (!code && err.graphQLErrors) {
    const errorWithUserMessage = err.graphQLErrors.find(e => e.extensions && e.extensions.userMessage)
    if (errorWithUserMessage) {
      userMessage = errorWithUserMessage.extensions.userMessage
    }
  }

  return { code, errorId, userMessage, env }
}

const errorToIntlId = (code, errorId) => {
  if (!code && errorId) code = 'unhandled-exception.errorid'
  else if (!code) code = 'unhandled-exception'

  return `error.${code}`
}

/**
 * An imperative way to get a formatted exception String. Pass in an `intl`, which can be obtained from props
 * by using the `injectIntl` HOC e.g. `import { injectIntl } from 'react-intl'` and
 * `export default injectIntl(MyComponent)`, or by using the hook.
 * @param intl
 * @param err
 * @returns {*}
 */
const formattedExceptionString = (intl, err) => {
  const { code, errorId, userMessage } = extractErrorInfo(err)
  return !code && userMessage ? userMessage : intl.formatMessage({ id: errorToIntlId(code, errorId) }, { errorId })
}

const FormattedException = ({ err, values, variant }) => {
  const { code, errorId, userMessage, env } = extractErrorInfo(err)
  return !code && userMessage ? userMessage : <FormattedErrorCode code={code} errorId={errorId} values={values} variant={variant || env} />
}

const FormattedErrorCode = ({ code, errorId, values, variant }) => {
  const id = errorToIntlId(code, errorId)
  const idVariant = variant ? `${id}.${variant}` : id

  // format the id, with variant (idVariant)
  // if the formatted message is the idVariant (i.e. the idVariant was not found), fallback to the non-variant id
  // if the formatted message is the id (i.e. the id was not found), fallback to the unhandled-exception code
  return (
    <FormattedMessage id={idVariant} values={{ errorId, ...values }}>
      {message => message === idVariant ? (
        <FormattedMessage id={id} values={{ errorId, ...values }}>
          {message => message === id ? <FormattedMessage id='error.unhandled-exception' /> : <div dangerouslySetInnerHTML={{ __html: message }} />}
        </FormattedMessage>
      ) : <div dangerouslySetInnerHTML={{ __html: message }} />}
    </FormattedMessage>
  )
}

export { FormattedException, FormattedErrorCode, extractErrorInfo, formattedExceptionString }
