import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  infiniteScrollEndMessage: {
    textAlign: 'left'
  }
}))

export default ({ progress, hasMore, loadMore, result, lastSearch }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  if (progress) {
    return (<Fade in><CircularProgress /></Fade>)
  } else if (hasMore) {
    return (
      <Fade in>
        <Grid item xs>
          <Button variant='outlined' onClick={loadMore}>
            More results
          </Button>
        </Grid>
      </Fade>
    )
  } else if (result) {
    return (
      <Fade in>
        <Grid item xs>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            <Grid item xs className={classes.infiniteScrollEndMessage}>
              {(result.hits.length || null) && (
                <>
                  <Typography>That's all the results we could find for <b>{lastSearch.query}</b></Typography>
                  <br />
                  <Typography> Still haven't found what you're looking for?</Typography>
                </>
              )}
              <ul>
                <li><Typography>Make sure that all words are spelled correctly.</Typography></li>
                <li><Typography>Try different keywords.</Typography></li>
                <li><Typography>Try more general keywords.</Typography></li>
                <li><Typography>Try fewer keywords.</Typography></li>
                <li><Typography>Try clearing your filters.</Typography></li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    )
  } else { return null }
}
