import { PublicClientApplication, LogLevel } from '@azure/msal-browser'
import { detect } from 'detect-browser'
import { LoginAccessDenied, LoginInProgress } from 'services/auth/Errors'

// https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/MSAL-basics

// v2 endpoint, tenant specific https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-protocols-oidc
const AUTHORITY = 'https://login.microsoftonline.com/redock.onmicrosoft.com'
const browser = detect()

// localStorage.setItem('msalDebugLogging', true)
const msalDebugLogging = localStorage.getItem('msalDebugLogging') || false

// Edge/IE11 support for auth, see
// https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser#1-issues-due-to-security-zones
const browserRequiresAuthStateInCookie = browser.name === 'ie' || browser.name === 'edge'
// Edge/IE11 workaround for auth popup not working properly unless login.microsoftonline.com is in the trusted zone, see
// https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser#1-issues-due-to-security-zones
const browserRequiresRedirectLogin = browser.name === 'ie' || browser.name === 'edge'

export default class MsalAuthService {
  constructor (clientId) {
    // default client id is for dev
    clientId = clientId || 'b990f4d0-e387-4985-b369-275ba8c5dd58'

    this.graphScopes = ['openid', 'profile', 'User.Read']
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-protected-web-api-app-registration#expose-an-api
    // not sure why, but entering a scope here like access_as_user fails, but leaving it blank seems to work
    this.customScopes = []
    this.loginScopes = { extraScopesToConsent: [...this.graphScopes, ...this.customScopes] }
    this.tokenScopes = { scopes: this.customScopes }
    // multiple accounts, not yet supported, we just pick the first one
    this.accountId = null
    this.logoutStarted = false

    this.app = new PublicClientApplication({
      auth: {
        clientId,
        authority: AUTHORITY,
        knownAuthorities: [],
        cloudDiscoveryMetadata: "",
        validateAuthority: true,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
        clientCapabilities: ["CP1"]
      },
      cache: {
        // allow multiple tabs to share auth, the default is sessionStorage
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: browserRequiresAuthStateInCookie,
        secureCookies: false
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii || !msalDebugLogging) {
              return
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message)
                return
              case LogLevel.Info:
                console.info(message)
                return
              case LogLevel.Verbose:
                console.debug(message)
                return
              case LogLevel.Warning:
                console.warn(message)
                return
              default:
                console.log(message)
                return
            }
          },
          piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
      }
    })

    this.app.handleRedirectPromise()
      .then(this.handleRedirectResponse)
      .catch(error => {
        if (error) {
          const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.'
          console.error(errorMessage)
          throw error
        }
      })
  }

  handleRedirectResponse = async (resp) => {
    if (msalDebugLogging) console.log('msal handleRedirectResponse resp=', resp)
    if (resp !== null) {
      this.accountId = resp.account.homeAccountId
    } else {
      this.updateAccountId()
    }
  }

  login = async () => {
    if (msalDebugLogging) console.log('msal login')
    let tokenResponse
    try {
      // popup doesn't work properly in IE, use redirect unless login.microsoftonline.com is in the trusted zone
      // see https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser#1-issues-due-to-security-zones
      if (browserRequiresRedirectLogin) {
        if (msalDebugLogging) console.log('Doing login with redirect to workaround browser issues')
      }
      tokenResponse = await (browserRequiresRedirectLogin
        ? this.app.loginRedirect(this.loginScopes)
        : this.app.loginPopup(this.loginScopes)
      )
    } catch (err) {
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/src/error/ClientAuthError.ts
      console.log('errorCode', err.errorCode)
      if (err.errorCode === 'login_progress_error' || err.errorCode === 'interaction_in_progress') {
        throw new LoginInProgress()
      } else if (err.errorCode === 'user_cancelled') {
        throw new LoginAccessDenied()
      } else if (err.errorCode === 'popup_window_error') {
        // popups blocked, try redirect
        if (msalDebugLogging) console.log('Doing login with redirect to workaround popup window blocking')
        tokenResponse = await this.app.loginRedirect(this.loginScopes)
      } else {
        console.error(err.name, err.errorCode, err.errorMessage, err)
        throw err
      }
    }
    if (msalDebugLogging) console.log('msal login idToken=', tokenResponse)
    return tokenResponse
  }

  updateAccountId = (currentAccounts) => {
    if (msalDebugLogging) console.log('msal updateAccountId')
    currentAccounts = currentAccounts || this.app.getAllAccounts()
    if (!currentAccounts || currentAccounts.length < 1) {
      this.accountId = null
    } else if (currentAccounts.length > 1) {
      console.warn('msal multiple accounts match, picking the first one')
      this.accountId = currentAccounts[0].homeAccountId
    } else if (currentAccounts.length === 1) {
      this.accountId = currentAccounts[0].homeAccountId
    }
    if (msalDebugLogging) console.log('msal updateAccountId result accountId=', this.accountId)
  }

  getUser = () => {
    const currentAccounts = this.app.getAllAccounts()
    if (msalDebugLogging) console.log('msal getUser currentAccounts=', currentAccounts)
    if (!currentAccounts || currentAccounts.length === 0) {
      // No user signed in
    } else if (currentAccounts.length > 1) {
      // More than one user signed in, find desired user with getAccountByUsername(username)
      // this.app.getAccountByUsername()
      return currentAccounts[0]
    } else {
      return currentAccounts[0]
    }
  }

  getToken = async () => {
    if (this.logoutStarted) {
      if (msalDebugLogging) console.log('msal getToken ignoring request, logout in progress')
      return null
    }
    if (!this.accountId) {
      this.updateAccountId()
    }
    if (msalDebugLogging) console.log('msal getToken accountId=', this.accountId)
    const currentAccount = this.accountId ? this.app.getAccountByHomeId(this.accountId) : null
    const tokenRequest = { ...this.tokenScopes, account: currentAccount }
    let tokenResponse
    try {
      tokenResponse = await this.app.acquireTokenSilent(tokenRequest)
    } catch (err) {
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/src/error/InteractionRequiredAuthError.ts
      if (err.name === 'InteractionRequiredAuthError') {
        try {
          tokenResponse = await this.login()
        } catch (e) {
          console.log('msal login while acquire token error', e)
          // re-throw so the caller doesn't get into a loop
          throw e
        }
      } else {
        console.log('msal acquire token error', err)
      }
    }
    return tokenResponse ? tokenResponse.idToken : null
  }

  logout = () => {
    let endSessionRequest = { postLogoutRedirectUri: window.location.origin }
    this.logoutStarted = true
    if (this.accountId) {
      const currentAccount = this.app.getAccountByHomeId(this.accountId)
      if (currentAccount) {
        const logoutHint = currentAccount.idTokenClaims.login_hint
        endSessionRequest = { ...endSessionRequest, account: currentAccount, logoutHint }
      }
    }
    if (msalDebugLogging) console.log('msal logoutRedirect with request=', endSessionRequest)
    this.accountId = null
    this.app.logoutRedirect(endSessionRequest)
  }

  idp = () => 'aadv2'

  additionalAuthHeaders = () => ({
    'X-RedockAuthMode': this.idp()
  })
}
