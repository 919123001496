import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

// The content inside the <Fragment> has been generated using https://svg2jsx.herokuapp.com/
// Note that in some instances you might need to add quotes around styles values manually
const BaseLogo = createSvgIcon(
  <>
    <radialGradient
      id='a'
      cx='-254.82'
      cy='705.836'
      r='82.978'
      gradientTransform='scale(3.2644) rotate(30 1174.792 966.99)'
      gradientUnits='userSpaceOnUse'
    >
      <stop offset='0' stopColor='#4387fd' />
      <stop offset='0.65' stopColor='#3078f0' />
      <stop offset='0.91' stopColor='#2b72ea' />
      <stop offset='1' stopColor='#286ee6' />
    </radialGradient>
    <radialGradient
      id='b'
      cx='-254.817'
      cy='705.837'
      r='82.973'
      gradientTransform='scale(3.2644) rotate(30 1174.792 966.99)'
      gradientUnits='userSpaceOnUse'
    >
      <stop offset='0' stopColor='#ffd24d' />
      <stop offset='1' stopColor='#f6c338' />
    </radialGradient>
    <path fill='#0da960' d='M24.2 120.4L0 78.5 45.3 0l24.2 41.9z' />
    <path fill='url(#a)' d='M24.2 120.4l24.2-41.9H139l-24.2 41.9z' />
    <path fill='url(#b)' d='M139 78.5H90.6L45.3 0h48.4z' />
    <path fill='#2d6fdd' d='M69.5 78.5H48.4l10.5-18.3-34.7 60.2z' />
    <path fill='#e5b93c' d='M90.6 78.5H139L80.1 60.2z' />
    <path fill='#0c9b57' d='M58.9 60.2l10.6-18.3L45.3 0z' />
  </>
  , 'LogoGoogleDrive'
)

export default props => (<BaseLogo viewBox='0 0 139 120.4' {...props} />)
