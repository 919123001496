import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/WarningOutlined'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useWindowHeight } from '@react-hook/window-size'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useStorageState } from 'react-storage-hooks'
import VirtualList from 'react-tiny-virtual-list'

import { removeTypename } from 'common/utilities/graphql'
import FilesUploaderContext from 'contexts/Common/FilesUploaderContext'
import LogoPDF from 'icons/LogoPDF'
import LogoPowerpoint from 'icons/LogoPowerpoint'
import LogoWord from 'icons/LogoWord'
import DlqEventDetail from 'scenes/admin/DlqEventDetail'
import { theme } from 'services/theme/reDockTheme'
import FileUploadStatusCard from 'scenes/upload/FileUploadStatusCard'
import UploadDrop from 'scenes/upload/UploadDrop'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  },
  message: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main
  },
  messageRippler: {
    animation: `$color-change 1000ms ${theme.transitions.easing.easeOut} infinite`
  },
  '@keyframes color-change': {
    '0%': {
      color: theme.palette.error.contrastText
    },
    '50%': {
      color: theme.palette.error.contrastText
    },
    '100%': {
      color: theme.palette.error.main
    }
  },
  files: {
    display: 'flex',
    flexDirection: 'column'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const Upload = ({ me, selectedClient, constants }) => {
  const SESSION_STORAGE_HIDE_COMPLETED = 'Upload-hide-completed'

  const classes = useStyles()
  const windowHeight = useWindowHeight()
  const filesUploaderContext = useContext(FilesUploaderContext)

  const [dlqEventKey, setDlqEventKey] = useState(null)
  const [hideCompleted, setHideCompleted, hideCompletedError] = useStorageState(window.sessionStorage, SESSION_STORAGE_HIDE_COMPLETED, false)

  if (hideCompletedError) { console.error('Cannot write `hideCompleted` state to session storage') }

  // Sort by time descending
  const sortedFiles = [...(filesUploaderContext.files)].sort((a, b) => b.pos - a.pos)
  const activeFiles = sortedFiles.filter(f => !f.completed)

  return (
    <div className={classes.root}>
      <DlqEventDetail dlqEventKey={removeTypename(dlqEventKey)} onClose={() => setDlqEventKey(null)} />
      <div style={{ display: dlqEventKey ? 'none' : 'block', flexGrow: 0 }}>
        {me.client.code !== selectedClient
          ? (
            <Paper className={classNames(classes.margin, classes.message)} elevation={16}>
              <Typography>
                <WarningIcon className={classNames(classes.messageRippler)} fontSize='large' style={{ verticalAlign: 'middle' }}>WARNING!</WarningIcon>
                <>
                  Uploading to <strong style={{ fontSize: 'x-large' }}>{selectedClient}</strong>
                </>
                <WarningIcon className={classNames(classes.messageRippler)} fontSize='large' style={{ verticalAlign: 'middle', animationDelay: '500ms' }}>WARNING!</WarningIcon>
              </Typography>
            </Paper>
          ) : null}
        <UploadDrop selectedClient={selectedClient}>
          <div style={{ textAlign: 'center' }}>
            <Typography variant='button' color='textSecondary'>Drop your files and/or folders here.</Typography>
            <br />
            <Typography variant='button' color='textSecondary'>Accepted files formats are Word Documents, PowerPoint Presentations and PDF files</Typography>
            <br />
            <Typography variant='button' color='textSecondary'>You can navigate to other parts of the reDock site while your files are being uploaded</Typography>
            <div style={{ paddingTop: 0 }}>
              <LogoWord style={{ fontSize: '48px', marginRight: theme.spacing(2) }} />
              <LogoPowerpoint style={{ fontSize: '48px', marginRight: theme.spacing(2) }} />
              {/* PDF uses a slightly smaller fontSize to make the icon "fit" better with the office icons */}
              <LogoPDF style={{ fontSize: '44px' }} />
            </div>
          </div>
        </UploadDrop>
        <div className={classes.files}>
          <div className={classes.controls}>
            <FormControlLabel
              control={<Checkbox checked={hideCompleted} onChange={() => setHideCompleted(prev => !prev)} color='primary' />}
              label='Hide Completed'
            />
          </div>
          <VirtualList
            width='100%'
            height={windowHeight - 302}
            overscanCount={15}
            itemCount={hideCompleted ? activeFiles.length : sortedFiles.length}
            itemSize={index => (hideCompleted ? activeFiles[index] : sortedFiles[index]).showDetails ? 158 : 52}
            renderItem={({ index, style }) =>
              <FileUploadStatusCard
                style={style}
                key={(hideCompleted ? activeFiles[index] : sortedFiles[index]).pos}
                me={me}
                file={hideCompleted ? activeFiles[index] : sortedFiles[index]}
                toggleShowDetails={filesUploaderContext.toggleShowDetails}
                client={selectedClient}
                setDlqEventKey={setDlqEventKey}
                hideCompleted={hideCompleted}
              />}
          />
        </div>
      </div>
    </div>
  )
}

export default Upload
