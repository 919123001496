import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { segmentTypeEnum } from 'common/config/Constants'

export default ({ className, segmentTypes, setSegmentTypes }) => {
  const segmentTypeEnumToLabel = {
    ANY: 'Any Type',
    PARAGRAPH: 'Text',
    TABLE: 'Table',
    SLIDE: 'Slide',
    CONTROL_TEXT: 'Control'
  }

  return (
    <FormControl className={className}>
      <Select
        multiple
        displayEmpty
        value={segmentTypes}
        onChange={setSegmentTypes}
        renderValue={selected => {
          if (selected.length === 0) {
            return <em>Any Type</em>
          }

          return selected.map(s => segmentTypeEnumToLabel[s]).join(', ')
        }}
      >
        <MenuItem value=''><em>Any Type</em></MenuItem>
        <MenuItem value={segmentTypeEnum.TEXT}><em>{segmentTypeEnumToLabel[segmentTypeEnum.TEXT]}</em></MenuItem>
        <MenuItem value={segmentTypeEnum.TABLE}>{segmentTypeEnumToLabel[segmentTypeEnum.TABLE]}</MenuItem>
        <MenuItem value={segmentTypeEnum.CONTROL}>{segmentTypeEnumToLabel[segmentTypeEnum.CONTROL]}</MenuItem>
        <MenuItem value={segmentTypeEnum.SLIDE}>{segmentTypeEnumToLabel[segmentTypeEnum.SLIDE]}</MenuItem>
      </Select>
    </FormControl>
  )
}
