import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

// The content inside the <Fragment> has been generated using https://svg2jsx.herokuapp.com/
// Note that in some instances you might need to add quotes around styles values manually
const BaseLogo = createSvgIcon(
  <>
    <circle cx='14.806' cy='9.5' r='9.5' fill='#036c70' />
    <circle cx='23.681' cy='17.875' r='8.125' fill='#1a9ba1' />
    <circle cx='15.806' cy='25.5' r='6.5' fill='#37c6d0' />
    <path
      d='M16.473 7H5.64a9.506 9.506 0 009.167 12c.277 0 .551-.013.823-.036l.005.038A6.5 6.5 0 009.306 25.5q0 .252.02.5h7.147a1.337 1.337 0 001.333-1.333V8.333A1.337 1.337 0 0016.473 7z'
      opacity='0.1'
    />
    <path
      d='M15.473 8H5.423a9.505 9.505 0 009.383 11c.277 0 .551-.013.823-.036l.005.038A6.505 6.505 0 009.48 27h5.993a1.337 1.337 0 001.333-1.333V9.333A1.337 1.337 0 0015.473 8z'
      opacity='0.2'
    />
    <path
      d='M15.473 8H5.423a9.505 9.505 0 009.383 11c.277 0 .551-.013.823-.036l.005.038A6.5 6.5 0 009.324 25h6.15a1.337 1.337 0 001.332-1.333V9.333A1.337 1.337 0 0015.473 8z'
      opacity='0.2'
    />
    <path
      d='M14.473 8h-9.05a9.505 9.505 0 009.383 11c.277 0 .551-.013.823-.036l.005.038A6.5 6.5 0 009.324 25h5.15a1.337 1.337 0 001.332-1.333V9.333A1.337 1.337 0 0014.473 8z'
      opacity='0.2'
    />
    <path
      fill='#03787c'
      d='M1.14 8h13.333a1.333 1.333 0 011.333 1.333v13.334A1.333 1.333 0 0114.473 24H1.14a1.333 1.333 0 01-1.333-1.333V9.333A1.333 1.333 0 011.14 8z'
    />
    <path
      fill='#fff'
      d='M5.476 15.825a2.645 2.645 0 01-.822-.87 2.361 2.361 0 01-.287-1.19 2.29 2.29 0 01.533-1.541 3.142 3.142 0 011.416-.924 5.982 5.982 0 011.935-.3 7.354 7.354 0 012.55.357v1.8a3.986 3.986 0 00-1.154-.471 5.596 5.596 0 00-1.349-.162 2.926 2.926 0 00-1.386.293.91.91 0 00-.549.833.844.844 0 00.233.59 2.122 2.122 0 00.627.448q.394.196 1.176.52a1.232 1.232 0 01.17.067 9.697 9.697 0 011.482.732 2.654 2.654 0 01.877.883 2.558 2.558 0 01.317 1.332 2.48 2.48 0 01-.499 1.605 2.789 2.789 0 01-1.335.896A6.049 6.049 0 017.51 21a10.028 10.028 0 01-1.722-.142 5.912 5.912 0 01-1.4-.404v-1.902a4.5 4.5 0 001.416.675 5.513 5.513 0 001.558.25 2.68 2.68 0 001.413-.3.947.947 0 00.475-.847.904.904 0 00-.266-.648 2.704 2.704 0 00-.735-.512q-.469-.236-1.386-.62a7.86 7.86 0 01-1.386-.725z'
    />
    <path fill='none' d='M-.194 0h32v32h-32z' />
  </>
  , 'LogoSharePointOnline'
)

export default props => (<BaseLogo viewBox='-0.13 0 31.873 32' {...props} />)
