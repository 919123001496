import gql from 'graphql-tag'

import { FILETYPE_PDF, FILETYPE_POWERPOINT, FILETYPE_WORD } from 'common/proto'

const downloadFile = async (client, fileName, fileType, uri, inline, convert, onError) => {
  // TODO this assumes Office is installed, this will likely need to be a user preference that can be turned off (or on)
  let fileUriScheme = ''
  let convertExtension = ''

  switch (fileType) {
    case FILETYPE_WORD:
      fileUriScheme = convert ? '' : 'ms-word:ofe|u|'
      convertExtension = '.docx'
      break
    case FILETYPE_POWERPOINT:
      fileUriScheme = convert ? '' : 'ms-powerpoint:ofe|u|'
      convertExtension = '.pptx'
      break
    case FILETYPE_PDF:
      convertExtension = '.pdf'
      break
    default:
      fileUriScheme = ''
  }

  if (convert && fileName.includes(convertExtension) === false) {
    fileName += convertExtension
  }

  let linkResult
  try {
    linkResult = (await client.query({
      query: PublicUrlQuery,
      variables: {
        uri: uri,
        fileName: fileName,
        disposition: inline ? 'inline' : null
      },
      fetchPolicy: 'network-only'
    })).data.v2Api.publicUrl
  } catch (err) {
    console.log(err)
    onError && onError(err)
    return null
  }

  window.open(`${fileUriScheme}${linkResult.link}`, inline && !convert ? '_blank' : '_self')
}

const userDownloadedFile = async (client, selectedClient, userSearchedEventId, contextViewLaunchedEventId, location, action, fileName, fileType, cmsId, sha256, docId) => {
  setTimeout(async _ => {
    try {
      await client.mutate({
        mutation: UserDownloadedFileMutation,
        variables: {
          input: {
            client: selectedClient,
            userSearchedEventId: userSearchedEventId,
            fileType: fileType,
            fileName: fileName,
            location: location,
            action: action,
            contextViewLaunchedEventId: contextViewLaunchedEventId,
            cmsId: cmsId,
            sha256: sha256,
            docId: docId
          }
        }
      })
    } catch (err) {
      console.error('Error doing UserCopiedMutation, ignoring', err)
    }
  }, 0)
}

const UserDownloadedFileMutation = gql`
  mutation userDownloadedFile($input: UserDownloadedFileInput) {
    v2Api {
      userDownloadedFile(input: $input) {
        id
      }
    }
  }
`

const PublicUrlQuery = gql`
  query publicUrl($uri: String!, $fileName: String, $disposition: Disposition) {
    v2Api {
      publicUrl(uri: $uri, fileName: $fileName, disposition: $disposition) {
        link
      }
    }
  }
`

export { downloadFile, userDownloadedFile }
