import errorsEn from './errors-en.json'
import messagesEn from './messages-en.json'

/**
 * Converts our json translations file from the messageDescriptor format to the object
 * required by IntlProvider. See
 * https://github.com/formatjs/react-intl/blob/master/docs/API.md#message-descriptor and
 * https://formatjs.io/guides/message-syntax/ for the syntax of the contained messages.
 * @param jsonArray
 * @returns {*}
 */
const readJson = jsonArray => jsonArray.reduce((collection, descriptor) => {
  collection[descriptor.id] = descriptor.defaultMessage
  return collection
}, {})

const en = { ...readJson(errorsEn), ...readJson(messagesEn) }

export { en }
