import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DatePicker from 'react-datepicker'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import moment from 'moment'

const styles = theme => ({
  datePickerGrid: {
    padding: theme.spacing(1),
    flexGrow: 0
  },
  inputGrid: {
    padding: theme.spacing(1)
  },
  inputRoot: {
    background: 'white',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid black'
  },
  input: {
    paddingLeft: theme.spacing(1)
  },
  inputError: {
    background: theme.palette.error.light,
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText
  },
  focused: {
    background: theme.palette.grey[200]
  },
  goButton: {
    float: 'right',
    margin: theme.spacing(1)
  },
  adornmentIconColorSecondary: {
    color: theme.palette.error.contrastText
  }
})

const FocusedDateEnum = {
  FROM: 0,
  TO: 1
}

// Uses/Expects Momment objects to represent the dates being sent in and the dates being returned
const DateRangeDialog = ({ open, onClose, onAccept, classes, title, fromDate, toDate, format }) => {
  const displayFormat = format || 'YYYY-MM-DD' // See https://momentjs.com/docs/#/displaying/format/
  const dateFormatRegex = /\d{4}-[01]\d-([0123]\d)/

  const [from, setFrom] = useState(fromDate) // Moment
  const [to, setTo] = useState(toDate) // Moment
  const [fromString, setFromString] = useState(fromDate ? fromDate.format(displayFormat) : '')
  const [toString, setToString] = useState(toDate ? toDate.format(displayFormat) : '')
  const [focused, setFocused] = useState(FocusedDateEnum.FROM)

  const fromInputRef = useCallback(node => {
    if (node !== null) {
      node.focus()
    }
  }, [])

  const toInputRef = useRef()

  useEffect(() => {
    if (toInputRef && toInputRef.current && from) {
      toInputRef.current.focus()
    }
  }, [from])

  const minDate = focused === FocusedDateEnum.TO ? from && from.toDate() : null
  const maxDate = focused === FocusedDateEnum.FROM ? to && to.toDate() : null

  // This is a bit redundant but the logic for "valid" dates is much cleaner than the logic for "invalid" dates
  // so this makes the code easier to follow IMO
  const fromValid = (!from && !fromString) || (dateFormatRegex.test(fromString) && (!to || from <= to))
  const fromError = !fromValid
  const toValid = (!to && !toString) || (dateFormatRegex.test(toString) && (!from || to >= from))
  const toError = !toValid

  const selected = focused === FocusedDateEnum.FROM ? from && from.toDate() : to && to.toDate()

  const handleChange = date => {
    const dateAsMoment = moment(date)
    if (focused === FocusedDateEnum.FROM) {
      setFrom(dateAsMoment)
      setFromString(dateAsMoment.format(displayFormat))
    } else {
      setTo(dateAsMoment)
      setToString(dateAsMoment.format(displayFormat))
    }
  }

  const handleInputChange = ev => {
    const value = ev.target.value
    if (focused === FocusedDateEnum.FROM) {
      setFromString(value)
      setFrom(dateFormatRegex.test(value) ? moment(value, displayFormat, true) : '')
    } else {
      setToString(ev.target.value)
      setTo(dateFormatRegex.test(value) ? moment(value, displayFormat, true) : '')
    }
  }

  const onGo = () => {
    onAccept && onAccept(from, to)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title || 'Choose a Date Range'}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs>
            <Grid container alignItems='center'>
              <Grid item xs={3}>
                <InputLabel>From</InputLabel>
              </Grid>
              <Grid item xs className={classnames(classes.inputGrid, (focused === FocusedDateEnum.FROM ? classes.focused : ''))}>
                <Input
                  disableUnderline
                  placeholder={displayFormat}
                  inputRef={fromInputRef}
                  value={fromString}
                  onChange={handleInputChange}
                  error={fromError}
                  onFocus={() => setFocused(FocusedDateEnum.FROM)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.input,
                    error: classes.inputError
                  }}
                  endAdornment={
                    <InputAdornment position='end' style={{ visibility: (fromString ? 'visible' : 'hidden') }}>
                      <IconButton
                        classes={{
                          colorSecondary: classes.adornmentIconColorSecondary
                        }}
                        color={fromError ? 'secondary' : 'primary'}
                        onClick={() => {
                          setFrom('')
                          setFromString('')
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems='center'>
              <Grid item xs={3}>
                <InputLabel>To</InputLabel>
              </Grid>
              <Grid item xs className={classnames(classes.inputGrid, (focused === FocusedDateEnum.TO ? classes.focused : ''))}>
                <Input
                  disableUnderline
                  placeholder={displayFormat}
                  inputRef={toInputRef}
                  value={toString}
                  onChange={handleInputChange}
                  error={toError}
                  onFocus={() => setFocused(FocusedDateEnum.TO)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.input,
                    error: classes.inputError
                  }}
                  endAdornment={
                    <InputAdornment position='end' style={{ visibility: (toString ? 'visible' : 'hidden') }}>
                      <IconButton
                        classes={{
                          colorSecondary: classes.adornmentIconColorSecondary
                        }}
                        color={toError ? 'secondary' : 'primary'}
                        onClick={() => {
                          setTo('')
                          setToString('')
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Button className={classes.goButton} variant='contained' color='primary' onClick={onGo}>Go</Button>
          </Grid>
          <Grid item xs className={classnames(classes.datePickerGrid, classes.focused)}>
            <DatePicker
              key={focused} // Ensures the component is recreated each time the focus changes to open the calendar to the currently selected date
              inline
              isClearable
              fixedHeight
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              todayButton='Today'
              openToDate={selected}
              selected={selected}
              onChange={handleChange}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(DateRangeDialog)
