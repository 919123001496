import React, { Component } from 'react'
import { reDockContent } from 'services/theme/reDockTheme'
import { withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import classnames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox'

const styles = theme => ({
  active: {
    backgroundColor: theme.primarySelectionBackground
  },
  listItem: {
    listStyleType: 'none'
  },
  listContainer: {
    margin: 0,
    padding: 0
  },
  card: {
    boxSizing: 'border-box',
    width: reDockContent.pageWidth,
    padding: '8px 0',
    margin: '8px 0',
    position: 'relative',
    minHeight: 48 // Because of checkbox
  },
  cardContent: {
    paddingTop: 0,
    paddingRight: reDockContent.margins.default().right,
    paddingBottom: 0,
    paddingLeft: reDockContent.margins.default().left,
    position: 'relative',
    '&:last-child': {
      paddingBottom: 0
    },
    '& img': {
      maxWidth: reDockContent.pageWidth - reDockContent.margins.default().left - reDockContent.margins.default().right
    },
    '& > h1:first-child': {
      marginTop: '8px !important',
      marginBottom: '8px !important'
    },
    '& > h2:first-child': {
      marginTop: '8px !important',
      marginBottom: '8px !important'
    },
    '& > h3:first-child': {
      marginTop: '8px !important',
      marginBottom: '8px !important'
    },
    '& > h4:first-child': {
      marginTop: '8px !important',
      marginBottom: '8px !important'
    },
    '& > h5:first-child': {
      marginTop: '8px !important',
      marginBottom: '8px !important'
    },
    '& > h6:first-child': {
      marginTop: '8px !important',
      marginBottom: '8px !important'
    }
  },
  checkbox: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  checkboxHeading: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    left: 0
  }
})

class ContextSegment extends Component {
  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return this.props.selected !== nextProps.selected
  }

  render () {
    const { segment, selected, updateSelection, classes, activeSegmentId, activeSegmentRef } = this.props

    const cardClass = selected ? classnames(classes.card, classes.active) : classes.card

    return (
      <li key='body' className={classes.listItem} ref={activeSegmentId === segment.uuid ? activeSegmentRef : null}>
        {segment.html &&
          <Card className={cardClass} square elevation={segment.type === 'HEADING' ? 0 : 4}>
            <CardContent dangerouslySetInnerHTML={{ __html: segment.html }} classes={{ root: classes.cardContent }} />
            <Checkbox
              checked={selected}
              className={segment.type === 'HEADING' ? classes.checkboxHeading : classes.checkbox}
              onClick={() => updateSelection(segment, !selected)}
            />
          </Card>}
      </li>
    )
  }
}

export default withStyles(styles)(ContextSegment)
