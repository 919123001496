import React, { Fragment, useState } from 'react'
import gql from 'graphql-tag'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import IconButton from '@material-ui/core/IconButton'
import SourceTypeIcon from 'components/Common/SourceTypeIcon'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation } from '@apollo/react-hooks'
import useConfirmationDialog from 'hooks/Common/useConfirmationDialog'
import { useSnackbar } from 'notistack'

import { FormattedException, FormattedErrorCode } from 'common/graphqlclient/ErrorHandler'
import CommonGraphqlFragments from 'common/graphqlclient/Fragments'
import { instantToDateTimeString } from 'common/utilities/dates'
import { mimeTypeToFileType } from 'common/config/Constants'
import { extractDlqEventKey, setDlqEventKey } from 'common/utilities/dlqUtilities'
import AnchorButton from 'components/Common/AnchorButton'
import DataTable from 'components/Common/DataTable'
import FileTypeIcon from 'components/Common/FileTypeIcon'
import DlqEventDetail from './DlqEventDetail'
import { theme } from 'services/theme/reDockTheme'
import RelatedEventsTable from 'components/FileStatuses/RelatedEventsTable'
import useAuthorization from 'hooks/Common/useAuthorization'

const PATHNAME = '/admin/file-statuses'

const styles = theme => ({
  item: {
    padding: theme.spacing(1)
  }
})

const RowDataIndexes = {
  RELATED_EVENTS: 0,
  CLIENT: 1,
  CMS_UUID: 2,
  SHA_256: 3,
  FILENAME: 4,
  SOURCE_TYPE: 5,
  PATH: 6,
  STATUSES: 7,
  CREATED_AT: 8,
  MODIFIED_AT: 9,
  MIME_TYPE: 10,
  PAGE_COUNT: 11,
  SEGMENTATION_METHODS: 12,
  SEGMENTS_COUNT: 13,
  API_ERROR_CODE: 14,
  DLQ_EVENTS_KEYS: 15
}

const columnToAttribute = {
  Client: 'client',
  Filename: 'fileName',
  Source: 'sourceType',
  Path: 'path',
  'Created At': 'createdAt',
  'Modified At': 'modifiedAt',
  'Mime Type': 'mimeType',
  'Page Count': 'pageCount',
  'Segmentation Method': 'segmentationMethod',
  'Segment Count': 'segmentsCount',
  Error: 'apiErrorCode'
}

const ProgressGrid = ({ statuses, deleted }) => {
  if (deleted) {
    return <span>DELETED</span>
  }

  return (
    <div style={{ display: 'flex', alignItems: 'stretch', border: '1px solid', borderColor: theme.palette.divider, borderRadius: theme.shape.borderRadius, overflow: 'hidden', width: 'fit-content' }}>
      {statuses.map((s, i) => {
        let icon = <RadioButtonUncheckedIcon fontSize='small' style={{ color: theme.palette.divider }} />
        if (s.status === 'YES') {
          icon = <CheckCircleOutlineIcon fontSize='small' style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} />
        } else if (s.status === 'FAILED') {
          icon = <ErrorOutlineIcon fontSize='small' style={{ backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText }} />
        }
        return (
          <Fragment key={s.name}>
            <Tooltip title={`${s.name}: ${s.status}`} style={{ width: 20 }}>
              {icon}
            </Tooltip>
            {i < statuses.length - 1 ? <Divider orientation='vertical' style={{ width: 1, height: 20 }} /> : null}
          </Fragment>
        )
      })}
    </div>
  )
}

const FileStatuses = ({ me, history, classes, selectedClient, location }) => {
  const { showConfirmationDialog } = useConfirmationDialog()
  const { enqueueSnackbar } = useSnackbar()
  const { Permissions, hasPermission } = useAuthorization()

  const [selectedRows, setSelectedRows] = useState([])
  const [sortAttribute, setSortAtribute] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)
  const [includeRelatedSegmentEvents, setIncludeRelatedSegmentEvents] = useState(false)
  const [withDlqsOnly, setWithDlqsOnly] = useState(false)
  const [rawData, setRawData] = useState(null)
  const [refetch, setRefetch] = useState(null)

  // Configure the mui-table  (https://github.com/gregnb/mui-datatables)
  const getColumns = () => [
    {
      name: 'File Events',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) { return null }
          return (
            <div>
              {value[0]
                ? <RelatedEventsTable me={me} events={value[0]} title='File Events' history={history} pathname={PATHNAME} setSelectedRows={setSelectedRows} refetch={refetch} />
                : null}
              {value[1]
                ? <Box mt={1}><RelatedEventsTable me={me} events={value[1]} title='Segment Events' history={history} pathname={PATHNAME} setSelectedRows={setSelectedRows} refetch={refetch} /></Box>
                : null}
            </div>
          )
        },
        display: false
      }
    },
    {
      name: 'Client',
      options: {
        sortDirection: sortAttribute === 'client' ? sortDirection : 'none',
        display: me.clientsAllowed.indexOf('*') > 0 || me.clientsAllowed.length > 1
      }
    },
    {
      name: 'CMS Uuid',
      options: {
        sort: false,
        display: hasPermission(Permissions.MANAGE_FILES_ADVANCED, me),
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          return {
            style: {
              wordBreak: 'break-word'
            }
          }
        }
      }
    },
    {
      name: 'sha256 Hash',
      options: {
        sort: false,
        display: hasPermission(Permissions.MANAGE_FILES_ADVANCED, me),
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          return {
            style: {
              wordBreak: 'break-word'
            }
          }
        }
      }
    },
    {
      name: 'Filename',
      options: {
        sortDirection: sortAttribute === 'fileName' ? sortDirection : 'none',
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          return {
            style: {
              wordBreak: 'normal'
            }
          }
        }
      }
    },
    {
      name: 'Source',
      options: {
        sortDirection: sortAttribute === 'source' ? sortDirection : 'none',
        setCellProps: (cellValue, rowIndex, columnIndex) => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value, tableMeta, updateValue) => <SourceTypeIcon type={value} size={24} />
      }
    },
    {
      name: 'Path',
      options: {
        sortDirection: sortAttribute === 'path' ? sortDirection : 'none',
        setCellProps: (cellValue, rowIndex, columnIndex) => ({ style: { wordBreak: 'normal' } }),
        customBodyRender: (value, tableMeta, updateValue) => <span>{value || '\u2013'}</span>
      }
    },
    {
      name: 'Status',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <ProgressGrid
            deleted={value.find(s => s.name === 'Deleted').status === 'YES'}
            statuses={value.filter(s => s.name !== 'Deleted' && (hasPermission(Permissions.MANAGE_FILES_ADVANCED, me) || s.name !== 'Indexed'))}
          />
        )
      }
    },
    {
      name: 'Created At',
      options: {
        sortDirection: sortAttribute === 'createdAt' ? sortDirection : 'none',
        customBodyRender: (value, tableMeta, updateValue) => instantToDateTimeString(value)
      }
    },
    {
      name: 'Modified At',
      options: {
        sortDirection: sortAttribute === 'modifiedAt' ? sortDirection : 'none',
        customBodyRender: (value, tableMeta, updateValue) => instantToDateTimeString(value)
      }
    },
    {
      name: 'File Type',
      options: {
        sortDirection: sortAttribute === 'mimeType' ? sortDirection : 'none',
        setCellProps: (cellValue, rowIndex, columnIndex) => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value, tableMeta, updatevalue) => (<FileTypeIcon type={mimeTypeToFileType(value)} size={24} />)
      }
    },
    {
      name: 'Page Count',
      options: {
        sortDirection: sortAttribute === 'pageCount' ? sortDirection : 'none',
        display: hasPermission(Permissions.MANAGE_FILES_ADVANCED, me)
      }
    },
    {
      name: 'Segmentation Method',
      options: {
        sortDirection: sortAttribute === 'segmentationMethods' ? sortDirection : 'none',
        display: hasPermission(Permissions.MANAGE_FILES_ADVANCED, me)
      }
    },
    {
      name: 'Segment Count',
      options: {
        sortDirection: sortAttribute === 'segmentsCount' ? sortDirection : 'none'
      }
    },
    {
      name: 'Error',
      options: {
        sortDirection: sortAttribute === 'apiErrorCode' ? sortDirection : 'none',
        customBodyRender: (value, tableMeta, updateValue) => (value ? <FormattedErrorCode code={value} /> : '-')
      }
    },
    {
      name: 'DLQ Events',
      options: {
        sort: false,
        display: hasPermission(Permissions.MANAGE_FILES_ADVANCED, me),
        customBodyRender: (value, tableMeta, updateValue) => value[1].length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <AnchorButton>
              {value[1].length}
            </AnchorButton>
          </div>
        ) : '-'
      }
    }
  ]

  const onRowsSelected = (newSelectedRows, selectedRows) => {
    setSelectedRows(selectedRows.map(r => r.index))
  }

  const clearSelectedRows = () => {
    setSelectedRows([])
  }

  const onColumnSortChange = (changedColumn, direction) => {
    if (columnToAttribute[changedColumn] === undefined) { return }

    // mui-datatable doesn't cycle back to 'none' direction which is unintuitive so we'll implement it ourselves
    // here instead
    if (columnToAttribute[changedColumn] === sortAttribute && sortDirection === 'asc') {
      setSortAtribute(null)
      setSortDirection(null)
    } else {
      setSortAtribute(columnToAttribute[changedColumn])
      setSortDirection(direction === 'descending' ? 'desc' : 'asc')
    }
  }

  const [deleteFileMutation] = useMutation(DeleteFileMutation, {
    onCompleted: data => {
      const results = data.v2Api.deleteFiles.results
      if (results.every(r => r.deleted)) {
        enqueueSnackbar(`${results.length} ${results.length > 1 ? 'files have' : 'file has'} been marked for deletion.`)
      } else if (results.every(r => !r.deleted)) {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`ERROR: ${r.cmsUuid}: ${r.errorMessage}`}</li>
          )}
          </ul>
          , { variant: 'error', persist: true }
        )
      } else {
        enqueueSnackbar(
          <ul>{results.map((r, i) =>
            <li key={i}>{`${r.deleted ? 'SUCCESS' : 'ERROR'}: ${r.cmsUuid}${r.deleted ? '' : `: ${r.errorMessage}`}`}</li>
          )}
          </ul>
          , { variant: 'warning', persist: true }
        )
      }
      if (refetch) { refetch() }
      setSelectedRows([])
    },
    onError: error => {
      console.log(error)
      enqueueSnackbar(<FormattedException err={error} />, { variant: 'error' })
    }
  })

  const handleDeleteFiles = (selectedRows) => {
    const statuses = rawData.v2Api.admin.fileStatuses.statuses
    const files = selectedRows.data.map(selectedRow => statuses[selectedRow.dataIndex])
    const validFiles = files.filter(f => f.sourceType === null)
    const connectorFiles = files.filter(f => f.sourceType !== null)

    const confirmMessage = (
      <>
        {validFiles.length
          ? <span>{`${validFiles.length} selected file(s) will be marked for deletion.`}</span>
          : <span>None of the selected files are eligible for manual deletion. Close the dialog and try again with different files.</span>}
        {connectorFiles.length ? <span><br />{`${connectorFiles.length} selected file(s) are managed by connectors and are being ignored.`}</span> : null}
      </>
    )

    showConfirmationDialog({
      title: 'Delete File(s)',
      message: confirmMessage,
      confirmLabel: 'Delete',
      onConfirm: async () => {
        deleteFileMutation({
          variables: {
            deleteFileRequests: validFiles.map(f => ({ cmsUuid: f.cmsUuid }))
          }
        })
      },
      showConfirm: validFiles.length > 0
    })
  }

  const dlqEventKey = extractDlqEventKey(location)
  const detailsView = <DlqEventDetail dlqEventKey={dlqEventKey} onClose={() => setDlqEventKey(history, PATHNAME, null)} />

  const columns = getColumns()

  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      style={{ height: '100%' }}
    >
      {detailsView}
      <Grid item xs style={{ display: dlqEventKey ? 'none' : 'initial', height: '100%' }} className={classes.item}>
        <DataTable
          id='FileStatuses'
          title='File Statuses'
          me={me}
          query={FileStatusesQuery}
          queryVariables={{
            params: {
              sortAttribute,
              sortDirection,
              includeRelatedFileEvents: hasPermission(Permissions.MANAGE_FILES_ADVANCED, me),
              includeRelatedSegmentEvents,
              withDlqsOnly,
              pendingOnly: false
            }
          }}
          customToolbar={(
            <>
              {hasPermission(Permissions.MANAGE_FILES_ADVANCED, me) ? (
                <FormControlLabel
                  control={
                    <Checkbox checked={includeRelatedSegmentEvents} onChange={() => setIncludeRelatedSegmentEvents(prev => !prev)} value='includeRelatedSegmentEvents' />
                  }
                  label='Load Segment Events'
                />
              ) : null}
              {hasPermission(Permissions.MANAGE_FILES_ADVANCED, me) ? (
                <FormControlLabel
                  control={
                    <Checkbox checked={withDlqsOnly} onChange={() => setWithDlqsOnly(prev => !prev)} value='withDlqsOnly' />
                  }
                  label='With Dlqs Only'
                />
              ) : null}
            </>
          )}
          defaultValues={{ clientCodes: [selectedClient] }}
          columns={columns}
          setRefetchData={f => setRefetch(() => f)}
          setRawData={setRawData}
          clearSelectedRows={clearSelectedRows}
          options={{
            // TODO: Move feature check to customToolbarSelect below if we ever add more actions
            selectableRows: me.features.manualFileDeletion.enabled ? 'multiple' : 'none',
            rowsSelected: selectedRows,
            onRowsSelect: onRowsSelected,
            sort: true,
            onColumnSortChange: onColumnSortChange,
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
              <Tooltip title='Delete Selected File(s)'>
                <IconButton onClick={() => handleDeleteFiles(selectedRows)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>,
            expandableRows: hasPermission(Permissions.MANAGE_FILES_ADVANCED, me),
            renderExpandableRow: (rowData, rowMeta) => (
              <TableRow>
                <TableCell />
                <TableCell colSpan={columns.length - 1} style={{ padding: theme.spacing(3) }}>
                  {rowData[0]}
                </TableCell>
              </TableRow>
            ),
            setRowProps: (row) => {
              // This is super ugly code but mui-datatable is deprecating passing objects as a column data. We could
              // alternatively create a hidden column to pass in the isDeleted flag directly but that's equally bad.
              if (row[RowDataIndexes.STATUSES].props.deleted) {
                return {
                  style: {
                    opacity: '0.3'
                  }
                }
              }

              return {}
            }
          }}
          extractData={data => data.v2Api.admin.fileStatuses.statuses.map(f => [
            [f.relatedFileEvents, f.relatedSegmentEvents],
            f.clientEnv.client,
            f.cmsUuid,
            f.sha256,
            f.fileName,
            f.sourceType,
            f.path,
            [
              { name: 'Received', status: f.isReceived },
              { name: 'Analyzed', status: f.isAnalyzed },
              { name: 'Indexed', status: f.isIndexed },
              { name: 'Segmented', status: f.isSegmented },
              { name: 'Deleted', status: f.isDeleted }
            ],
            f.createdAt,
            f.modifiedAt,
            f.mimeType,
            f.pageCount,
            f.segmentationMethods,
            f.segmentsCount,
            f.apiErrorCode,
            [f.cmsUuid, f.dlqEvents]
          ])}
          extractTotal={data => data.v2Api.admin.fileStatuses.total}
        />
      </Grid>
    </Grid>
  )
}

const FileStatusesQuery = gql`
  query fileStatusesQuery($clients: [ID!], $page: Int!, $rowsPerPage: Int!, $searchText: String, $params: FindFileStatusesParams!) {
    v2Api {
      admin{
        fileStatuses(clients: $clients, page: $page, rowsPerPage: $rowsPerPage, searchText: $searchText, params: $params) {
          total
          statuses {
            ...fileStatusFields
          }
        }
      }
    }
  }

  ${CommonGraphqlFragments.dlqEvent}
  ${CommonGraphqlFragments.fileStatus}
  ${CommonGraphqlFragments.event}
  ${CommonGraphqlFragments.eventContext}
`

const DeleteFileMutation = gql`
  mutation deleteFiles($deleteFileRequests: [DeleteFileRequest!]!) {
    v2Api {
      deleteFiles(deleteFileRequests: $deleteFileRequests) {
        results {
          cmsUuid
          deleted
          errorMessage
        }
      }
    }
  }
`

export default withStyles(styles)(FileStatuses)
