import gql from 'graphql-tag'
import sortBy from 'lodash/sortBy'
import * as clipboard from 'clipboard-polyfill'

const computeSegmentHtml = segment => {
  if (!segment.content || segment.content.length === 0) {
    return ''
  }

  let repr = segment.content.find(c => c.repr === 'html')
  if (repr) {
    return repr.value
  }

  repr = segment.content.find(c => c.repr === 'image')
  if (repr) {
    return `<img src="${repr.uri}" />`
  }

  repr = segment.content.find(c => c.repr === 'plain')
  if (repr) {
    return `<pre>${repr.value}</pre>`
  }

  return ''
}

const getClips = async (client, uuids) => {
  return (await client.query({
    query: SegmentsClipsQuery,
    variables: { uuids: uuids },
    fetchPolicy: 'network-only'
  })).data.v2Api.segmentsClips
}

const joinClips = clips => {
  const result = clips.reduce((result, clip) => {
    return {
      plain: result.plain + clip.plain.trim() + '\n\n',
      html: result.html + clip.html
    }
  }, { plain: '', html: '' })

  // Remove trailing newlines
  result.plain = result.plain.trim()

  return result
}

const doCopy = async (clip, enqueueSnackbar, callback) => {
  try {
    const dt = new clipboard.DT()
    if (clip.plain) dt.setData('text/plain', clip.plain)
    if (clip.html) dt.setData('text/html', clip.html)

    await clipboard.write(dt)

    if (callback) { callback() }

    if (enqueueSnackbar) { enqueueSnackbar('Copied', { variant: 'success' }) }
  } catch (err) {
    console.log(err)
    if (enqueueSnackbar) { enqueueSnackbar('Oops, there was an error copying to your clipboard', { variant: 'error', persist: true }) }
  }
}

const orderSegments = (segments, parentUuid) => {
  let current = segments.filter(s => s.parentUuid === parentUuid)
  current = sortBy(current, 'index')
  const result = []
  current.forEach(s => {
    result.push(s)
    result.push(...orderSegments(segments, s.uuid))
  })
  return result
}

const buildSegmentsTree = (docUuid, segments, edges) => {
  segments.forEach(segment => {
    segment.html = computeSegmentHtml(segment)
    const edge = edges.find(e => e.fromUuid === segment.uuid)
    segment.selected = false // initialize
    segment.edge = edge
    segment.index = edge.index
    segment.parentUuid = edge.toUuid
  })

  return orderSegments(segments, docUuid)
}

const SegmentsClipsQuery = gql`
  query clipQuery($uuids: [ID]!) {
    v2Api {
      segmentsClips(uuids: $uuids) {
        plain
        html
      }
    }
  }
`

export { computeSegmentHtml, joinClips, getClips, buildSegmentsTree, doCopy }
