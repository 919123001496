// these must be first, for IE 11 support
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'fast-text-encoding'
import 'core-js/es/array/find'
import 'core-js/es/object/values'
import 'core-js/es/string/starts-with'
import 'core-js/web/url'
import 'core-js/web/url-search-params'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'

import App from 'App'
import 'index.css'
import appConfig from 'common/config/AppConfig'
import { TopLevelErrorCode, TopLevelErrorException, TopLevelErrorHandler } from 'components/Error/topLevelError'
import MsalAuthService from 'services/auth/MsalAuthService'
import DevTokenAuthService from 'services/auth/DevTokenAuthService'
import * as generatedMessages from 'translations'

import 'react-datepicker/dist/react-datepicker.css'

// for browsers that don't have this built-in, polyfill it, add locale data for each support language as necessary
// https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
if (!Intl.PluralRules) {
  // eslint-disable-next-line
  import('@formatjs/intl-pluralrules/polyfill')
  // can make this dynamic, see https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
  // import('@formatjs/intl-pluralrules/dist/locale-data/xx') // Add locale data for xx
}

// for browsers that don't have this built-in, polyfill it, add locale data for each support language as necessary
// https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
if (!Intl.RelativeTimeFormat) {
  // eslint-disable-next-line
  import('@formatjs/intl-relativetimeformat/polyfill')
  // can make this dynamic, see https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
  // import('@formatjs/intl-relativetimeformat/dist/locale-data/xx') // Add locale data for xx
}

// enable progressive web apps (offline capable), disable it for now
// registerServiceWorker()

// We don't know the user or his preferences at this point so use the navigator's language instead
// We can nest IntlProvider as needed to provide different localization context lower in the DOM
const navLang = navigator.language.substr(0, 2) // Ignore Region for now
const LocalizedApp = (props) => (
  <IntlProvider
    locale={navLang}
    defaultLocale='en'
    messages={generatedMessages[navLang]}
    defaultMessages={generatedMessages.en}
  >
    {props.children}
  </IntlProvider>
)

const renderApp = async () => {
  try {
    const config = await appConfig()

    let authService
    if ((process.env.NODE_ENV !== 'production' && process.env.REACT_APP_TOKEN)) {
      authService = new DevTokenAuthService()
    } else {
      authService = new MsalAuthService(config.aadv2 && config.aadv2.applicationId)
    }

    ReactDOM.render((
      <LocalizedApp>
        <TopLevelErrorHandler>
          <BrowserRouter>
            <App urls={config.urls} authService={authService} zohoDeskAsapId={config.zohoDesk.asapId} constants={config.constants || {}} />
          </BrowserRouter>
        </TopLevelErrorHandler>
      </LocalizedApp>
    ), document.getElementById('app'))
  } catch (err) {
    if (typeof err === 'string' && err.startsWith('user_login_error')) {
      ReactDOM.render(<LocalizedApp><TopLevelErrorCode code='login-failure' /></LocalizedApp>, document.getElementById('app'))
    } else {
      console.error('App initialization failed', err)
      ReactDOM.render(<LocalizedApp><TopLevelErrorException error={err} /></LocalizedApp>, document.getElementById('app'))
    }
  }
}

// noinspection JSIgnoredPromiseFromCall
renderApp()
