import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M 21,3 H 3 C 1.9,3 1,3.9 1,5 v 14 c 0,1.1 0.9,2 2,2 h 18 c 1.1,0 2,-0.9 2,-2 V 5 C 23,3.9 22.1,3 21,3 Z m 0,16 H 3 V 5 h 18 z' />
    <path d='M 4,5.8335452 H 20.077085 V 18.033038 H 4 Z' />
    <path fill='none' d='M0 0h24v24H0z' />
  </>
  , 'Document'
)
