import React, { useState } from 'react'
import ConfirmationDialog from 'components/Common/ConfirmationDialog'
import ConfirmationDialogContext from 'contexts/Common/ConfirmationDialogContext'

const ConfirmationDialogProvider = ({ children }) => {
  const [dialogProps, setDialogProps] = useState(null)
  const contextValue = {
    // See ConfirmationDialog Component for the list of available props
    showConfirmationDialog: newProps => {
      setDialogProps({
        open: true,
        ...newProps
      })
    }
  }

  return (
    <ConfirmationDialogContext.Provider value={contextValue}>
      {children}
      <ConfirmationDialog {...dialogProps} onClose={() => setDialogProps(prev => ({ ...prev, open: false }))} />
    </ConfirmationDialogContext.Provider>
  )
}

export default ConfirmationDialogProvider
