import { removeTypename } from 'common/utilities/graphql'

/** Retrieves a DlqEvent { id, process } object from the passed router location **/
const extractDlqEventKey =
  (location) => {
    if (!location || !location.search) {
      return null
    }

    const params = new URLSearchParams(location.search)
    return {
      uuid: params.get('uuid'),
      id: params.get('id'),
      process: params.get('process')
    }
  }

/**
 * Pushes a new location on the provided router history, using the provided pathname and
 * appending the provided DlqEventKey object as 2 parameters (id, process)
 */
const setDlqEventKey = (history, pathname, key) => {
  if (key) {
    key = removeTypename(key)
    const search = `?uuid=${encodeURIComponent(key.uuid)}&id=${encodeURIComponent(key.id)}&process=${encodeURIComponent(key.process)}`
    history.push({
      pathname: pathname,
      search: search
    })
  } else {
    history.push({
      pathname: pathname,
      search: ''
    })
  }
}

export { extractDlqEventKey, setDlqEventKey }
