import React from 'react'
import classNames from 'classnames'

import Select, { components } from 'react-select'

const { Control, ValueContainer, Placeholder } = components

const customControl = ({ children, ...props }) => (
  <Control {...props}>
    {React.Children.map(children, child => {
      return React.cloneElement(child, { isFocused: props.isFocused })
    })}
  </Control>
)

const CustomValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props}>
    <Placeholder {...props}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
  </ValueContainer>
)

export default (props) => (
  <Select
    className={props.className ? classNames('basic-single', props.className) : 'basic-single'}
    classNamePrefix='select'
    components={{
      Control: customControl,
      ValueContainer: CustomValueContainer
    }}
    styles={{
      container: (provided) => ({
        ...provided,
        marginTop: 16,
        marginBottom: 8
      }),
      valueContainer: (provided) => ({
        ...provided,
        overflow: 'visible'
      }),
      menu: (provided) => ({ ...provided, zIndex: 100 }),
      control: (provided, state) => {
        let styles = {
          ...provided,
          boxSizing: 'border-box',
          paddingTop: 9,
          paddingBottom: 9,
          paddingLeft: 14,
          borderColor: 'rgba(0,0,0,0.25)',
          '&:hover': {
            borderColor: 'rgba(0,0,0,0.87)'
          }
        }

        if (state.isFocused) {
          styles = {
            ...styles,
            borderColor: 'rgba(0,0,0,0.87)',
            boxShadow: '0 0 0 1px rgba(0,0,0,0.87)'
          }
        }

        return styles
      },
      placeholder: (provided, state) => ({
        ...provided,
        color: (state.isFocused || state.isSelected) ? 'black' : provided.color,
        position: 'absolute',
        top: state.hasValue || state.selectProps.inputValue ? -12 : '50%',
        left: -8,
        transition: 'top 0.1s, font-size 0.1s',
        fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
        background: 'white',
        paddingLeft: 4,
        paddingRight: 4
      })
    }}
    {...props}
  />
)
