class LoginInProgress extends Error {
  constructor (...args) {
    super(...args)
    this.name = 'LoginInProgress'
  }
}

class LoginAccessDenied extends Error {
  constructor (...args) {
    super(...args)
    this.name = 'LoginAccessDenied'
  }
}

export { LoginInProgress, LoginAccessDenied }
