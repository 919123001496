import React from 'react'
import classnames from 'classnames'
import gql from 'graphql-tag'
import { detect } from 'detect-browser'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Waypoint } from 'react-waypoint'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { arrayIsNullOrEmpty } from 'common/utilities/arrays'
import { dynamicCssTransitionClassNames } from 'common/utilities/jss'
import { extractErrorInfo, FormattedException } from 'common/graphqlclient/ErrorHandler'
import DateFilter from 'components/Search/DateFilter'
import SearchBar from 'components/Search/SearchBar'
import SearchResultFooter from 'components/Search/SearchResultFooter'
import SegmentTypeFilter from 'components/Search/SegmentTypeFilter'
import SourceContextFilter from 'components/Search/SourceContextFilter'
import SearchSummary from 'components/Search/SearchSummary'
import { getItemParsed } from 'common/utilities/storage'
import { uuidv4 } from 'common/uuid/Uuid'
import { dateTypeEnum } from 'common/config/Constants'
import SearchResults from 'components/Search/SearchResults'
import { extractParams, formatParams } from 'common/utilities/searchParams'
import ContextView from 'components/Segment/ContextView'
import CommonGraphqlFragments from 'common/graphqlclient/Fragments'
import FilterIcon from 'icons/Filter'
import FilterOutlineIcon from 'icons/FilterOutline'
import Tips from 'components/Tips/Tips'
import { userDownloadedFile } from 'services/redock/File'
import { getFoldedHits, getHitFromSegmentId, getHitPositionAllHitsById, getHitPositionUnderResultById, getHitPositionVisibleHitsById, getResultPositionById } from 'services/redock/Search'
import { reDockContent } from 'services/theme/reDockTheme'

const browser = detect()
// edge kind of supports it but doesn't reset the sticky state properly i.e. waypoint onEnter doesn't work, IE doesn't at all
const browserSupportsPositionSticky = browser.name !== 'ie' && browser.name !== 'edge'

const showAndApplyFiltersKey = 'Search.state.showAndApplyFilters'

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden'
  },
  scrollableItem: {
    overflowY: 'auto',
    height: '100%'
  },
  contentContainerWithResults: {
    marginBottom: '40vh'
  },
  searchBody: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  searchSummary: {
    width: reDockContent.pageWidth
  },
  searchFilters: {
    width: reDockContent.pageWidth
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  searchBar: {
    position: browserSupportsPositionSticky ? 'sticky' : 'relative',
    color: 'inherit',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[0],
    zIndex: '100'
  },
  'searchBar-enter-active': {
    boxShadow: theme.shadows[4],
    transition: 'box-shadow 300ms ease-out'
  },
  'searchBar-enter-done': {
    boxShadow: theme.shadows[4]
  },
  'searchBar-exit': {
    boxShadow: theme.shadows[4]
  },
  'searchBar-exit-active': {
    boxShadow: theme.shadows[0],
    transition: 'box-shadow 300ms ease-out'
  },
  tips: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class Search extends React.Component {
  constructor (props) {
    super(props)

    const params = extractParams(props.location)

    this.state = {
      ...params,
      status: null,
      result: null,
      foldedHits: [],
      progress: false,
      raiseForm: false,
      searchEventId: null,
      contextViewLaunchedEventId: null,
      showSimilar: false,
      similarByUuid: {},
      showAndApplyFilters: this.hasActiveFilters(params) || getItemParsed(window.sessionStorage, showAndApplyFiltersKey),
      showCustomRangeDialog: false
    }
  }

  componentDidMount () {
    if (this.props.location.search) {
      this.locationBasedSearch()
    }
  }

  locationBasedSearch = () => {
    const { query } = this.state
    if (query) { this.executeSearch('LOCATIONBASED') }
  }

  // segments is an Array of object with the following properties:
  //   segment: Segment being copied
  //   hit OR hitSegmentId: Hit of the segment being copied OR a segmentId we can use to retrieve
  //     a Hit from the current search results.
  userCopiedSegments = (segments, location) => {
    setTimeout(async _ => {
      try {
        const copyActionId = uuidv4() // Shared by all copySegmentEvent of a copy action
        segments.forEach(async pair => {
          const h = pair.hit || getHitFromSegmentId(this.state.result, pair.hitSegmentId)
          const s = pair.segment
          await this.props.client.mutate({
            mutation: UserCopiedSegmentMutation,
            variables: {
              input: {
                client: this.props.selectedClient,
                userSearchedEventId: this.state.searchEventId,
                segmentId: s.uuid,
                hitPositionVisibleHits: getHitPositionVisibleHitsById(this.state.foldedHits, h.id),
                hitPositionUnderResult: getHitPositionUnderResultById(this.state.result, h.id),
                hitPositionAllHits: getHitPositionAllHitsById(this.state.result, h.id),
                resultPosition: getResultPositionById(this.state.result, h.id),
                copyActionId: copyActionId,
                segmentType: s.type,
                fileType: s.fileType,
                fileName: h.breadcrumb.fileName,
                location: location,
                contextViewLaunchedEventId: this.state.contextViewLaunchedEventId,
                docId: h.docId
              }
            }
          })
        })
      } catch (err) {
        console.error('Error doing UserCopiedMutation, ignoring', err)
      }
    }, 0)
  }

  setShowSimilar = val => this.setState({ showSimilar: val })

  showSimilarByUuid = (uuid, showSimilar) => {
    const similarByUuid = Object.assign({}, this.state.showSimilarByUuid)
    similarByUuid[uuid] = !!showSimilar

    this.setState({
      similarByUuid: similarByUuid,
      foldedHits: getFoldedHits(this.state.result, similarByUuid, this.state.showSimilar)
    })
  }

  loadMore = async (event) => {
    event.preventDefault()

    if (!this.props.authService.getUser()) {
      return
    }

    this.setState({ progress: true })
    try {
      const result = await this.props.client.query({
        query: SearchQuery,
        variables: {
          input: {
            client: this.props.selectedClient,
            ...this.state.lastSearch,
            contentTimestampFilter: this.state.showAndApplyFilters ? this.getDateFilter() : null,
            segmentTypeFilter: this.state.showAndApplyFilters ? this.getSegmentTypesFilter() : null,
            sourceContextFilter: this.state.showAndApplyFilters ? this.getSourceContextFilter() : null
          }
        },
        fetchPolicy: 'network-only'
      })

      const newHits = result.data.v2Api.searchSegments.hits
      // Needs to be done outside the setTimeout otherwise
      // the state gets updated before this line is executed
      const currentHitsCount = this.state.result.hits.length

      setTimeout(async _ => {
        try {
          await this.props.client.mutate({
            mutation: UserLoadedMoreMutation,
            variables: {
              input: {
                client: this.props.selectedClient,
                userSearchedEventId: this.state.searchEventId,
                currentHitsCount: currentHitsCount,
                totalHitsCount: result.data.v2Api.searchSegments.total,
                requestedResultsSize: 10, // Currently hardcoded in the schema
                resultsCount: newHits.filter(h => h.similarToUuid === null).length,
                hitsCount: newHits.length,
                duration: result.data.v2Api.searchSegments.took
              }
            }
          })
        } catch {
          // TODO add error capture and reporting, but continue the logout process from the user's perspective by ignoring this error otherwise
        }
      }, 0)

      const lastHit = newHits.length > 0 ? newHits[newHits.length - 1] : null
      const newResult = { ...this.state.result, hits: [...this.state.result.hits, ...newHits] }
      const newLastSearch = Object.assign(
        {},
        this.state.lastSearch,
        {
          searchAfterScore: lastHit !== null ? lastHit.score : null,
          searchAfterId: lastHit !== null ? lastHit.id : null
        }
      )
      this.setState({
        status: null,
        progress: false,
        result: newResult,
        foldedHits: getFoldedHits(newResult, this.state.similarByUuid, this.state.showSimilar),
        lastSearch: newLastSearch
      })
    } catch (err) {
      this.setState({ status: <FormattedException err={err} />, progress: false })
    }
  }

  updateSearchParams = (params) => {
    if (!this.props.authService.getUser()) {
      return
    }

    this.setState({
      ...params
    })
  }

  onSearch = async (event, trigger, source) => {
    if (event) event.preventDefault()
    this.executeSearch(trigger, source)
  }

  executeSearch = async (trigger, source) => {
    const { query, segmentTypes } = this.state
    trigger = trigger || 'FORM_SUBMITTED'
    source = source || 'IN_APP'

    if (!query) {
      this.setState({ status: null, result: null, foldedHits: [] })
      return
    }

    this.props.history.push({
      pathname: '/',
      search: formatParams({ ...this.state, trigger, source })
    })

    this.setState({ trigger, source, progress: true, result: null, foldedHits: [] })

    try {
      const result = await this.props.client.query({
        query: SearchQuery,
        variables: {
          input: {
            client: this.props.selectedClient,
            query: query,
            contentTimestampFilter: this.state.showAndApplyFilters ? this.getDateFilter() : null,
            segmentTypeFilter: this.state.showAndApplyFilters ? this.getSegmentTypesFilter(segmentTypes) : null,
            sourceContextFilter: this.state.showAndApplyFilters ? this.getSourceContextFilter() : null,
            trigger
          }
        },
        fetchPolicy: 'network-only'
      })

      // Hackish for case when the url was reset while the search was being executed
      if (!this.state.progress) {
        return
      }

      const hits = result.data.v2Api.searchSegments.hits
      setTimeout(async _ => {
        try {
          const mutationResult = await this.props.client.mutate({
            mutation: UserSearchedMutation,
            variables: {
              input: {
                client: this.props.selectedClient,
                trigger: trigger,
                query: query,
                source: source,
                referrer: document.referrer,
                totalHitsCount: result.data.v2Api.searchSegments.total,
                requestedResultsSize: 10, // Currently hardcoded in the schema
                resultsCount: hits.filter(h => h.similarToUuid === null).length,
                hitsCount: hits.length,
                duration: result.data.v2Api.searchSegments.took
              }
            }
          })

          this.setState({ searchEventId: mutationResult ? mutationResult.data.v2Api.userSearched.id : 0 })
        } catch (err) {
          // TODO add error capture and reporting, but continue the logout process from the user's perspective by ignoring this error otherwise
          console.error('Error doing UserSearchedMutation, ignoring', err)
        }
      }, 0)

      const lastHit = hits.length > 0 ? hits[hits.length - 1] : null
      this.setState(
        {
          page: 0,
          status: null,
          progress: false,
          result: result.data.v2Api.searchSegments,
          foldedHits: getFoldedHits(result.data.v2Api.searchSegments, this.state.similarByUuid, this.state.showSimilar),
          lastSearch: {
            query: query,
            trigger: trigger,
            searchAfterScore: lastHit !== null ? lastHit.score : null,
            searchAfterId: lastHit !== null ? lastHit.id : null
          }
        })
    } catch (err) {
      const { code, userMessage } = extractErrorInfo(err)
      this.props.client.mutate({
        mutation: UserSearchFailedMutation,
        variables: {
          input: {
            client: this.props.selectedClient,
            trigger: trigger,
            query: query,
            error: `${code} - ${userMessage}`
          }
        }
      }).catch(() => null /* no-op, metrics monitoring failure should not impact users */)

      this.setState({ status: <FormattedException err={err} />, progress: false })
    }
  }

  handleSearchWaypointEnter = ({ previousPosition, currentPosition, event }) => {
    this.setState({
      raiseForm: false
    })
  }

  handleSearchWaypointLeave = ({ previousPosition, currentPosition, event }) => {
    this.setState({
      raiseForm: true
    })
  }

  toggleFilters = () => {
    window.sessionStorage.setItem(showAndApplyFiltersKey, !this.state.showAndApplyFilters)

    this.setState({
      showAndApplyFilters: !this.state.showAndApplyFilters
    })
  }

  clearFilters = () => {
    this.updateSearchParams({
      dateType: dateTypeEnum.ANY,
      dateFrom: null,
      dateTo: null,
      segmentTypes: [],
      sourceContext: ''
    })
  }

  hasActiveFilters = (params) => {
    const { dateType, segmentTypes, sourceContext } = params || this.state
    return dateType !== dateTypeEnum.ANY || !arrayIsNullOrEmpty(segmentTypes) || sourceContext
  }

  getDateFilter = (from, to) => {
    const { dateFrom, dateTo } = this.state
    from = from || dateFrom
    to = to || dateTo

    return {
      from: from ? from.hours(0).minutes(0).seconds(0).toDate() : null,
      to: to ? to.hours(23).minutes(59).seconds(59).toDate() : null
    }
  }

  setDateFilter = (dateType, dateFrom, dateTo) => {
    this.updateSearchParams({
      dateType,
      dateFrom,
      dateTo
    })
  }

  getSegmentTypesFilter = types => {
    const { segmentTypes } = this.state

    return {
      values: types || segmentTypes
    }
  }

  setSegmentTypes = event => {
    let segmentTypes = event.target.value

    const hasNone = segmentTypes.some(t => t === '')

    if (hasNone) { segmentTypes = [] }

    this.updateSearchParams({
      segmentTypes
    })
  }

  getSourceContextFilter = () => {
    const { sourceContext } = this.state
    return sourceContext
  }

  setSourceContext = (context) => {
    const newContext = context
    this.updateSearchParams({
      sourceContext: newContext
    })

    // When setting the SourceContext by clicking on the path of a result, it's possible
    // for the Filter pane to be hidden. Show it.
    if (newContext) {
      this.setState({
        showAndApplyFilters: true
      })
    }
  }

  handleFileDownloaded = (fileName, fileType, location, action, hit, segmentId) => {
    hit = hit || getHitFromSegmentId(this.state.result, segmentId)
    const { client, selectedClient } = this.props
    const { searchEventId, contextViewLaunchedEventId } = this.state

    userDownloadedFile(client, selectedClient, searchEventId, contextViewLaunchedEventId, location, action, fileName, fileType, hit.cmsId, hit.sha256, hit.docId)
  }

  handleOpenContextView = segmentId => {
    this.updateSearchParams({
      segmentId
    })

    const params = extractParams(this.props.location)
    params.segmentId = segmentId
    this.props.history.push({
      pathname: '/',
      search: formatParams(params)
    })
  }

  handleContextViewClosed = () => {
    this.setState({
      contextViewLaunchedEventId: null,
      segmentId: null
    })

    const params = extractParams(this.props.location)
    params.segmentId = null
    this.props.history.push({
      pathname: '/',
      search: formatParams(params)
    })
  }

  handleContextViewSegmentsLoaded = (segmentId, loadingTime) => {
    const h = getHitFromSegmentId(this.state.result, segmentId)
    setTimeout(async _ => {
      try {
        const mutationResult = await this.props.client.mutate({
          mutation: UserLaunchedContextViewMutation,
          variables: {
            input: {
              client: this.props.selectedClient,
              userSearchedEventId: this.state.searchEventId,
              segmentId: h.segment.uuid,
              hitPositionVisibleHits: getHitPositionVisibleHitsById(this.state.foldedHits, h.id),
              hitPositionUnderResult: getHitPositionUnderResultById(this.state.result, h.id),
              hitPositionAllHits: getHitPositionAllHitsById(this.state.result, h.id),
              resultPosition: getResultPositionById(this.state.result, h.id),
              fileType: h.segment.fileType,
              fileName: h.breadcrumb.fileName,
              cmsId: h.cmsId,
              sha256: h.sha256,
              docId: h.docId,
              loadingTime: loadingTime
            }
          }
        })

        this.setState({ contextViewLaunchedEventId: mutationResult ? mutationResult.data.v2Api.userLaunchedContextView.id : 0 })
      } catch (err) {
        console.error('Error doing UserLaunchedContextViewMutation, ignoring', err)
      }
    }, 0)
  }

  render () {
    const { me, classes, location, authService, selectedClient } = this.props
    const {
      query,
      segmentId,
      dateType,
      dateFrom,
      dateTo,
      segmentTypes,
      sourceContext,
      status,
      result,
      foldedHits,
      progress,
      lastSearch,
      raiseForm,
      showSimilar,
      showAndApplyFilters
    } = this.state
    const hasMore = (result && result.hits.length < result.total)

    if (!authService.getUser() || !me) {
      return null
    }

    const contextViewHit = getHitFromSegmentId(result, segmentId)
    const contextViewPath = contextViewHit && contextViewHit.path

    const searchResultFooter = <SearchResultFooter progress={progress} hasMore={hasMore} loadMore={this.loadMore} result={result} lastSearch={lastSearch} />

    return (
      <>
        <div className={classes.tips}><Tips show={!result && !progress} /></div>
        <Grid container direction='column' justify='flex-start' alignItems='center' className={classnames({ [classes.contentContainerWithResults]: !!result })}>
          {browserSupportsPositionSticky ? (
            <Waypoint
              onEnter={this.handleSearchWaypointEnter}
              onLeave={this.handleSearchWaypointLeave}
            />) : null}
          <CSSTransition in={raiseForm} timeout={300} classNames={{ ...dynamicCssTransitionClassNames(classes, 'searchBar') }}>
            <AppBar className={classes.searchBar}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SearchBar query={query} setQuery={query => this.updateSearchParams({ query })} handleSearch={this.onSearch} />
                <Tooltip title='Filters' placement='top' style={{ marginRight: -48 }}>
                  <IconButton onClick={() => this.toggleFilters()}>
                    {showAndApplyFilters ? <FilterIcon /> : <FilterOutlineIcon />}
                  </IconButton>
                </Tooltip>
              </div>
              <Collapse in={showAndApplyFilters && true}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className={classes.searchFilters}>
                    {me.features.sourceContextFilter.enabled ? <SourceContextFilter className={classes.formControl} sourceContext={sourceContext} setSourceContext={this.setSourceContext} submitForm={this.onSearch} /> : null}
                    <DateFilter className={classes.formControl} dateType={dateType} dateFrom={dateFrom} dateTo={dateTo} setDateFilter={this.setDateFilter} />
                    <SegmentTypeFilter className={classes.formControl} segmentTypes={segmentTypes} setSegmentTypes={this.setSegmentTypes} />
                    <FormControl className={classes.formControl}>
                      <Button style={{ visibility: (this.hasActiveFilters() ? 'visible' : 'hidden') }} onClick={() => this.clearFilters()}>Clear</Button>
                    </FormControl>
                  </div>
                </div>
              </Collapse>
            </AppBar>
          </CSSTransition>
          {status ? (
            <Grid item className={classes.searchBody}>
              <p>{status}</p>
            </Grid>) : null}
          {result ? (
            <Grid item className={classes.searchSummary}>
              <SearchSummary
                result={result}
                hits={foldedHits}
                location={location}
                showSimilar={showSimilar}
                setShowSimilar={this.setShowSimilar}
                executeSearch={(query, trigger, source, referer) => this.updateSearchParams({ query, trigger, source, referer })}
              />
            </Grid>) : null}
          {result ? (
            <Grid item className={classes.searchBody}>
              <SearchResults
                hits={showSimilar && result ? result.hits : foldedHits}
                userCopiedSegments={this.userCopiedSegments}
                showSimilarByUuid={this.showSimilarByUuid}
                onFileDownloaded={this.handleFileDownloaded}
                onOpenContextView={this.handleOpenContextView}
                setSourceContext={this.setSourceContext}
              />
            </Grid>) : null}
          {searchResultFooter ? (
            <Grid item style={{ textAlign: 'center', padding: '8px' }} className={classes.searchBody}>
              {searchResultFooter}
            </Grid>) : null}
        </Grid>
        {result ? (
          <ContextView
            authService={authService}
            selectedClient={selectedClient}
            segmentId={segmentId}
            path={contextViewPath}
            userCopiedSegments={this.userCopiedSegments}
            onFileDownloaded={this.handleFileDownloaded}
            onSegmentsLoaded={this.handleContextViewSegmentsLoaded}
            onClose={this.handleContextViewClosed}
          />) : null}
      </>
    )
  }
}

const SearchQuery = gql`
  query searchQuery($input: SearchSegmentsInput) {
    v2Api {
      searchSegments(input: $input) {
        total
        took
        hits {
          id
          pos
          score
          scoreExplanation
          type
          segment {
            ...segmentFields
          }
          breadcrumb {
            titles
            titlesHighlighted
            fileName
            fileNameHighlighted
            sourceDocUri
          }
          parent {
            ...segmentFields
          }
          segmentToParentEdge {
            ...edgeFields
          }
          similarToUuid
          docId
          sha256
          cmsId
        }
        runQuery {
          queryText
          transformations
          transformAction
        }
      }
    }
  }

  ${CommonGraphqlFragments.segment}
  ${CommonGraphqlFragments.edge}
`

const UserSearchedMutation = gql`
  mutation userSearched($input: UserSearchedInput) {
    v2Api {
      userSearched(input: $input) {
        id
      }
    }
  }
`

const UserSearchFailedMutation = gql`
  mutation userSearchFailed($input: UserSearchFailedInput) {
    v2Api {
      userSearchFailed(input: $input) {
        id
      }
    }
  }
`

const UserLoadedMoreMutation = gql`
  mutation userLoadedMore($input: UserLoadedMoreInput) {
    v2Api {
      userLoadedMore(input: $input) {
        id
      }
    }
  }
`

const UserCopiedSegmentMutation = gql`
  mutation userCopiedSegment($input: UserCopiedSegmentInput) {
    v2Api {
      userCopiedSegment(input: $input) {
        id
      }
    }
  }
`

const UserLaunchedContextViewMutation = gql`
  mutation userLaunchedContextView($input: UserLaunchedContextViewInput) {
    v2Api {
      userLaunchedContextView(input: $input) {
        id
      }
    }
  }
`

export { Search }

export default withRouter(withStyles(styles)(withApollo(Search)))
