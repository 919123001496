import React, { useState, useEffect, useCallback } from 'react'
import { isNetworkRequestInFlight } from 'apollo-client/core/networkStatus'
import { useQuery } from '@apollo/react-hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import MUIDataTable from 'mui-datatables'
import ClientPicker from 'components/Common/ClientPicker'
import SimpleSearchControl from 'components/Common/SimpleSearchControl'
import { FormattedException } from 'common/graphqlclient/ErrorHandler'
import { allAllowedClients } from 'common/users/userClients'
import { arraysAreEqual } from 'common/utilities/arrays'
import { usePrevious } from 'hooks/Common/usePrevious'
import { useStorageState } from 'react-storage-hooks'

const customTheme = theme => createMuiTheme({
  ...theme,
  overrides: {
    MuiPaper: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%'
      }
    },
    MuiToolbar: {
      root: {
        flexGrow: 0
      },
      regular: {
        height: 92 // Matches the MUIDataTableToolbarSelect height, avoiding unwanted movement when selecting the first item
      }
    },
    MUIDataTableFilterList: {
      root: {
        flexGrow: 0
      }
    },
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        flexGrow: 1,
        maxHeight: '100%'
      },
      responsiveScroll: {
        flexGrow: 1,
        maxHeight: '100%'
      },
      liveAnnounce: {
        flexGrow: 0
      }
    },
    MuiTable: { // Toolbar at the bottom
      root: {
        flexGrow: 0
      }
    },
    MuiTableCell: {
      root: {
        padding: 4
      }
    }
  }
})

const styles = theme => ({
  customToolbar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  tool: {
    marginLeft: theme.spacing(1)
  }
})

const RefreshButton = ({ refetch }) => <Tooltip title='Refresh'><IconButton onClick={() => refetch()}><RefreshIcon /></IconButton></Tooltip>

const DataTable = (
  {
    id,
    me,
    defaultValues,
    query,
    columns,
    queryVariables,
    customToolbar,
    options,
    extractData,
    extractTotal,
    title,
    setRefetchData,
    setRawData,
    onCreate,
    useClientPicker = true,
    classes,
    clearSelectedRows
  }
) => {
  const LOCAL_STORAGE_ROWSPERPAGE = `DataTable-${id}-rowsPerPage`

  const [page, setPage] = useState(0)
  const [searchText, setSearchText] = useState(defaultValues ? defaultValues.searchText || '' : '')
  const [clientCodes, setClientCodes] = useState(defaultValues ? defaultValues.clientCodes || null : null)
  const [rowsPerPage, setRowsPerPage] = useStorageState(window.localStorage, LOCAL_STORAGE_ROWSPERPAGE, 10)
  const [expandedRows, setExpandedRows] = useState([])

  const prevDefaultValues = usePrevious(defaultValues)
  const prevClientCodes = usePrevious(clientCodes)

  const resetTable = useCallback(() => {
    setPage(0)
    if (clearSelectedRows) { clearSelectedRows() }
  }, [clearSelectedRows])

  useEffect(() => {
    const prevPropsClientCodes = prevDefaultValues && prevDefaultValues.clientCodes
    const currentPropsClientCodes = defaultValues && defaultValues.clientCodes

    if (arraysAreEqual(prevClientCodes, prevPropsClientCodes) && !arraysAreEqual(prevPropsClientCodes, currentPropsClientCodes)) {
      setClientCodes(currentPropsClientCodes)
      resetTable()
    }
  }, [prevClientCodes, prevDefaultValues, defaultValues, resetTable])

  const onTableChange = async (action, tableState) => {
    if (page !== tableState.page || rowsPerPage !== tableState.rowsPerPage) {
      resetTable()
      setPage(tableState.page)
      setRowsPerPage(tableState.rowsPerPage)
    }
  }

  const onRowsExpanded = (currentRowsExpanded, allRowsExpanded) => {
    setExpandedRows(allRowsExpanded.map(r => r.index))
  }

  const handleSearch = text => {
    resetTable()
    setSearchText(text)
  }

  const handleClientsChanged = clientCodes => {
    resetTable()
    setClientCodes(clientCodes.map(c => c.code))
  }

  const defaultOptions = {
    serverSide: true,
    onTableChange: onTableChange,
    pagination: true,
    selectableRows: 'none',
    rowsExpanded: expandedRows,
    onRowsExpand: onRowsExpanded,
    rowsPerPageOptions: [10, 25, 100, 1000],
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    sort: false,
    search: false,
    responsive: 'stacked'
  }

  const { loading, error, data, refetch, networkStatus } = useQuery(query, {
    variables: {
      ...queryVariables,
      page: page,
      rowsPerPage: rowsPerPage,
      searchText: searchText,
      clients: clientCodes
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    // This is a bit weird but it allows the parent component to invoke refetch
    if (setRefetchData && refetch) {
      setRefetchData(refetch)
    }
  }, [setRefetchData, refetch])

  useEffect(() => {
    // This is a bit weird but it allows the parent component to access the raw data
    if (setRawData && data) {
      setRawData(data)
    }
  }, [setRawData, data])

  if (loading || isNetworkRequestInFlight(networkStatus)) return <CircularProgress />
  if (error) return <FormattedException err={error} />

  const columnData = extractData(data)
  const count = extractTotal(data)

  return (
    <ThemeProvider theme={customTheme}>
      <MUIDataTable
        columns={columns}
        data={columnData}
        options={Object.assign({
          count,
          page,
          rowsPerPage,
          customToolbar: () =>
            <div className={classes.customToolbar}>
              {onCreate ? (
                <div className={classes.tool}>
                  <Tooltip title='Create'>
                    <IconButton onClick={onCreate}><AddCircleIcon /></IconButton>
                  </Tooltip>
                </div>
              ) : null}
              <div className={classes.tool}><RefreshButton refetch={refetch} /></div>
              <div className={classes.tool}><SimpleSearchControl value={searchText} onSubmit={handleSearch} /></div>
              {useClientPicker
                ? <div className={classes.tool}><ClientPicker clients={allAllowedClients(me, true)} selectedClientCodes={clientCodes} isMulti onChange={handleClientsChanged} /></div>
                : null}
              {customToolbar}
            </div>
        }, defaultOptions, options, {
          // Hide the "Select All" icon when no data is present
          selectableRows: (count && options && options.selectableRows) ? options.selectableRows : 'none'
        })}
        title={title}
      />
    </ThemeProvider>
  )
}

export default withStyles(styles)(DataTable)
