import React from 'react'

/**
 * Basically this is a hack to allow a route to log the user out, mainly for auth testing. This wouldn't be used
 * in normal user situations.
 */
export default class Logout extends React.Component {
  componentDidMount () {
    this.props.authService.logout()
  }

  render () {
    return <p>Logging out...</p>
  }
}
