import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'

export default ({ className, sourceContext, setSourceContext, submitForm }) => {
  const handleSourceContextKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      submitForm()
    }
  }

  const handleChange = event => {
    setSourceContext(event.target.value)
  }

  return (
    <FormControl className={className} style={{ flexGrow: '1' }}>
      <Input
        id='sourceContext'
        placeholder='Source Path'
        type='search'
        autoComplete='off'
        onDoubleClick={evt => { evt.target.select && evt.target.select() }}
        value={sourceContext}
        onChange={handleChange}
        onKeyDown={handleSourceContextKeyDown}
      />
    </FormControl>
  )
}
